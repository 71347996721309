import React, { Component } from 'react';
import { Col, Row, Card} from "react-bootstrap";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import Form from "react-bootstrap/Form";

class PieChartContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedData: 'all',
            chartDataArray: [],
            chartDisplayData: []
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // do things with nextProps.someProp and prevState.cachedSomeProp
        if(nextProps.chartDataArray !== prevState.chartDataArray)
            return {
                selectedData: nextProps.selectedData,
                chartDataArray: nextProps.chartDataArray,
                chartDisplayData: nextProps.chartDisplayData
            // ... other derived state properties
            };

        return null
      }

    render() { 
        const {
            selectedData,
            chartDataArray,
            chartDisplayData
        } = this.state
        const {
            chartTitle
        } = this.props
        const COLORS = ['#7394af', '#dcdcdc', '#9094c0', '#88bcc0', '#1B5F75', '#9E6282', '#5DA77E', '#9499ac', '#ac9f7d', '#f2f3fd', '#c4e0c0'];
        return (
            <Row>
                <Col>
                    <Row><Col><h5>{chartTitle}</h5></Col></Row>
                    <Row className="mt-3" style={{ justifyContent: "center" }}><Col md={3}><h5><Form.Control
                        as="select"
                        value={selectedData}
                        onChange={(event) => {
                            this.changeSelectedData(event)

                        }}
                    >
                        <option value="all">View All</option>
                        {chartDataArray?.map((obj, index) => (
                            <option key={index} value={obj.name}>{obj.name}</option>
                        ))}
                    </Form.Control></h5></Col></Row>
                    <Row><Col>
                        <ResponsiveContainer width="100%" height={500}>
                            <PieChart width="100%" height={500}>
                                <Pie
                                    dataKey="count"
                                    isAnimationActive={true}
                                    data={chartDisplayData}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={200}
                                    fill="#7394af"
                                    label
                                >
                                    {
                                        chartDataArray.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))
                                    }
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </Col></Row>
                </Col>
            </Row>
        );
    }

    // render methods

    // general methods
    changeSelectedData = (event) => {
		let chartDisplayData = [];
		let matchIndex = this.state.chartDataArray.findIndex((x) => x.name == event.target.value)
		if (matchIndex !== -1) {
			chartDisplayData.push(this.state.chartDataArray[matchIndex])
            // console.log("if matched")
		}
		else {
			chartDisplayData.push(...this.state.chartDataArray)
		}
        // console.log("chartDisplayData",chartDisplayData)
		this.setState({
			selectedData: event.target.value,
			chartDisplayData: chartDisplayData
		})

	}
}
 
export default PieChartContainer;