import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Loader from "./loader";
import { Col, Row, Card, Jumbotron } from "react-bootstrap";
import swal from "@sweetalert/with-react";
import { UserContext } from "../contexts/userContext";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import application from "../constants/apiList";
import PieChartContainer from "./Common/pieChartContainer";

class Home extends Component {
	static contextType = UserContext;
	state = {
		redirect: null,
		loaded: false,
		jobsheetChartDataArray: [],
		jobsheetChartDisplayData: [],
		warrantyChartDataArray: [],
		warrantyChartDisplayData: [],
		userPrivilege: "",
    	userData: this.context[0]
	};
	redirectAuthenticated = async () => {
		const config = {
			method: "post",
			url: application.API.REACT_APP_USERSCHECKAUTH,
			withCredentials: true,
			// headers: { authorization: process.env.REACT_APP_AUTH },
		};
		// result = await axios(config).then((res) => //console.log("result", res));
		let result = await axios(config);
		//console.log("result", result.data);
		if (result.data.data === "faliure") {
			this.setState({ redirect: "/login" });
		}
		this.setState({ loaded: true });
	};

	componentDidMount() {
		// console.log("userStatusCheck ==> ",this.context[0]);
		if (this.context[0])
		{
			this.getData(null, null);
			if(this.context[0].user.privilege !== 'regular user') {
				this.getWarrantyData();
			}
		}
	}

	handleError = (status, message, error) => {
		this.setState({ errorStatus: status, errorMessage: message, loaded: true });
	};

	getWarrantyData = async () => {
		this.setState({
			loaded: false
		})
		try {
			const config = {
				withCredentials: true,
				method: "GET",
				url: application.API.WARRANTY,
				header: "Access-Control-Allow-Headers: X-Requested-With, privatekey",
			};
			const response = await axios(config);
			if ((response.status === 200) && response.data.status === "success") {
				let warrantyChartDataArray = [
					{
						name: "Approved",
						count: 0
					},
					{
						name: "Pending Approval",
						count: 0
					},
			]
				response.data.data?.map((obj) => {
					if (obj.warranty_approval === 1) {
						// 0th element indicating approved
						warrantyChartDataArray[0].count++;
					}
					else {
						// 1st element indicating pending
						warrantyChartDataArray[1].count++;
					}
	
				})
				this.setState({
					warrantyChartDataArray: warrantyChartDataArray,
					warrantyChartDisplayData: warrantyChartDataArray,
				})
			}
		}
		catch (error) {
			console.log("error in getWarrantyData :- ", error);
			this.handleError(true, "Error Getting data");
			swal({ title: "Error", text: "Internal Server Error", icon: "error" });
		}
		this.setState({
			loaded: true
		})
	}

	getData = async (pageNo, rowCount) => {
		this.setState({
			loaded: false
		})
		//console.log("entered")
		try {
			const response = await this.getJobsheetData(pageNo, rowCount);
			if(response !== false){
				//console.log("final response.data :- ", response.data);
				//console.log("final response.count :- ", response.count);
				this.setState({
					data: response.data,
					count: response.count,
					pageData: response.data,
				});
				// //console.log("state", this.state)
				let jobsheetChartDataArray = []
				this.state.data?.map((obj) => {
					let chartDataFoundIndex = -1
					if (jobsheetChartDataArray.length > 0)
						chartDataFoundIndex = jobsheetChartDataArray.findIndex((x) => x.name == obj.jobsheet_status)
					//if chartData present
					if (chartDataFoundIndex !== -1) {
						jobsheetChartDataArray[chartDataFoundIndex].count++;
					}
					else {
						jobsheetChartDataArray.push({ name: obj.jobsheet_status, count: 1 })
					}
	
				})
				//console.log("chartArray", jobsheetChartDataArray)
				this.setState({
					jobsheetChartDataArray: jobsheetChartDataArray,
					jobsheetChartDisplayData: jobsheetChartDataArray,
				})
			}
		} catch (error) {
			console.log("error in getData :- ", error);
			this.handleError(true, "Error Getting data");
			swal({ title: "Error", text: "Internal Server Error", icon: "error" });
		}
		this.setState({
			loaded: true
		})
	};

	getJobsheetData = async (pageNo, rowCount) => {
		try {
			// //console.log("userContext --> ",this.context);
			const userData = this.context[0];
			const user = userData['user'];
			let filteredReqData = {};
			//console.log("user --> ", userData);
			if (userData['loggedInStatus'] && ((user['privilege'] !== "super admin") && user['privilege'] !== "admin")) {
				filteredReqData['jobsheet_user'] = user['userName'];
			}
			const config = {
				withCredentials: true,
				method: "POST",
				url: application.API.JOBSHEET_PAGINATON,
				header: "Access-Control-Allow-Headers: X-Requested-With, privatekey",
				data: {
					attributes: {
						limit: rowCount,
						offset: pageNo * rowCount,
					},
					filteredReq: filteredReqData,
					columns: null,
				},
			};
			const response = await axios(config);
			// //console.log("response ==========> ",response.data);
			// if(response.status === 200)
			if ((response.status === 200) && response.data.status === "success") {
				// this.setState({name: "", city: "", result: this.state.name+" successfully inserted !!!"});
				// //console.log("data ",response.data.result);
				return response.data.result;
			}else{
				//console.log("false data")
				return false;
			}
		} catch (error) {
			//console.log(error);
			if (error.response) {
				//console.log(error.response.data);
				//console.log(error.response.status);
				//console.log(error.response.headers);
			}
			this.handleError(true, "Error getting response from the server");
			swal({ title: "Error", text: "Internal Server Error", icon: "error" });
		}
	};


	render() {
		//console.log("LOCAL ===> ",local);
		//console.log("redirect ===> ",this.state.redirect);

    
		if (this.state.redirect) {
      //console.log("redirecting to ====> ",this.state.redirect);
			return <Redirect to={this.state.redirect} />;
		}
		if (!this.state.loaded) {
      //console.log("redirecting to loaded ====> ",this.state.loaded);
			return <Loader />;
		} 
    else if(!this.state.userData.user.pchanged) {
      return <Redirect to={"/change-password"} />;
    }
    else
			return (
				<>
					<Jumbotron className="jumbotron-title shadow-sm">
						<h4 style={{ textAlign: "center" }}>Dashboard</h4>
					</Jumbotron>
					<Card className="main-container">
						<Card.Body className="main-container-body">
							<PieChartContainer
								key="jobsheet"
								selectedData={'all'}
								chartDataArray={this.state.jobsheetChartDataArray}
								chartDisplayData={this.state.jobsheetChartDisplayData}
								chartTitle={"Jobsheet Status"}
							/>
							<hr />
							{
								(this.context[0].user.privilege !== 'regular user') 
								?
								<PieChartContainer
									key="warranty"
									selectedData={'all'}
									chartDataArray={this.state.warrantyChartDataArray}
									chartDisplayData={this.state.warrantyChartDisplayData}
									chartTitle={"Warranty Approval"}
								/>
								: null
							}
						</Card.Body>
					</Card>
				</>
			);
	}
}

export default Home;
