import moment from 'moment';
import logo from '../resources/images/KEI-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faDownload,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import React, {
  useState,
  useReducer,
  useEffect,
  useContext,
  ReactDOM,
} from 'react';
import '../styles/pages.scss';
import '../styles/componentsStyles/addJobsheet.scss';
import { returnNullForEmpty } from '../constants/commonHelper';
import { Col, Row, Card, Jumbotron, Collapse } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import ImageRenderer from './imageRenderer';
import { UserContext } from '../contexts/userContext';
import application from '../constants/apiList';
import Loader from './loader';
import swal from '@sweetalert/with-react';
import { Redirect } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { CodeSharp } from '@material-ui/icons';
var $ = require('jquery');

const initialState = {
  jobsheet_no: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_user: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_last_modified_by: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_customer_type: {
    isValid: true,
    value: 'Customer',
    errorMsg: '',
  },
  jobsheet_customer_name: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_address: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_city: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_pincode: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_mobile_no: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_tel: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_email_id: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_dop: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_dealer_name: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_brand: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_product_category: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_model: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_serial_no: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_quantity: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_type_of_service: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_warranty: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_non_returnable_reason: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_replacement_reason: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_invoice_no: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_invoice_amount: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_others: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_accessories_packing: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_defects: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_status: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_status_pending_reason: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_status_pending_spare_part_reason: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_status_cancel_reason: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_status_other_reason: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_status_close_reason: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_call_type: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_part_name: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_part_number: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_part_quantity: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_status_other_reason: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_software_reason: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_set_condition: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_action_type: {
    isValid: true,
    value: 'Hardware',
    errorMsg: '',
  },
  jobsheet_upload_invoice: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_product_image_1: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_product_image_2: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_product_image_3: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
  jobsheet_submission_date: {
    isValid: true,
    value: '',
    errorMsg: '',
  },
};

function init(initialData) {
  initialData['jobsheet_set_condition']['value'] = [];
  initialData['jobsheet_status']['value'] = 'pending';
  return {
    data: initialData,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'valueChanged':
      let stateData = {};
      let changedValue = {};

      changedValue['value'] = action.payload;
      changedValue['isValid'] = true;
      changedValue['errorMsg'] = '';

      state.data[action.key] = changedValue;
      stateData['data'] = state.data;

      return stateData;

    case 'initialEditState':
      let initialEditState = {};
      initialEditState['data'] = action.payload;
      return initialEditState;

    case 'setConiditionValueChanged':
      let setConditionChangedValue = {};
      let setConiditionStateData = {};
      let currentCondition = [];

      if (state.data[action.key]['value'].includes(action.payload)) {
        // // console.log("Logs:- removing previous elements")
        currentCondition = state.data[action.key]['value'];
        // // console.log("Logs:- currentList :- ",currentCondition);
        for (var i = 0; i < currentCondition.length; i++) {
          // // console.log("Logs:- i = ",currentCondition[i]);
          if (currentCondition[i] === action.payload) {
            currentCondition.splice(i, 1);
            // // console.log("Logs:- after Removing i :- ",currentCondition);
          }
        }
        setConditionChangedValue['isValid'] = true;
        setConditionChangedValue['errorMsg'] = '';
        setConditionChangedValue['value'] = currentCondition;

        // // console.log("Logs:- setConditionChangedValue :- ",setConditionChangedValue.value.length)
        // // console.log("Logs:- setConditionChangedValue :- ",setConditionChangedValue.value)
        // // console.log("Logs:- setConditionChangedValue :- ",setConditionChangedValue)

        state.data[action.key] = setConditionChangedValue;
        setConiditionStateData['data'] = state.data;

        return setConiditionStateData;
      } else {
        // // console.log("Logs:- adding previous elements")
        currentCondition = state.data[action.key]['value'];
        currentCondition.push(action.payload);

        setConditionChangedValue['isValid'] = true;
        setConditionChangedValue['errorMsg'] = '';
        setConditionChangedValue['value'] = currentCondition;

        // // console.log("Logs:- setConditionChangedValue :- ",setConditionChangedValue.value.length)
        // // console.log("Logs:- setConditionChangedValue :- ",setConditionChangedValue.value)
        // // console.log("Logs:- setConditionChangedValue :- ",setConditionChangedValue)

        state.data[action.key] = setConditionChangedValue;
        setConiditionStateData['data'] = state.data;

        return setConiditionStateData;
      }
    // currentCondition.push(action.payload);
    // // // console.log("Logs:- currentCondition => ",currentCondition);

    // state.data[action.key]["value"] = currentCondition;
    // state.data[action.key]["errorMsg"] = "";
    // state.data[action.key]["isValid"] = true;

    // // // console.log("Logs:- stateData => ",state.data[action.key]);
    // setConiditionStateData["data"] = state.data;

    // setConditionChangedValue["isValid"] = true;
    // setConditionChangedValue["errorMsg"] = "";
    // setConditionChangedValue["value"] = currentCondition;

    // // // console.log("Logs:- setConditionChangedValue :- ",setConditionChangedValue.value.length)
    // // // console.log("Logs:- setConditionChangedValue :- ",setConditionChangedValue.value)
    // // // console.log("Logs:- setConditionChangedValue :- ",setConditionChangedValue)

    // state.data[action.key] = setConditionChangedValue
    // setConiditionStateData["data"] = state.data;

    // return setConiditionStateData;
    // return {
    //   ...state,
    //   'jobsheet_set_condition': {
    //     'value': [action.payload],
    //     'errorMsg': "",
    //     'isValid': true
    //   }
    // };
    default:
      throw new Error();
  }
}

let productData;
const partNameRef = React.createRef();
const partCodeRef = React.createRef();
const AddJobsheet = () => {
  const currentUrl = window.location.href;
  let keyCount = 0;

  const [userState, setUserState] = useContext(UserContext);
  const [insertState, setInsertState] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [isInitDataLoadStarted, setIsInitDataLoadStarted] = useState(false);

  const [state, dispatch] = useReducer(reducer, initialState, init);
  const [validated, setValidated] = useState(false);
  const [actionSelector, setActionSelector] = useState('Hardware');

  const [setConditionState, setSetConditionState] = useState([]);

  const [brandState, setBrandState] = useState(null);
  const [categoryState, setCategoryState] = useState(null);
  const [productState, setProductState] = useState(null);

  const [uploadProgress, setUploadProgress] = useState(null);
  const [pdt1Progress, setPdt1Progress] = useState(null);
  const [pdt2Progress, setPdt2Progress] = useState(null);
  const [pdt3Progress, setPdt3Progress] = useState(null);
  const [pdt4Progress, setPdt4Progress] = useState(null);

  const [imageSource, setImageSource] = useState(null);
  const [pdtImg1Src, setPdtImg1Src] = useState(null);
  const [pdtImg2Src, setPdtImg2Src] = useState(null);
  const [pdtImg3Src, setPdtImg3Src] = useState(null);
  const [pdtImg4Src, setPdtImg4Src] = useState(null);

  const [file, setFile] = useState(null);
  const [pdtImg1StateFile, setPdtImg1StateFile] = useState(null);
  const [pdtImg2StateFile, setPdtImg2StateFile] = useState(null);
  const [pdtImg3StateFile, setPdtImg3StateFile] = useState(null);
  const [pdtImg4StateFile, setPdtImg4StateFile] = useState(null);

  const [invoiceCollapse, setinvoiceCollapse] = useState(false);
  const [productImage1, setProductImage1] = useState(false);
  const [productImage2, setProductImage2] = useState(false);
  const [productImage3, setProductImage3] = useState(false);
  const [productImage4, setProductImage4] = useState(false);

  const [inventoryListData, setInventoryListData] = useState({});
  const [isPartLoading, setIsPartLoading] = useState(false);
  const [isPartNameDropDownOpen, setIsPartNameDropDownOpen] = useState(false);
  const [isPartNumberDropDownOpen, setisPartNumberDropDownOpen] = useState(false);
  const [isPartQuantityAvailable, setIsPartQuantityAvailable] = useState(null);
  const [partQuantityErrorMsg, setPartQuantityErrorMsg] = useState("");
  var partNameRequestTimer;
  var partNumberRequestTimer;

  const resetFormState = () => {
    for (let key in initialState) {
      let defaultValue = {};
      defaultValue['isValid'] = true;
      defaultValue['value'] = '';
      defaultValue['errorMsg'] = '';
      initialState[key] = defaultValue;
    }
    initialState['jobsheet_action_type'] = {
      isValid: true,
      value: 'Hardware',
      errorMsg: '',
    };
    initialState['jobsheet_status']['value'] = 'pending';
    setSetConditionState([]);
    return initialState;
  };

  const handleOuterClick = e => {
    if ((partNameRef !== null && partCodeRef !== null) || !partNameRef.current.contains(e.target) || !partCodeRef.current.contains(e.target)) {
      setIsPartNameDropDownOpen(false);
      setisPartNumberDropDownOpen(false);
    }
  };

  useEffect(() => {
    if (userState.loggedInStatus && !isInitDataLoadStarted) {
      document.addEventListener("click", handleOuterClick);
      // console.log("Logs:- useEffect => ")
      setIsInitDataLoadStarted(true);
      getInitData();
      $('#main-container-body').hide();
    }
  }, []);

  const getInitData = () => {
    // console.log("Logs:- getInitData =====> ");
    if (currentUrl.includes('edit')) {
      const jobsheetNo =
        currentUrl.split('/')[currentUrl.split('/').length - 1];
      // //console.log("jobsheetNo ===> ", jobsheetNo);
      getJobsheetData(jobsheetNo);
    } else {
      getProductData('', '');
    }
  };

  const getKey = () => {
    return keyCount++;
  };

  const getJobsheetData = async (jobsheetNo) => {
    setSubmitStatus(true);
    try {
      const config = {
        url: application.API.JOBSHEET_URL + '?jobsheet_no=' + jobsheetNo,
        type: 'GET',
        withCredentials: true,
      };
      const response = await axios(config);
      if (response.status === 200 && response.data.status === 'success') {
        // //console.log("successfully got the data :- ");
        setSubmitStatus(false);
        const jobsheetData = response.data.data[0];
        for (let key in jobsheetData) {
          if (initialState.hasOwnProperty(key)) {
            initialState[key]['value'] =
              jobsheetData[key] === null ? '' : jobsheetData[key];
          }
        }

        setSetConditionState(
          initialState['jobsheet_set_condition']['value'].split(',')
        );

        if (
          initialState['jobsheet_upload_invoice']['value'] !== null &&
          initialState['jobsheet_upload_invoice']['value'] !== ''
        ) {
          setUploadProgress(100);
          setImageSource(
            application.API.REACT_APP_IMAGES_URL +
              '/' +
              initialState['jobsheet_upload_invoice']['value']
          );
        }
        if (
          initialState['jobsheet_product_image_1']['value'] !== null &&
          initialState['jobsheet_product_image_1']['value'] !== ''
        ) {
          setPdt1Progress(100);
          setPdtImg1Src(
            application.API.REACT_APP_IMAGES_URL +
              '/' +
              initialState['jobsheet_product_image_1']['value']
          );
        }
        if (
          initialState['jobsheet_product_image_2']['value'] !== null &&
          initialState['jobsheet_product_image_2']['value'] !== ''
        ) {
          setPdt2Progress(100);
          setPdtImg2Src(
            application.API.REACT_APP_IMAGES_URL +
              '/' +
              initialState['jobsheet_product_image_2']['value']
          );
        }
        if (
          initialState['jobsheet_product_image_3']['value'] !== null &&
          initialState['jobsheet_product_image_3']['value'] !== ''
        ) {
          setPdt3Progress(100);
          setPdtImg3Src(
            application.API.REACT_APP_IMAGES_URL +
              '/' +
              initialState['jobsheet_product_image_3']['value']
          );
        }

        // //console.log("initialState ==> ", initialState);
        // //console.log("productData ===> ", productData);
        //setting category and product
        getProductData(
          initialState['jobsheet_brand']['value'],
          initialState['jobsheet_product_category']['value']
        );
        // setCategoryState(productData[initialState['jobsheet_brand']['value']]);
        // setProductState(productData[initialState['jobsheet_product_category']['value']]);
        dispatch({
          type: 'initialEditState',
          key: '',
          payload: initialState,
        });
      } else {
        // // //console.log("No data found");
        setSubmitStatus(false);
        swal({ title: 'Error', text: 'Internal Server Error', icon: 'error' });
      }
    } catch (error) {
      setSubmitStatus(false);
      swal({ title: 'Error', text: 'Internal Server Error', icon: 'error' });
      console.log('error in getJobsheetData :- ', error);
    }
  };

  const getProductData = async (brandValue, categoryValue) => {
    // console.log("Logs:- called getProductData :- ");
    try {
      const config = {
        method: 'get',
        url: application.API.APP_PRODUCTS,
        withCredentials: true,
      };
      // console.log("calling api");
      const response = await axios(config);
      // console.log("Logs:- response :- ", response.data.status);
      if (response.status === 200 && response.data.status === 'success') {
        productData = response.data.result;

        let brand = Object.keys(response.data.result);
        let category = Object.keys(productData[brand[0]]);
        let productList;

        // console.log("Logs:- brandValue :- ",productData[brandValue]);
        // console.log("Logs:- categoryValue :- ",productData[categoryValue]);
        //checking if the entered product is in the list
        if (
          (brandValue === '' && categoryValue === '') ||
          productData[brandValue] === null ||
          typeof productData[brandValue] === 'undefined' ||
          productData[brandValue][categoryValue] === null ||
          typeof productData[brandValue][categoryValue] === 'undefined'
        ) {
          productList = productData[brand[0]][category[0]];
        } else {
          category = Object.keys(productData[brandValue]);
          productList = productData[brandValue][categoryValue];
          // // //console.log("brand ===> ",brand);
          // // //console.log("category ===> ",category);
          // // //console.log('productData ==> ',productData[brandValue][category]);
        }

        setBrandState(brand);
        setCategoryState(category);
        setProductState(productList);
      } else {
        swal({ title: 'Error', text: 'Internal Server Error', icon: 'error' });
      }
    } catch (error) {
      console.log('error in getProductData :- ', error);
      swal({ title: 'Error', text: 'Internal Server Error', icon: 'error' });
    }
  };

  const getFileName = (file) => {
    if (file !== null) {
      const fileName = file.split('/')[file.split('/').length - 1];
      return fileName;
    }
    return null;
  };

  const getSetConditionString = (setConidtionArray) => {
    let setConditionString = '';
    if (typeof setConidtionArray.length !== 'undefined') {
      if (setConidtionArray.length > 0) {
        for (var i = 0; i < setConidtionArray.length - 1; i++) {
          setConditionString += setConidtionArray[i] + ',';
        }
        setConditionString += setConidtionArray[setConidtionArray.length - 1];
      }
    }
    return setConditionString;
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity()) {
      // console.log("inventoryListData ===> ",inventoryListData);
      event.preventDefault();
      if(!isPartQuantityAvailable && state.data.jobsheet_part_quantity.value.length > 0){
        swal({
          title: 'Error',
          text: 'The part quantity added is not available',
          icon: 'error',
        });
        setValidated(false);
      }
      else{
        const user = userState.user.userName;
        const jobsheetData = state.data;
  
        const invoiceFileName = getFileName(imageSource);
        const pdt1FileName = getFileName(pdtImg1Src);
        const pdt2FileName = getFileName(pdtImg2Src);
        const pdt3FileName = getFileName(pdtImg3Src);
        const pdt4FileName = getFileName(pdtImg4Src);
  
        // //console.log("invoiceFileName :- ", invoiceFileName);
  
        const date = new Date();
        const submissionDate =
          date.getFullYear() +
          '-' +
          ('00' + (date.getMonth() + 1)).slice(-2) +
          '-' +
          ('00' + date.getDate()).slice(-2) +
          ' ' +
          ('00' + date.getHours()).slice(-2) +
          ':' +
          ('00' + date.getMinutes()).slice(-2) +
          ':' +
          ('00' + date.getSeconds()).slice(-2);
  
        // //console.log("submissionDate :- ", submissionDate);
  
        // //console.log("userState :- ", user);
        // //console.log("jobsheetData :- ", jobsheetData);
        // //console.log("partQuantity --> ", jobsheetData.jobsheet_part_quantity.value);
        // //console.log("jobsheetNo ---> ", ((jobsheetData.jobsheet_part_quantity.value === '') ? null : jobsheetData.jobsheet_part_quantity.value));
  
        jobsheetData.jobsheet_set_condition.value =
          getSetConditionString(setConditionState);
        setSubmitStatus(true);
  
        var requestData = {
          jobsheet_user: user,
          jobsheet_customer_type:
            jobsheetData.jobsheet_customer_type.value === ''
              ? 'Customer'
              : jobsheetData.jobsheet_customer_type.value,
          jobsheet_customer_name: jobsheetData.jobsheet_customer_name.value,
          jobsheet_address: jobsheetData.jobsheet_address.value,
          jobsheet_city: jobsheetData.jobsheet_city.value,
          jobsheet_pincode: returnNullForEmpty(
            jobsheetData.jobsheet_pincode.value
          ),
          jobsheet_mobile_no: jobsheetData.jobsheet_mobile_no.value,
          jobsheet_tel: jobsheetData.jobsheet_tel.value,
          jobsheet_email_id: jobsheetData.jobsheet_email_id.value,
          jobsheet_dop: returnNullForEmpty(jobsheetData.jobsheet_dop.value),
          jobsheet_dealer_name: jobsheetData.jobsheet_dealer_name.value,
          jobsheet_brand: jobsheetData.jobsheet_brand.value,
          jobsheet_product_category: jobsheetData.jobsheet_product_category.value,
          jobsheet_model: jobsheetData.jobsheet_model.value,
          jobsheet_serial_no: jobsheetData.jobsheet_serial_no.value,
          jobsheet_quantity: returnNullForEmpty(
            jobsheetData.jobsheet_quantity.value
          ),
          jobsheet_type_of_service: jobsheetData.jobsheet_type_of_service.value,
          jobsheet_non_returnable_reason:
            jobsheetData.jobsheet_non_returnable_reason.value,
          jobsheet_replacement_reason:
            jobsheetData.jobsheet_replacement_reason.value,
          jobsheet_warranty: jobsheetData.jobsheet_warranty.value,
          jobsheet_invoice_no: jobsheetData.jobsheet_invoice_no.value,
          jobsheet_invoice_amount: returnNullForEmpty(
            jobsheetData.jobsheet_invoice_amount.value
          ),
          jobsheet_others: jobsheetData.jobsheet_others.value,
          jobsheet_accessories_packing:
            jobsheetData.jobsheet_accessories_packing.value,
          jobsheet_defects: jobsheetData.jobsheet_defects.value,
          jobsheet_set_condition: jobsheetData.jobsheet_set_condition.value,
          jobsheet_status: jobsheetData.jobsheet_status.value,
          jobsheet_status_pending_reason:
            jobsheetData.jobsheet_status_pending_reason.value,
          jobsheet_status_pending_spare_part_reason:
            jobsheetData.jobsheet_status_pending_spare_part_reason.value,
          jobsheet_status_cancel_reason:
            jobsheetData.jobsheet_status_cancel_reason.value,
          jobsheet_status_other_reason:
            jobsheetData.jobsheet_status_other_reason.value,
          jobsheet_status_close_reason:
            jobsheetData.jobsheet_status_close_reason.value,
          jobsheet_call_type: jobsheetData.jobsheet_call_type.value,
          jobsheet_upload_invoice: invoiceFileName,
          jobsheet_product_image_1: pdt1FileName,
          jobsheet_product_image_2: pdt2FileName,
          jobsheet_product_image_3: pdt3FileName,
          jobsheet_product_image_4: pdt4FileName,
          jobsheet_action_type: jobsheetData.jobsheet_action_type.value,
          jobsheet_part_name: jobsheetData.jobsheet_part_name.value,
          jobsheet_part_number: jobsheetData.jobsheet_part_number.value,
          jobsheet_part_quantity:
            jobsheetData.jobsheet_part_quantity.value === ''
              ? null
              : jobsheetData.jobsheet_part_quantity.value,
          jobsheet_part_others: jobsheetData.jobsheet_status_other_reason.value,
          jobsheet_software_reason: jobsheetData.jobsheet_software_reason.value,
        };
  
        if (currentUrl.includes('edit')) {
          requestData['jobsheet_user'] = jobsheetData.jobsheet_user.value;
          requestData['jobsheet_last_modified_by'] = user;
        } else {
          requestData['jobsheet_user'] = user;
          requestData['jobsheet_last_modified_by'] = user;
        }

        if (jobsheetData.jobsheet_status.value === 'callClose') {
          requestData['jobsheet_completion_date'] = submissionDate;
        }
  
        if (currentUrl.indexOf('edit') > 0) {
          requestData['jobsheet_modification_date'] = submissionDate;
          const config = {
            url: application.API.JOBSHEET_URL,
            method: 'patch',
            data: {
              where: {
                jobsheet_no: state.data.jobsheet_no.value,
              },
              update: {
                requestData,
              },
            },
            withCredentials: true,
          };
          const response = await axios(config);
          // //console.log("response :- ", response.data);
          if (response.status === 200 && response.data.status === 'success') {
            setSubmitStatus(false);
            resetFormState();
            setValidated(false);
            swal({
              title: 'Success',
              text: 'Jobsheet Updated',
              icon: 'success',
            }).then(function () {
              window.location = application.API.REACT_APP_VIEW_JOBSHEET;
            });
            // $('html, body').animate({
            //   scrollTop: 0
            // }, 1000);
          } else {
            setSubmitStatus(false);
            swal({
              title: 'Error',
              text: 'Internal Server Error',
              icon: 'error',
            });
          }
        } else {
          requestData['jobsheet_submission_date'] = submissionDate;
          const config = {
            url: application.API.JOBSHEET_URL,
            method: 'post',
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
            data: requestData,
          };
  
          const response = await axios(config);
  
          if (response.status === 200 && response.data.status === 'success') {
            // //console.log("jobsheet inserted");
            resetFormState();
            init(initialState);
            setValidated(false);
            swal({
              title: 'Success',
              text: 'Jobsheet Inserted',
              icon: 'success',
            });
            $('html, body').animate(
              {
                scrollTop: 0,
              },
              1000
            );
            setSubmitStatus(false);
            dispatch({
              type: 'initialEditState',
              key: '',
              payload: initialState,
            });
          } else {
            setSubmitStatus(false);
            swal({
              title: 'Error',
              text: 'Internal Server Error',
              icon: 'error',
            });
          }
        }
      }
    } else {
      event.preventDefault();
      event.stopPropagation();
      var errorElements = document.querySelectorAll(
        'input.form-control:invalid'
      );
      $('html, body').animate(
        {
          scrollTop: $(errorElements[0]).offset().top - 20,
        },
        1000
      );
      swal({
        title: 'Error',
        text: 'Please fix the errors in your form',
        icon: 'warning',
      });
    }
  };

  const actionSelected = (e) => {
    // //console.log("changed to ", e.target.value);
    setActionSelector(e.target.value);
  };

  const addFile = (e) => {
    // //console.log("file name :- ", e.target.files[0].name);
    const file = e.target.files[0];
    // // // console.log("Logs:- file size :- ",getFileSize(file.size));
    if (getFileSize(file.size) > 2) {
      swal({
        title: 'Error',
        text: 'The size of the upload should be less than 2mb',
        icon: 'error',
      });
      e.target.value = '';
    }
    setFile(file);
  };

  const getFileSize = (sizeInBytes) => {
    return sizeInBytes / 1024 / 1024;
  };

  const addPdtImg1File = (e) => {
    // //console.log("imageName :- ", e.target.files[0].name);
    const file = e.target.files[0];
    if (getFileSize(file.size) > 2) {
      swal({
        title: 'Error',
        text: 'The size of the upload should be less than 2mb',
        icon: 'error',
      });
      e.target.value = '';
    }
    setPdtImg1StateFile(e.target.files[0]);
  };

  const addPdtImg2File = (e) => {
    // //console.log("imageName :- ", e.target.files[0].name);
    const file = e.target.files[0];
    if (getFileSize(file.size) > 2) {
      swal({
        title: 'Error',
        text: 'The size of the upload should be less than 2mb',
        icon: 'error',
      });
      e.target.value = '';
    }
    setPdtImg2StateFile(e.target.files[0]);
  };

  const addPdtImg3File = (e) => {
    // //console.log("imageName :- ", e.target.files[0].name);
    const file = e.target.files[0];
    if (getFileSize(file.size) > 2) {
      swal({
        title: 'Error',
        text: 'The size of the upload should be less than 2mb',
        icon: 'error',
      });
      e.target.value = '';
    }
    setPdtImg3StateFile(e.target.files[0]);
  };

  const addPdtImg4File = (e) => {
    // //console.log("imageName :- ", e.target.files[0].name);
    setPdtImg4StateFile(e.target.files[0]);
  };

  const changeCategory = (e) => {
    const selectedBrand = e.target.value;
    let model = document.getElementById('model');
    const productList = [];
    let categoryList;
    let optionLength = model.options.length;
    // brand.options[brand.selectedIndex].setAttribute('selected', true);
    // //console.log("brand val = ", e.target.value);
    // //console.log("productData = ", productData);
    // //console.log("optionLength = ", optionLength);
    if (selectedBrand === '') {
      categoryList = [];
    } else {
      categoryList = Object.keys(productData[selectedBrand]);
    }

    setCategoryState(categoryList);
    setProductState(productList);
  };

  const changeProduct = (e) => {
    const selectedCategory = e.target.value;
    let brand = document.getElementById('brand').options;
    brand = brand[brand.selectedIndex].value;
    // //console.log("brand :- ", brand);
    // const category = document.getElementById("category").value;
    // //console.log("category :- ", selectedCategory);
    const productList = productData[brand][selectedCategory];
    // //console.log("productList :- ", productList);
    setProductState(productList);
  };

  const resetForm = () => {
    resetFormState();
    dispatch({
      type: 'initialEditState',
      key: '',
      payload: initialState,
    });
    setValidated(false);
  };

  const uploadFile = async (e, type) => {
    try {
      // //console.log("type ===> ", type);
      let form = new FormData();
      setSubmitStatus(true);
      switch (type) {
        case 'invoice':
          form.append('file', file);
          break;
        case 'pdt1':
          form.append('file', pdtImg1StateFile);
          break;
        case 'pdt2':
          form.append('file', pdtImg2StateFile);
          break;
        case 'pdt3':
          form.append('file', pdtImg3StateFile);
          break;
        case 'pdt4':
          form.append('file', pdtImg4StateFile);
          break;
        default:
          break;
      }

      const response = await axios.post(application.API.JOBSHEET_IMAGE_UPLOAD, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progressData = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          switch (type) {
            case 'invoice':
              setUploadProgress(progressData);
              break;
            case 'pdt1':
              setPdt1Progress(progressData);
              break;
            case 'pdt2':
              setPdt2Progress(progressData);
              break;
            case 'pdt3':
              setPdt3Progress(progressData);
              break;
            case 'pdt4':
              setPdt4Progress(progressData);
              break;
            default:
              break;
          }
        },
      });
      // // console.log("Logs:- response :- ", response.data);
      if (response.status === 200) {
        setSubmitStatus(false);
        if (response.data['status'] === 'success') {
          const imgSrc =
            response.data.data['filePath'] +
            '/' +
            response.data.data['fileName'];
          // //console.log("respondedPath :- ", imgSrc);
          switch (type) {
            case 'invoice':
              setImageSource(imgSrc);
              break;
            case 'pdt1':
              setPdtImg1Src(imgSrc);
              break;
            case 'pdt2':
              setPdtImg2Src(imgSrc);
              break;
            case 'pdt3':
              setPdtImg3Src(imgSrc);
              break;
            case 'pdt4':
              setPdtImg4Src(imgSrc);
              break;
            default:
              break;
          }
          const fileName = imgSrc.split('/')[imgSrc.split('/').length - 1];
          // //console.log("Upload File Name :- ", fileName);
          swal({ title: 'Success', text: 'Image Uploaded', icon: 'success' });
        } else {
          setSubmitStatus(false);
          swal({
            title: 'Error',
            text: 'Internal Server Error',
            icon: 'error',
          });
        }
      } else {
        setSubmitStatus(false);
        swal({ title: 'Error', text: 'Internal Server Error', icon: 'error' });
      }
    } catch (error) {
      console.log('error while uploading the image :- ', error);
      setSubmitStatus(false);
      swal({ title: 'Error', text: 'Internal Server Error', icon: 'error' });
    }
  };

  const nullAndUndefinedSafe = (val) => {
    if (typeof val === 'undefined') {
      return '';
    } else if (val === null) {
      return '';
    }
    return val;
  };

  const downloadForm = () => {
    setSubmitStatus(true);
    $('#main-container-body').show();
    $('#main-container-body-jobsheet').hide();
    const input = document.getElementById('main-container-body');
    html2canvas(input, {
      height: input.offsetHeight,
      windowHeight: input.offsetHeight,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      let pdf = new jsPDF('p', 'mm', 'a4');
      let width = pdf.internal.pageSize.getWidth();
      let height = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, 'JPEG', 0, 0, width, height, '', 'FAST');

      let name = 'Jobsheet';
      // pdf.save(name);
      pdf.output('dataurlnewwindow');
      // pdf.addJs('print({});')
      // pdf.autoPrint()
      setSubmitStatus(false);
    });
    $('#main-container-body-jobsheet').show();
    $('#main-container-body').hide();
  };

  if (
    brandState === null ||
    categoryState === null ||
    productState === null ||
    submitStatus
  ) {
    if (userState.loggedInStatus && !isInitDataLoadStarted) {
      // console.log("Logs:- isInitDataLoadStarted ====> ")
      getInitData();
      setIsInitDataLoadStarted(true);
    }
    return <Loader />;
  }

  if (currentUrl.indexOf('insert') > 0) {
    // value: "",
    // errorMsg: ""
    if (!insertState) {
      // console.log("initializing the state for insert jobsheet");
      resetFormState();
      // // //console.log()
      init(initialState);
      setInsertState(true);
    }
  }

  if (currentUrl.indexOf('edit') > 0) {
    if (insertState) {
      const jobsheetNo =
        currentUrl.split('/')[currentUrl.split('/').length - 1];
      // console.log("jobsheetNo ===> ", jobsheetNo);
      getJobsheetData(jobsheetNo);
      setInsertState(false);
    }
  }

  const handleSetConidtion = (setValue) => {
    let conidtionValue = setConditionState;
    if (conidtionValue.includes(setValue)) {
      // // console.log("Logs:- removing setValue");
      const removeIndex = conidtionValue.indexOf(setValue);
      conidtionValue.splice(removeIndex, 1);
    } else {
      // // console.log("Logs:- adding setValue");
      conidtionValue.push(setValue);
    }
    // // console.log("Logs:- finalconidtionValue => ",conidtionValue);
    // // console.log("Logs:- setConidtionString => ",getSetConditionString(conidtionValue));
    setSetConditionState([...conidtionValue]);
  };

  const getInventoryDetails = async (event, config) => {
    var inventoryType = config['data']['inventory_type'];
    const response = await axios(config);
    if (response.status === 200 && response.data.status === 'success') {
      // console.log('FINAL RESULT ===> ', response.data.result);
      var responseData = inventoryListData;
      responseData[inventoryType] = response.data.result[inventoryType];
      setInventoryListData(responseData);
      setIsPartLoading(false);
      // console.log('inventoryType ==> ', responseData);
    }else if(response.status === 200 && response.data.status === 'failed'){
      var responseData = inventoryListData;
      // console.log("failed responseData => ",responseData);
      if(responseData.hasOwnProperty(inventoryType))
        responseData[inventoryType] = [];
      setInventoryListData(responseData);
      setIsPartLoading(false);
      // console.log('inventoryType failed ==> ', inventoryListData);
    }
  };

  const checkAvailableQuantity = async (quantity, partName, partCode) => {
    try{
      if(partName.length > 0 && partCode.length > 0){
        const config = {
          url: application.API.INVENTORY_QUANTITY_CHECK,
          method: 'POST',
          data: {
            "part_name": partName,
            "part_code": partCode,
            "requested_quantity": quantity
          },
          withCredentials: true,
        };
        const response = await axios(config);
        if (response.status === 200 && response.data.status === "success" && response.data.message === "Available") {
          // console.log("Available");
          setIsPartQuantityAvailable(true);
          setPartQuantityErrorMsg("");
        }
        else if (response.status === 200 && response.data.status === "success" && response.data.message === "NA") {
          // console.log("NA");
          setValidated(false);
          setIsPartQuantityAvailable(false);
          setPartQuantityErrorMsg("Not Available");
        }else{
          // console.log("response ==> ",response);
          swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
      }else{
        setValidated(false);
        setIsPartQuantityAvailable(false);
        setPartQuantityErrorMsg("Please enter part name and part code");
      }
    }catch(error){
      console.log("Error in checkAvailableQuantity ==> ",error);
      swal({ title: "Error", text: "Internal Server Error", icon: "error" });
    }
  }

  // // console.log("Logs:- state => ",state.data);
  // // console.log("Logs:- setCondition => ",setConditionState);

  return (
    (!userState.user.pchanged) 
    ? <Redirect to={"/change-password"} />
    :
    <>
      <Jumbotron className='jumbotron-title shadow-sm'>
        {!insertState ? (
          <h4 style={{ textAlign: 'center' }}>Edit Jobsheet</h4>
        ) : (
          <h4 style={{ textAlign: 'center' }}>Add Jobsheet</h4>
        )}
      </Jumbotron>
      <Card className='main-container'>
        {nullAndUndefinedSafe(userState.user) !== '' ? (
          <Card.Body
            id='main-container-body'
            style={{ display: 'none' }}
            className='main-container-body print-jobsheet-div'
          >
            <Row>
              <Col>
                <div className='print-flex-main-container'>
                  <div className='print-left'>
                    <Row>
                      <Col>
                        <img src={logo} alt='logo' style={{ width: 90 }} />
                      </Col>
                    </Row>
                  </div>

                  <div className='print-center'>
                    <h5>
                      <b>CUSTOMER HISTORY RECORD</b>
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <div className='print-flex-main-container'>
                  <div className='print-left'>
                    <div>
                      <Row>
                        <Col>{state.data.jobsheet_no.value}</Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>{state.data.jobsheet_call_type.value}</Col>
                      </Row>
                    </div>
                  </div>

                  <div className='print-center'>
                    <div>
                      <div>
                        <span>
                          <h5>
                            <b>
                              {nullAndUndefinedSafe(
                                userState.user.companyTitle
                              )}
                            </b>
                          </h5>
                        </span>
                      </div>
                      <div className='company-container'>
                        <div className='company-info-container'>
                          <span>
                            {nullAndUndefinedSafe(userState.user.companyAdd)}
                          </span>
                        </div>
                      </div>
                      <div className='company-container'>
                        <div className='companyInfoContainer'>
                          <span>
                            {nullAndUndefinedSafe(userState.user.companyInfo)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              {/* first column */}
              <Col className='display-right-border'>
                <Row>
                  <Col className='display-right-border'>
                    <b>{state.data.jobsheet_customer_type.value}</b>
                  </Col>
                  <Col>{state.data.jobsheet_customer_name.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Address:</b>
                  </Col>
                  <Col>{state.data.jobsheet_address.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>City:</b>
                  </Col>
                  <Col>{state.data.jobsheet_city.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Pincode:</b>
                  </Col>
                  <Col>{state.data.jobsheet_pincode.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Mobile No.:</b>
                  </Col>
                  <Col>{state.data.jobsheet_mobile_no.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Tel No.:</b>
                  </Col>
                  <Col>{state.data.jobsheet_tel.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Email ID:</b>
                  </Col>
                  <Col>{state.data.jobsheet_email_id.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Invoice No.:</b>
                  </Col>
                  <Col>{state.data.jobsheet_invoice_no.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Invoice Amount:</b>
                  </Col>
                  <Col>{state.data.jobsheet_invoice_amount.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Set Condition:</b>
                  </Col>
                  <Col>{state.data.jobsheet_set_condition.value}</Col>
                </Row>
              </Col>

              {/* second column */}
              <Col>
                <Row>
                  <Col className='display-right-border'>
                    <b>DOP:</b>
                  </Col>
                  <Col>
                    {/* {state.data.jobsheet_dop.value} */}
                    {typeof state.data.jobsheet_dop.value !== 'undefined' &&
                    state.data.jobsheet_dop.value !== null &&
                    state.data.jobsheet_dop.value !== ''
                      ? moment(state.data.jobsheet_dop.value).format('DD/MM/YY')
                      : ''}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Dealer Name:</b>
                  </Col>
                  <Col>{state.data.jobsheet_dealer_name.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Brand:</b>
                  </Col>
                  <Col>{state.data.jobsheet_brand.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Product Category:</b>
                  </Col>
                  <Col>{state.data.jobsheet_product_category.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Model:</b>
                  </Col>
                  <Col>{state.data.jobsheet_model.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Serial No.:</b>
                  </Col>
                  <Col>{state.data.jobsheet_serial_no.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Quantity:</b>
                  </Col>
                  <Col>{state.data.jobsheet_quantity.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Type of Service:</b>
                  </Col>
                  <Col>{state.data.jobsheet_type_of_service.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Warranty:</b>
                  </Col>
                  <Col>{state.data.jobsheet_warranty.value}</Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <b>Others (specify details):</b>
                  </Col>
                  <Col>{state.data.jobsheet_others.value}</Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className='display-right-border'>
                <b>Accessories / Packing:</b>
              </Col>
              <Col>{state.data.jobsheet_accessories_packing.value}</Col>
            </Row>
            <hr />
            <Row>
              <Col className='display-right-border'>
                <b>Defects (Observed by Engineer):</b>
              </Col>
              <Col>{state.data.jobsheet_defects.value}</Col>
            </Row>
            <hr />
            <Row>
              <Col className='display-right-border'>
                <b>Action Taken:</b>
              </Col>
              <Col></Col>
              <Col></Col>
              <Col></Col>
            </Row>
            <hr />
            <Row>
              <Col className='display-right-border'>
                <b>Customer Name & Sign</b>
              </Col>
              <Col>
                <b>Receiving & Delivering Staff</b>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className='display-right-border'>
                <Row>
                  <Col className='display-right-border'>
                    <b>Depositing the Set</b>
                  </Col>
                  <Col>
                    <b>Receiving the Set</b>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <br />
                    <br />
                  </Col>
                  <Col>
                    <br />
                    <br />
                  </Col>
                </Row>
                <hr />

                {/* date row */}
                <Row>
                  <Col className='display-right-border'>
                    <b>Date:</b>
                  </Col>
                  <Col className='display-right-border'>
                    <b>{moment().format('DD/MM/YY')}</b>
                  </Col>
                  <Col className='display-right-border'>
                    <b>Date:</b>
                  </Col>
                  <Col></Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className='display-right-border'>
                    <b>Receiving</b>
                  </Col>
                  <Col>
                    <b>Delivering</b>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className='display-right-border'>
                    <br />
                    <br />
                  </Col>
                  <Col>
                    <br />
                    <br />
                  </Col>
                </Row>
                <hr />

                {/* date row */}
                <Row>
                  <Col className='display-right-border'>
                    <b>Date:</b>
                  </Col>
                  <Col className='display-right-border'>
                    <b>
                      {/* {moment().format('DD/MM/YY')} */}
                      {moment(state.data.jobsheet_submission_date.value).format(
                        'DD/MM/YY'
                      )}
                    </b>
                  </Col>
                  <Col className='display-right-border'>
                    <b>Date:</b>
                  </Col>
                  <Col></Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className='display-right-border'>
                <b>Remarks and Comments:</b>
              </Col>
              <Col></Col>
            </Row>
            <hr />
            <Row>
              <Col style={{ textAlign: 'left' }}>
                If the product is not taken back by the customer within 30 days
                of information of its getting repaired, then it shall be deemed
                that customer is not interested to claim his/her product. No
                claims of such customer shall be entertained thereafter the
                expiry of 30 days.
              </Col>
            </Row>
            <hr />
            <Row>
              <Col style={{ textAlign: 'left' }}>
                <b>Note:</b>
                <br />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col style={{ textAlign: 'left' }}>
                <div>
                  <b>Kripa Electronics (India) Pvt. Ltd.</b>
                </div>
                <div>
                  Toll Free No.: 1-800-22-4244{' '}
                  <span dangerouslySetInnerHTML={{ __html: '&bull;' }} />{' '}
                  E-mail: service@keihifi.com
                </div>
              </Col>
            </Row>
            <hr />
          </Card.Body>
        ) : null}
        <Card.Body
          id='main-container-body-jobsheet'
          className='main-container-body'
        >
          {!insertState ? (
            <>
              <Row>
                <Col>
                  <button
                    className='btn btn-primary'
                    type='button'
                    onClick={downloadForm}
                  >
                    <FontAwesomeIcon icon={faPrint} />
                  </button>
                </Col>
              </Row>
              <hr />{' '}
            </>
          ) : (
            ''
          )}
          <div className='jobsheetContainer'>
            <div className='form-container'>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} sm={4} controlId='consumerType'>
                    <Form.Label>
                      Consumer Type <span className='important'>*</span>
                    </Form.Label>
                    <Form.Control
                      as='select'
                      value={state.data.jobsheet_customer_type.value}
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_customer_type',
                          payload: event.target.value,
                        });
                      }}
                    >
                      <option value='Customer'>Customer</option>
                      <option value='Dealer'>Dealer</option>
                      <option value='KEI Stock Set'>KEI Stock Set</option>
                    </Form.Control>
                    <Form.Control
                      style={{ marginTop: '15px' }}
                      type='text'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_customer_name',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_customer_name.value}
                      placeholder='Enter Name'
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} sm={4} controlId='address'>
                    <Form.Label>
                      Address <span className='important'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      as='textarea'
                      rows={3}
                      value={state.data.jobsheet_address.value}
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_address',
                          payload: event.target.value,
                        });
                      }}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} sm={4} controlId='city'>
                    <Form.Label>
                      City <span className='important'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type='text'
                      placeholder='Enter City'
                      value={state.data.jobsheet_city.value}
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_city',
                          payload: event.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} sm={4} controlId='pincode'>
                    <Form.Label>Pincode</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Pincode'
                      maxLength='6'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_pincode',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_pincode.value}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm={4} controlId='mobileNo'>
                    <Form.Label>
                      Mobile <span className='important'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type='text'
                      placeholder='Enter Mobile Number'
                      maxLength='10'
                      onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        if (
                          event.target.value === '' ||
                          re.test(event.target.value)
                        ) {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_mobile_no',
                            payload: event.target.value,
                          });
                        }
                      }}
                      value={state.data.jobsheet_mobile_no.value}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm={4} controlId='telephoneNo'>
                    <Form.Label>Telephone No</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Telephone Number'
                      maxLength='10'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_tel',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_tel.value}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} sm={4} controlId='emailId'>
                    <Form.Label>Email-Id</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter Email-id'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_email_id',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_email_id.value}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm={4} controlId='dop'>
                    <Form.Label>Date Of Purchase</Form.Label>
                    <Form.Control
                      type='date'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_dop',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_dop.value}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm={4} controlId='dealerName'>
                    <Form.Label>Dealer Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Dealer Name'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_dealer_name',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_dealer_name.value}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} sm={4} controlId='brand'>
                    <Form.Label>
                      Brand <span className='important'>*</span>
                    </Form.Label>
                    <Form.Control
                      required={
                        state.data.jobsheet_brand.value !== '' ? false : true
                      }
                      as='select'
                      value={state.data.jobsheet_brand.value}
                      onChange={(event) => {
                        changeCategory(event);
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_brand',
                          payload: event.target.value,
                        });
                      }}
                    >
                      <option value=''>Select Brand</option>
                      {brandState.map((brand) => {
                        return (
                          <option key={brand + '-' + getKey()} value={brand}>
                            {brand}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <Form.Label
                      style={{
                        textAlign: 'center',
                        width: '100%',
                        marginTop: '15px',
                      }}
                    >
                      OR
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Brand Name'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_brand',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_brand.value}
                      required={
                        state.data.jobsheet_brand.value !== '' ? false : true
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm={4} controlId='category'>
                    <Form.Label>
                      Category <span className='important'>*</span>
                    </Form.Label>
                    <Form.Control
                      required={
                        state.data.jobsheet_product_category.value !== ''
                          ? false
                          : true
                      }
                      as='select'
                      value={state.data.jobsheet_product_category.value}
                      onChange={(event) => {
                        changeProduct(event);
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_product_category',
                          payload: event.target.value,
                        });
                      }}
                    >
                      <option value=''>Select Category</option>
                      {categoryState.map((category) => {
                        return (
                          <option
                            key={category + '-' + getKey()}
                            value={category}
                          >
                            {category}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <Form.Label
                      style={{
                        textAlign: 'center',
                        width: '100%',
                        marginTop: '15px',
                      }}
                    >
                      OR
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Category'
                      required={
                        state.data.jobsheet_product_category.value !== ''
                          ? false
                          : true
                      }
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_product_category',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_product_category.value}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm={4} controlId='model'>
                    <Form.Label>
                      Model <span className='important'>*</span>
                    </Form.Label>
                    <Form.Control
                      required={
                        state.data.jobsheet_model.value !== '' ? false : true
                      }
                      as='select'
                      value={state.data.jobsheet_model.value}
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_model',
                          payload: event.target.value,
                        });
                      }}
                    >
                      <option value=''>Select Model</option>
                      {productState.map((model) => {
                        return (
                          <option key={model + '-' + getKey()} value={model}>
                            {model}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <Form.Label
                      style={{
                        textAlign: 'center',
                        width: '100%',
                        marginTop: '15px',
                      }}
                    >
                      OR
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Model Name'
                      required={
                        state.data.jobsheet_model.value !== '' ? false : true
                      }
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_model',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_model.value}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} sm={4} controlId='serialNo'>
                    <Form.Label>
                      Serial No <span className='important'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type='text'
                      placeholder='Enter Serial No.'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_serial_no',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_serial_no.value}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm={4} controlId='quantity'>
                    <Form.Label>
                      Quantity <span className='important'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type='text'
                      placeholder='Enter Quantity'
                      onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        if (
                          event.target.value === '' ||
                          re.test(event.target.value)
                        ) {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_quantity',
                            payload: event.target.value,
                          });
                        }
                      }}
                      value={state.data.jobsheet_quantity.value}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm={4} controlId='typeOfService'>
                    <Form.Label>
                      Type Of Service <span className='important'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      style={{ marginTop: '5px' }}
                      as='select'
                      value={state.data.jobsheet_type_of_service.value}
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_type_of_service',
                          payload: event.target.value,
                        });
                      }}
                    >
                      <option value='returnable'>Returnable</option>
                      <option value='nonReturnable'>Non Returnable</option>
                      <option value='replacement'>Replacement</option>
                    </Form.Control>
                    {state.data.jobsheet_type_of_service.value ===
                    'nonReturnable' ? (
                      <Form.Control
                        type='text'
                        placeholder='Enter Non-Returnable Reason'
                        onChange={(event) => {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_non_returnable_reason',
                            payload: event.target.value,
                          });
                        }}
                        value={state.data.jobsheet_non_returnable_reason.value}
                      />
                    ) : state.data.jobsheet_type_of_service.value ===
                      'replacement' ? (
                      <Form.Control
                        type='text'
                        placeholder='Enter Replacement Reason'
                        onChange={(event) => {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_replacement_reason',
                            payload: event.target.value,
                          });
                        }}
                        value={state.data.jobsheet_replacement_reason.value}
                      />
                    ) : null}
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} sm={4} controlId='warranty'>
                    <Form.Label>
                      Warranty Type <span className='important'>*</span>
                    </Form.Label>
                    <Form.Check
                      required
                      label='In Warranty'
                      type='radio'
                      id='inWarranty'
                      name='warrantyCheck'
                      value='inWarranty'
                      checked={
                        state.data.jobsheet_warranty.value === 'inWarranty'
                      }
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_warranty',
                          payload: event.target.value,
                        });
                      }}
                    />
                    <Form.Check
                      required
                      label='Out Warranty'
                      type='radio'
                      id='outWarranty'
                      name='warrantyCheck'
                      value='outWarranty'
                      checked={
                        state.data.jobsheet_warranty.value === 'outWarranty'
                      }
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_warranty',
                          payload: event.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm={4} controlId='invoiceNo'>
                    <Form.Label>Invoice No</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Invoice No.'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_invoice_no',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_invoice_no.value}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm={4} controlId='invoiceAmount'>
                    <Form.Label>Invoice Amount</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Invoice Amount'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_invoice_amount',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_invoice_amount.value}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId='others'>
                    <Form.Label>Others (Specify Details)</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Others Reason'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_others',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_others.value}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId='accessoriesPacking'>
                    <Form.Label>Accessories/Packing</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Accessories'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_accessories_packing',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_accessories_packing.value}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId='defects'>
                    <Form.Label>Defects (Observed by Engineer)</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter Observed Defects'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_defects',
                          payload: event.target.value,
                        });
                      }}
                      value={state.data.jobsheet_defects.value}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className='justify-content-center'>
                  <Form.Group
                    style={{ justifyContent: 'center', textAlign: 'center' }}
                    as={Col}
                    sm={12}
                    md={12}
                    xs={12}
                    controlId='quantity'
                  >
                    <Form.Label>Set Condition</Form.Label>
                    <br />
                    <Row className='justify-content-center'>
                      <Form.Check
                        label='Tampered'
                        id='tamperedId'
                        value='tampered'
                        checked={setConditionState.includes('tampered')}
                        onChange={(event) => {
                          handleSetConidtion(event.target.value);
                        }}
                        // checked={(state.data.jobsheet_set_condition.value.split(",").includes("tampered"))}
                        // checked={(state.data.jobsheet_set_condition.value.includes("tampered"))}
                        // onChange={(event) => {
                        //   dispatch({
                        //     type: "setConiditionValueChanged",
                        //     key: "jobsheet_set_condition",
                        //     payload: event.target.value,
                        //   })
                        // }}
                      />
                      <Form.Check
                        label='Broken'
                        id='brokenId'
                        value='broken'
                        checked={setConditionState.includes('broken')}
                        onChange={(event) => {
                          handleSetConidtion(event.target.value);
                        }}
                        // checked={(state.data.jobsheet_set_condition.value.split(",").includes("broken"))}
                        // onChange={(event) => {
                        //   dispatch({
                        //     type: "setConiditionValueChanged",
                        //     key: "jobsheet_set_condition",
                        //     payload: event.target.value,
                        //   })
                        // }}
                      />
                      <Form.Check
                        label='Scratches'
                        id='scratchesId'
                        value='scratches'
                        checked={setConditionState.includes('scratches')}
                        onChange={(event) => {
                          handleSetConidtion(event.target.value);
                        }}
                        // checked={(state.data.jobsheet_set_condition.value.split(",").includes("scratches"))}
                        // onChange={(event) => {
                        //   dispatch({
                        //     type: "setConiditionValueChanged",
                        //     key: "jobsheet_set_condition",
                        //     payload: event.target.value,
                        //   })
                        // }}
                      />
                      <Form.Check
                        label='Missing Parts'
                        id='missingPartsId'
                        value='missingParts'
                        checked={setConditionState.includes('missingParts')}
                        onChange={(event) => {
                          handleSetConidtion(event.target.value);
                        }}
                        // checked={(state.data.jobsheet_set_condition.value.split(",").includes("missingParts"))}
                        // onChange={(event) => {
                        //   dispatch({
                        //     type: "setConiditionValueChanged",
                        //     key: "jobsheet_set_condition",
                        //     payload: event.target.value,
                        //   })
                        // }}
                      />
                    </Row>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId='jobsheetStatus'>
                    <Form.Label>
                      Status <span className='important'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      as='select'
                      value={state.data.jobsheet_status.value}
                      // onChange={handleServiceState}
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_status',
                          payload: event.target.value,
                        });
                      }}
                    >
                      <option value='statusState' disabled>
                        Select Jobsheet Status
                      </option>
                      <option value='pending'>Pending</option>
                      <option value='sparePartPending'>
                        Pending for spare parts
                      </option>
                      <option value='callCancel'>Call Cancel</option>
                      <option value='callClose'>Call Close</option>
                      <option value='others'>Others</option>
                    </Form.Control>
                    {state.data.jobsheet_status.value === 'pending' ? (
                      <Form.Control
                        type='text'
                        placeholder='Enter Pending Reason'
                        onChange={(event) => {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_status_pending_reason',
                            payload: event.target.value,
                          });
                        }}
                        value={state.data.jobsheet_status_pending_reason.value}
                      />
                    ) : state.data.jobsheet_status.value ===
                      'sparePartPending' ? (
                      <Form.Control
                        type='text'
                        placeholder='Enter Pending Part Name'
                        onChange={(event) => {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_status_pending_spare_part_reason',
                            payload: event.target.value,
                          });
                        }}
                        value={
                          state.data.jobsheet_status_pending_spare_part_reason
                            .value
                        }
                      />
                    ) : state.data.jobsheet_status.value === 'callCancel' ? (
                      <Form.Control
                        type='text'
                        placeholder='Enter Call Cancel Reason'
                        onChange={(event) => {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_status_cancel_reason',
                            payload: event.target.value,
                          });
                        }}
                        value={state.data.jobsheet_status_cancel_reason.value}
                      />
                    ) : state.data.jobsheet_status.value === 'others' ? (
                      <Form.Control
                        type='text'
                        placeholder='Enter Others Reason'
                        onChange={(event) => {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_status_other_reason',
                            payload: event.target.value,
                          });
                        }}
                        value={state.data.jobsheet_status_other_reason.value}
                      />
                    ) : state.data.jobsheet_status.value === 'callClose' ? (
                      <Form.Control
                        type='text'
                        placeholder='Call Close By'
                        onChange={(event) => {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_status_close_reason',
                            payload: event.target.value,
                          });
                        }}
                        value={state.data.jobsheet_status_close_reason.value}
                      />
                    ) : null}
                  </Form.Group>
                  <Form.Group as={Col} controlId='callType'>
                    <Form.Label>
                      Call Type <span className='important'>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      as='select'
                      value={state.data.jobsheet_call_type.value}
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_call_type',
                          payload: event.target.value,
                        });
                      }}
                    >
                      <option value='inHouse'>In House</option>
                      <option value='onField'>On Field</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} controlId='invoiceUpload'>
                    <Form.Label>Upload Invoice (2mb)</Form.Label>
                    <Row>
                      <Col md={6}>
                        <Form.File id='invoiceId' onChange={addFile} />
                      </Col>
                      <Col md={6} style={{ marginTop: '-5px' }}>
                        <Button
                          disabled={file === null}
                          type='button'
                          onClick={() => uploadFile(this, 'invoice')}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>
                    {uploadProgress === 100 ? (
                      <Row>
                        <Col>
                          <FontAwesomeIcon
                            icon={invoiceCollapse ? faChevronUp : faChevronDown}
                            onClick={() => setinvoiceCollapse(!invoiceCollapse)}
                          />
                          <Collapse in={invoiceCollapse}>
                            <div>
                              <ImageRenderer
                                imageSource={imageSource}
                                uploadProgress={uploadProgress}
                              />
                            </div>
                          </Collapse>
                        </Col>
                      </Row>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </Form.Row>

                <Form.Row style={{ justifyContent: 'center' }}>
                  <h3>Product Images</h3>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId='productImage1'>
                    <Form.Label>Product Image-1 (2mb)</Form.Label>
                    <Row>
                      <Col>
                        <Form.File id='pdtImg1Id' onChange={addPdtImg1File} />
                      </Col>
                      <Col md={6} style={{ marginTop: '-5px' }}>
                        <Button
                          type='button'
                          onClick={() => uploadFile(this, 'pdt1')}
                          disabled={pdtImg1StateFile === null}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>
                    {pdt1Progress === 100 ? (
                      <Row>
                        <Col>
                          <FontAwesomeIcon
                            icon={productImage1 ? faChevronUp : faChevronDown}
                            onClick={() => setProductImage1(!productImage1)}
                          />
                          <Collapse in={productImage1}>
                            <div>
                              <ImageRenderer
                                imageSource={pdtImg1Src}
                                uploadProgress={pdt1Progress}
                              />
                            </div>
                          </Collapse>
                        </Col>
                      </Row>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group as={Col} controlId='productImage2'>
                    <Form.Label>Product Image-2(2mb) </Form.Label>
                    <Row>
                      <Col>
                        <Form.File id='pdtImg2Id' onChange={addPdtImg2File} />
                      </Col>
                      <Col md={6} style={{ marginTop: '-5px' }}>
                        <Button
                          type='button'
                          onClick={() => uploadFile(this, 'pdt2')}
                          disabled={pdtImg2StateFile === null}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>
                    {pdt2Progress === 100 ? (
                      <Row>
                        <Col>
                          <FontAwesomeIcon
                            icon={productImage2 ? faChevronUp : faChevronDown}
                            onClick={() => setProductImage2(!productImage2)}
                          />
                          <Collapse in={productImage2}>
                            <div>
                              <ImageRenderer
                                imageSource={pdtImg2Src}
                                uploadProgress={pdt2Progress}
                              />
                            </div>
                          </Collapse>
                        </Col>
                      </Row>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group as={Col} controlId='productImage3'>
                    <Form.Label>Product Image-3(2mb) </Form.Label>
                    <Row>
                      <Col>
                        <Form.File id='pdtImg3Id' onChange={addPdtImg3File} />
                      </Col>
                      <Col md={6} style={{ marginTop: '-5px' }}>
                        <Button
                          type='button'
                          onClick={() => uploadFile(this, 'pdt3')}
                          disabled={pdtImg3StateFile === null}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>
                    {pdt3Progress === 100 ? (
                      <Row>
                        <Col>
                          <FontAwesomeIcon
                            icon={productImage3 ? faChevronUp : faChevronDown}
                            onClick={() => setProductImage3(!productImage3)}
                          />
                          <Collapse in={productImage3}>
                            <div>
                              <ImageRenderer
                                imageSource={pdtImg3Src}
                                uploadProgress={pdt3Progress}
                              />
                            </div>
                          </Collapse>
                        </Col>
                      </Row>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  {/* <Form.Group as={Col} controlId="productImage4">
                    <Form.Label>Product Image-4</Form.Label>
                    <Row>
                      <Col>
                        <Form.File
                          id="pdtImg4Id"
                          onChange={addPdtImg4File}
                        />
                      </Col>
                      <Col
                        md={6}
                        style={{ 'marginTop': '-5px' }}
                      >
                        <Button
                          type="button"
                          onClick={() => uploadFile(this, "pdt4")}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>
                    {pdt4Progress===100?<Row><Col>
                      <FontAwesomeIcon icon={productImage4 ? faChevronUp : faChevronDown} onClick={() => setProductImage4(!productImage4)} />
                      <Collapse in={productImage4}>
                        <div>
                          <ImageRenderer
                            imageSource={pdtImg4Src}
                            uploadProgress={pdt4Progress}
                            fileName={pdtImg4StateFile}
                          />
                        </div></Collapse></Col></Row>:''}
                  </Form.Group> */}
                </Form.Row>

                <Form.Row style={{ justifyContent: 'center' }}>
                  <h3>Action Taken</h3>
                </Form.Row>

                <Form.Row style={{ justifyContent: 'center' }}>
                  <Form.Group as={Row} controlId='actionTaken'>
                    <Form.Check
                      type='radio'
                      label='Hardware'
                      id='hardwareId'
                      name='actionTypeCheck'
                      value='Hardware'
                      style={{ marginRight: '10px' }}
                      // onChange={actionSelected}
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_action_type',
                          payload: event.target.value,
                        });
                      }}
                      checked={
                        state.data.jobsheet_action_type.value === 'Hardware'
                      }
                    />
                    <Form.Check
                      type='radio'
                      label='Software'
                      id='softwareId'
                      name='actionTypeCheck'
                      value='Software'
                      onChange={(event) => {
                        dispatch({
                          type: 'valueChanged',
                          key: 'jobsheet_action_type',
                          payload: event.target.value,
                        });
                      }}
                      checked={
                        state.data.jobsheet_action_type.value === 'Software'
                      }
                    />
                  </Form.Group>
                </Form.Row>

                {state.data.jobsheet_action_type.value === 'Hardware' ? (
                  <Form.Row>
                    <Form.Group as={Col} ref={partNameRef} controlId='hardwarePartName'>
                      <Form.Label>Part Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Part Name'
                        autoComplete='off'
                        isInvalid={!state.data.jobsheet_part_name.isValid}
                        onChange={(event) => {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_part_name',
                            payload: event.target.value,
                          });
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_part_number',
                            payload: "",
                          });
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_part_quantity',
                            payload: "",
                          });
                          setIsPartQuantityAvailable(true);
                          setPartQuantityErrorMsg("");
                          if (event.target.value.length > 0)
                            setIsPartNameDropDownOpen(true);
                          else setIsPartNameDropDownOpen(false);
                        }}
                        onKeyDown={() => {
                          var partNameList = inventoryListData;
                          if (partNameList.hasOwnProperty('part_name')) {
                            clearTimeout(partNameRequestTimer);
                            delete partNameList['part_name'];
                          }
                          setInventoryListData(partNameList);
                        }}
                        onKeyUp={(event) => {
                          if ((event.which < 65 &&  event.which != 32 && event.which != 8) || event.which > 90 ) {
                            return false;
                          }
                          setIsPartLoading(true);
                          var inventoryValue = event.target.value;
                          clearTimeout(partNameRequestTimer);
                          var partNameList = inventoryListData;
                          if (partNameList.hasOwnProperty('part_name'))
                            delete partNameList['part_name'];
                          partNameRequestTimer = setTimeout(() => {
                            const config = {
                              url: application.API.INVENTORY_TYPE,
                              method: 'POST',
                              data: {
                                inventory_type: 'part_name',
                                inventory_type_value: inventoryValue,
                              },
                              withCredentials: true,
                            };
                            getInventoryDetails(event, config);
                          }, 500);
                        }}
                        value={state.data.jobsheet_part_name.value}
                      />
                      <Form.Control.Feedback type="invalid">
                        {state.data.jobsheet_part_name.errorMsg}
                      </Form.Control.Feedback>
                      {isPartNameDropDownOpen ? (
                        <div
                          id='auto-complete-list-container'
                          className='autocomplete-items'
                        >
                          {(isPartLoading) ? (
                            <option>Loading...</option>
                          ) : (inventoryListData.hasOwnProperty('part_name')) 
                          ? (
                            inventoryListData['part_name'].map((partName)=>{
                              return (<option 
                                  key={partName + '-' + getKey()} 
                                  value={partName}
                                  onClick={(event) => {
                                    dispatch({
                                      type: 'valueChanged',
                                      key: 'jobsheet_part_name',
                                      payload: event.target.value,
                                    });
                                    setIsPartNameDropDownOpen(false);
                                    clearTimeout(partNameRequestTimer);
                                  }}
                                >
                                  {partName}
                                </option>)
                            })
                          )
                        : <option>No Data Found!</option>}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group as={Col} ref={partCodeRef} controlId='hardwarePartNumber'>
                      <Form.Label>Part Number</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Part Number'
                        autoComplete="off"
                        required={state.data.jobsheet_part_name.value.length>0?true:false}
                        onChange={(event) => {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_part_number',
                            payload: event.target.value,
                          });
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_part_quantity',
                            payload: "",
                          });
                          setIsPartQuantityAvailable(true);
                          setPartQuantityErrorMsg("");
                          if (event.target.value.length > 0)
                            setisPartNumberDropDownOpen(true);
                          else setisPartNumberDropDownOpen(false);
                        }}
                        onKeyDown={() => {
                          var partCodeList = inventoryListData;
                          if (partCodeList.hasOwnProperty('part_code')) {
                            clearTimeout(partNumberRequestTimer);
                            delete partCodeList['part_code'];
                          }
                          setInventoryListData(partCodeList);
                        }}
                        onKeyUp={(event) => {
                          if ((event.which < 65 &&  event.which != 32 && event.which != 8) || event.which > 90 ) {
                            return false;
                          }
                          setIsPartLoading(true);
                          var inventoryValue = event.target.value;
                          clearTimeout(partNumberRequestTimer);
                          var partCodeList = inventoryListData;
                          if (partCodeList.hasOwnProperty('part_code'))
                            delete partCodeList['part_code'];
                          partNumberRequestTimer = setTimeout(() => {
                            const config = {
                              url: application.API.INVENTORY_TYPE,
                              method: 'POST',
                              data: {
                                inventory_type: 'part_code',
                                inventory_type_value: inventoryValue,
                                attributes:{
                                  "part_name": state.data.jobsheet_part_name.value
                                }
                              },
                              withCredentials: true,
                            };
                            getInventoryDetails(event, config);
                          }, 500);
                        }}
                        value={state.data.jobsheet_part_number.value}
                      />
                      {isPartNumberDropDownOpen ? (
                        <div
                          id='auto-complete-list-container'
                          className='autocomplete-items'
                        >
                          {(isPartLoading) ? (
                            <option>Loading...</option>
                          ) : (inventoryListData.hasOwnProperty('part_code'))
                          ? (
                            inventoryListData['part_code'].map((partCode)=>{
                              return (<option 
                                  key={partCode + '-' + getKey()} 
                                  value={partCode}
                                  onClick={(event) => {
                                    dispatch({
                                      type: 'valueChanged',
                                      key: 'jobsheet_part_number',
                                      payload: event.target.value,
                                    });
                                    setisPartNumberDropDownOpen(false);
                                    clearTimeout(partNumberRequestTimer);
                                  }}
                                >
                                  {partCode}
                                </option>)
                            })
                          )
                        : <option>No Data Found!</option>}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group as={Col} controlId='hardwarePartQuantity'>
                      <Form.Label>Part Quantity</Form.Label>
                      <Form.Control
                        type="number"
                        autoComplete="off"
                        required={state.data.jobsheet_part_name.value.length>0?true:false}
                        placeholder='Enter Part Quantity'
                        isInvalid={(isPartQuantityAvailable === null) ? false : !isPartQuantityAvailable}
                        onChange={(event) => {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_part_quantity',
                            payload: event.target.value,
                          });
                          checkAvailableQuantity(event.target.value, state.data.jobsheet_part_name.value, state.data.jobsheet_part_number.value);
                        }}
                        value={state.data.jobsheet_part_quantity.value}
                      />
                      <Form.Control.Feedback type="invalid">
                        {partQuantityErrorMsg}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId='hardwareOthers'>
                      <Form.Label>Action</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Other Reason'
                        onChange={(event) => {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_status_other_reason',
                            payload: event.target.value,
                          });
                        }}
                        value={state.data.jobsheet_status_other_reason.value}
                      />
                    </Form.Group>
                  </Form.Row>
                ) : (
                  <Form.Row style={{ justifyContent: 'center' }}>
                    <Form.Group as={Col} controlId='softwareAction'>
                      <Form.Label>Action</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Software Action'
                        onChange={(event) => {
                          dispatch({
                            type: 'valueChanged',
                            key: 'jobsheet_software_reason',
                            payload: event.target.value,
                          });
                        }}
                        value={state.data.jobsheet_software_reason.value}
                      />
                    </Form.Group>
                  </Form.Row>
                )}

                <Form.Row>
                  <Button type='submit'>Submit form</Button>
                  <Button style={{ marginLeft: '45px' }} onClick={resetForm}>
                    Reset form
                  </Button>
                  {/* {insertState ? <div>
              <button className="btn btn-primary" type="button" onClick={downloadForm}>Print form</button> </div>: ''} */}
                </Form.Row>
              </Form>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddJobsheet;
