import React from 'react';
import pdfLogo from '../pdf_logo.png';

export default function PdfRenderer(props) {
    let fileName = props.fileName;
    fileName = fileName.substring(fileName.lastIndexOf("/")+1, fileName.length);
    return (
        <div>
            <a href={props.fileName} target="_blank">
                <img src={pdfLogo} height="150" width="150" />
                <h5>{fileName}</h5>
            </a>
        </div>
    )
}
