import {Form, Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useState} from 'react';
import * as XLSX from 'xlsx';
import swal from "@sweetalert/with-react";
import ReactFileReader from 'react-file-reader';
import { Table } from "react-bootstrap";
import readXlsxFile from 'read-excel-file';
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import Loader from "./loader";
import application from "../constants/apiList";
import axios from "axios";
import { data } from "jquery";

const AddBulkProducts = ({user})=>{
    const [fileUploadingError, setFileUploadingError] = useState('');
    const [loader, setLoader] = useState('');
    let bulkProductData;

    const readExcel = (file) =>{
        const promise = new Promise((resolve, reject) =>{
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
    
            fileReader.onload = (e) =>{
                const bufferArray = e.target.result;
    
                const wb = XLSX.read(bufferArray, {type:"buffer"});
    
                const wsname = wb.SheetNames[0];
    
                const ws = wb.Sheets[wsname];
    
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data)
                // console.log();
    
            };
            fileReader.onerror= (error)=>{
                reject(error);
            };
        });
    
        promise.then((d)=>{
            if(d.length <=3000&& d.length > 0){
                bulkProductData = d;
                setFileUploadingError("");
            }else{
                setFileUploadingError("Maximum of 3000 products can be added at once");
            }

        })
        
    }
    const UploadFileFunction = async ()=>{
        setLoader(true);
        const config = {
            url: application.API.APP_PRODUCTS,
            method: "post",
            data: {data: bulkProductData},
            withCredentials: true,
        }
        const response = await axios(config);
        setLoader(false);
        // console.log("response ===> ",response.data);
        if(response.status === 200 && response.data.status === "success"){
            swal("Success", " Product Added", "success");
        }else{
            swal("Failed", "The products were not inserted", "error");
        }
    }
    
    const checkFileExtension = (fileName) => {
        return (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName)[0] : fileName;
    }
    
    const CheckExtension = (e) => {
        const file = e.target.files[0];
        let fileName = file.name;
        
        if(checkFileExtension(fileName) == 'xlsx'){
            // alert('correct file');
            const reader = new FileReader();
            reader.onload = (evt) => {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, {type:'binary'});
            }
            setFileUploadingError("");
            readExcel(file);
        }else{ 
            setFileUploadingError("Please upload .xlsx");
        }
    }
   
    if(loader){
        return <Loader />;
    }
     return (
        <Form> 
        <Form.Row  style={{ justifyContent: "flex-end",marginBottom: "0px", fontSize: "12px" }}>
            <div>
                <span style={{ color: "red"}}> Note: For bulk-uploading please use the below sample file.</span><br/>
                <span style={{ color: "red"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Maximum of 3000 products can be uploaded at a time.</span>          
            </div>
        </Form.Row>
        <Row style={{ justifyContent: "flex-end" }}>
        <Col md={2}>
        <a href="/product_sample.xlsx" download="Sample_file.xlsx"> Download Sample File</a>
        </Col>
        </Row>
         <Form.Row>
            <Form.Group as={Col} md={4} controlId="bulkProductUpload">
                <Form.Label >Upload Bulk Products</Form.Label>
                <Row>
                    <Col md={6}>
                    <Form.File
                          onChange={CheckExtension}
                    />        
                    </Col>  
                    <Col
                        md={6}
                        style={{ 'marginTop': '-5px' }}
                    >

                        <Button
                            id= "submit"
                            type="button"
                            disabled={(fileUploadingError === "") ? false : true}
                            onClick = {UploadFileFunction}
                        >
                          Upload
                        </Button>
                    </Col> 
   
                </Row>
                <p id="error" style={{fontSize:"small", marginTop: "-30px"}} >{fileUploadingError}</p>
            </Form.Group>
        </Form.Row>
    </Form>
    )
}

export default AddBulkProducts;