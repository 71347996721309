import axios from "axios";
import logo from "../resources/images/KEI-logo.png";
import helpers from "../helpers";
import lightningBoltIcon from "../resources/images/lightning-bolt.svg";
import { useForm } from "react-hook-form";
import { UserContext } from "../contexts/userContext";
import {useContext, useState} from 'react';
import  application  from "../constants/apiList";
import { Redirect } from "react-router-dom";
import swal from "@sweetalert/with-react";

const ChangePassword = () => {

  const { register, errors, handleSubmit, watch } = useForm({
    mode: "onBlur",
  });
  const [userState, setUserState] = useContext(UserContext);
  let [password, setPassword] = useState(null);
  let [confirmPassword, setConfirmPassword] = useState(null);
  const [passError, setPassError] = useState(false);
  const [redirectFlag, setRedirectFlag] = useState(false);

  const onSubmit = async (data) => {
    console.log("change password request",application.API.APP_USERURL +"/"+userState.user.userName);
    let result;
    if(password === confirmPassword) {
      const config = {
        method: "PATCH",
        url: application.API.APP_USERURL +"/"+userState.user.userName,
        data: {
          "pchanged": 1,
          "pass": password
        },
        withCredentials: true,
      }
      result = await axios(config);
      if (result.data.status === "success") {
        console.log("ENTERED");
        let userData = userState;
        userData['user']['pchanged'] = 1;
        setUserState(userData);
        setRedirectFlag(true);
        swal("Success", "Details updated successfully", "success");
      }
    }else{
      setPassError(true)
    }
  }

  const passChange = (type, value) => {
    setPassError(false);
    if(type === "password"){
      setPassword(value)
    }else{
      setConfirmPassword(value)
    }
  }


  return (
    <>
    {(!redirectFlag)?
      <div className="wrapper fadeInDown align-middle">
        <div id="formContent">
          <div className="fadeIn first">
            <img src={logo} id="" alt="User Icon" width="100" />
            {
              // check if lightning env set then show lightning icon
              helpers.checkLightingEnv()
              ?
              (
                <img src={lightningBoltIcon} id="" alt="lightning icon" width="30" />
              )
              :
              null
            }
          </div>
          <div className="fadeIn first w-100 d-flex justify-content-center mt-3">
            <div className="w-75">
              <p>Hi, {userState.user.userName} please change the password to proceed further</p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="text"
              className="fadeIn second input-login"
              name="password"
              placeholder="Password"
              onChange={(e) => {passChange("password", e.target.value)}}
              ref={register({
                  required: true,
                  // validate: checkCred,
              })}
            />
            {errors.password && errors.password.type === "required" && (
              <p>This field is required</p>
            )}
            <input
              type="password"
              className="fadeIn third input-login"
              name="changePassword"
              placeholder="Confirm Password"
              onChange={(e) => {passChange("ConfirmPassword", e.target.value)}}
              ref={register({
                required: true,
                // validate: checkCred,
              })}
            />
            {errors.changePassword && errors.changePassword.type === "required" && (
              <p>This field is required</p>
            )}
            {
              passError && (<p>Password doesn't match</p>)
            }
            <div id="formFooter align-middle">
              <input type="submit" className="fadeIn fourth" value="Submit" />
            </div>
          </form>
        </div>
      </div>
      : <Redirect to="/" />
    }
    </>
  )
}

export default ChangePassword;