import React, { Component } from 'react';
import MUIDataTable from "mui-datatables";
import { Col, Card, Form, Jumbotron, Row, Button } from "react-bootstrap";
import application from "../constants/apiList";
import { UserContext } from "../contexts/userContext";
import EditProductModal from "../components/editProductModal";
import swal from "@sweetalert/with-react";
import axios from "axios";
import "../styles/pages.scss";
import Loader from "./loader";
import { Redirect } from "react-router-dom";

let productData = {};
let keyCount = 0;

class ProductsPagination extends Component {

    static contextType = UserContext;

    state = { 
        loadInitData: true,
        loadState: false,
        brandState: [],
        categoryState: [],
        productState: [],
        product_no: '',
        product_version: '',
        status: 'active',
        brand: '',
        category: '',
        data: [],
        count: 1,
        pageData: null,
        productsDataCount: 0,
        rowsPerPage: 10,
        mountModalComponent: false,
        page: 0,
        modalShow: false,
        userData: this.context[0],
        modalData: {
        },
        modalUser: null,
        columns: [
            {
                name: "brand",
                label: "Brand"
            },
            {
                name: "category",
                label: "Category"
            },
            {
                name: "product_no",
                label: "Model"
            },
            {
                name: "product_version",
                label: "Product Version"
            },
            {
                name: "product_warranty",
                label: "Product Warranty"
            },
            {
                name: "last_modified_by",
                label: "Added By"
            },
            {
                name: "status",
                label: "Status",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <span style={{color: (value === 1 ? "green" : "red")}}>{(value === 1) ? "Active" : "In-Active"}</span>
                        )
                    }
                }
            },
            {
                name: "Action",
                options:{
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <Button style={{align: "center"}} onClick={() => {
                                this.setState({  
                                    modalShow: true,
                                    mountModalComponent: true,
                                    modalData: this.state.data[dataIndex]
                                })
                            }}>Edit</Button>
                        )
                    }
                }
            }
        ]
    };

    componentDidMount() {
        const userData = this.state.userData;
        if((userData['loggedInStatus']) && (this.state.loadInitData)){
            this.getProductData();
            this.getData(0, 10, {status: 1});
            this.setState({
                modalUser: userData['user'],
                loadInitData: false
            })
        }
    }

    componentDidUpdate() {
        const userData = this.state.userData;
        if((userData['loggedInStatus']) && (this.state.loadInitData)){
            this.getProductData();
            this.getData(0, 10, {status: 1});
            this.setState({
                modalUser: userData['user'],  
                loadInitData: false
            })
          }
    }

    getProductData = async () => {
        try{
          const config = {
            method: "get",
            url: application.API.APP_PRODUCTS,
            withCredentials: true,
          };
          this.setState({
            loadState: true
          })
          const response = await axios(config);
          this.setState({
              loadState: false
          })
          if ((response.status === 200 || response.status === 304) && response.data.status === "success") {
            productData = response.data.result;
            let brand = Object.keys(response.data.result);
            let category = Object.keys(productData[brand[0]]);
            let productList = productData[brand[0]][category[0]];
    
            this.setState({
              brandState: brand,
              categoryState: category,
              productState: productList
            });
          }else{
            swal({ title: "Error", text: "Internal Server Error product api", icon: "error" });
          }
        }catch(err){
          console.log("error in getProductData :- ",err);
          swal({ title: "Error", text: "Internal Server Error product api", icon: "error" });
        }
    }

    productsFilterChange = (event, key) => {
        let filterData = {};
        let value = event.target.value;
        filterData[key] = value;

        if(key === "brand"){
            let categoryList = Object.keys(productData[value]);
            this.setState({categoryState: categoryList});
            // if(typeof categoryList !== "undefined" && categoryList !== null && categoryList !== ""){
            // }
        }
        if(key === "category"){
            let selectedBrand = this.state.brand;
            let selectedCategory = value;
            let productList = productData[selectedBrand][selectedCategory];
            this.setState({productState: productList});
        }

        this.setState(filterData);
    }

    getKey = () =>{
        return keyCount++;
    }

    getData = async (pageNo, rowCount, filteredData) => {
        try{
            const response = await this.getProductsPaginationData(pageNo, rowCount, filteredData);
            if(response != "error") {
                this.setState({
                    data: response.data,
                    count: response.count,
                    pageData: response.data,
                    productsDataCount: response.count,
                    page: pageNo,
                    rowsPerPage: rowCount
                })
            }
        }catch (error) {
            console.log("error in getData :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
          }
    }

    getProductsPaginationData = async (pageNo, rowCount, filteredReqData) => {
        try{
            const config = {
                withCredentials: true,
                method: "POST",
                url: application.API.APP_PRODUCT_PAGINATION,
                header: "Access-Control-Allow-Headers: X-Requested-With, privatekey",
                data: {
                    attributes:{
                        limit: rowCount,
                        offset: pageNo * rowCount
                    },
                    filteredReq: filteredReqData,
                    columns: [
                        "id",
                        "brand",
                        "category",
                        "product_no",
                        "product_version",
                        "product_warranty",
                        "created_date",
                        "last_modified_by",
                        "status"
                    ]
                }
            }

            const response = await axios(config);
            if (response.data.status === "success") {
                return response.data.result;
            }else{
                swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            }
        }catch (error) {
            console.log("Error in getProductsData :- ",error)
            swal({ title: "Error", text: "Error getting response from the server", icon: "error" });
          }
    }

    closeModal = ({page, rowsPerPage}) =>{
        this.getData(page, rowsPerPage);
        this.setState({
            modalShow: false,
            mountModalComponent: false
        })
    }

    getFilterProductData = () => {
        try{
            let productFilterData = {};
            const data = this.state;
            if(data.brand !== ""){
                productFilterData['brand'] = data.brand;
            }

            if(data.category !== ""){
                productFilterData['category'] = data.category;
            }

            if(data.product_no !== ""){
                productFilterData['product_no'] = data.product_no;
            }

            if(data.product_version !== ""){
                productFilterData['product_version'] = data.product_version;
            }
            
            if(data.status !== ""){
                productFilterData['status'] = (data.status === "active") ? 1 : 0;
            }

            return productFilterData;
        }catch(error){
            console.log("Error in getFilterProductData :- ",error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
    }

    handleFilter = async(event) => {
        try{
            event.preventDefault();
            const filterData = this.getFilterProductData();
            this.setState({loadState: true});
            const response = await this.getProductsPaginationData(this.state.page, this.state.rowsPerPage, filterData);
            if(response !== "error"){
                this.setState({
                  loadState: false,
                  page: this.state.page,
                  rowsPerPage: this.state.rowsPerPage,
                  data: response.data,
                  count: response.count,
                  jobsheetDataCount: response.count
                });
              }else{
                this.setState({loadState: true});
                swal({ title: "Error", text: "Internal Server Error handle filter", icon: "error" });
              }
        }catch (error) {
            console.log("Error in handleFilter :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
    }

    render() { 
        // console.log("Logs:- brandState => ",this.state.brandState);
        // console.log("Logs:- modalData ===> ",this.state.userData)
        if(this.state.loadState){
            return <Loader />;
        }
        else if(!this.state.userData.user.pchanged){
          return <Redirect to={"/change-password"} />;
        }
        else{
            const { data, count, page, rowsPerPage } = this.state;
            const options = {
                page: page,
                rowsPerPage: rowsPerPage,
                count: count,
                serverSide: true,
                filter: true,
                fixedHeader: true,
                filterType: "dropdown",
                print: false,
                search: false,
                responsive: "vertical",
                tableBodyHeight: "550px",
                onTableChange: (action, tableState) => {
                    switch (action) {
                        case "changePage":
                        case "changeRowsPerPage":
                            const filteredData = this.getFilterProductData()
                            this.getData(tableState.page, tableState.rowsPerPage, filteredData);
                        break;
                    }
                }
            }
            return (
                <>
                    <Jumbotron className="jumbotron-title shadow-sm">
                        <h4 style={{ textAlign: "center" }}>Products Detail</h4>
                    </Jumbotron>
                    <Card className="main-container">
                        <Card className="main-container filter-container">
                            <Card.Body className="main-container-body">
                                <Form
                                    noValidate
                                    onSubmit={this.handleFilter}
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} sm={2} controlId="productName">
                                            <Form.Label>Product Name</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                placeholder="Enter Product Name"
                                                value={this.state.product_no}
                                                onChange={(event) => {
                                                    this.productsFilterChange(event, "product_no")
                                                }}
                                            />
                                         </Form.Group>
                                        <Form.Group as={Col} sm={2} controlId="productVersion">
                                            <Form.Label>Product Version</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                placeholder="Enter Product Version"
                                                value={this.state.product_version}
                                                onChange={(event) => {
                                                    this.productsFilterChange(event, "product_version")
                                                }}
                                            />
                                         </Form.Group>
                                        <Form.Group as={Col} sm={2} controlId="productStatus">
                                            <div style={{width: '100%', textAlign: "left"}}>
                                                <Form.Label>Product Status</Form.Label>    
                                            </div>
                                        <Form.Group as={Row} controlId="productStatus" style={{}}>
                                        <Form.Check 
                                            type="radio"
                                            label="Active"
                                            id="activeProduct"
                                            name="productStatusType"
                                            value="active"
                                            style={{ marginRight: "10px" }}
                                            onChange={(event) => {
                                                this.productsFilterChange(event, "status")
                                            }}
                                            checked={this.state.status === "active"}
                                        />
                                        <Form.Check 
                                             type="radio"
                                             label="InActive"
                                             id="inActiveProduct"
                                             name="productStatusType"
                                             value="inactive"
                                             style={{ marginRight: "10px" }}
                                             onChange={(event) => {
                                                this.productsFilterChange(event, "status")
                                            }}
                                            checked={this.state.status === "inactive"}
                                        />  
                                        </Form.Group> 
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} sm={4} controlId="brand">
                                            <Form.Label>Brand</Form.Label>
                                            <Form.Control 
                                                as="select"
                                                value={this.state.brand}
                                                onChange={(event) => {
                                                    this.productsFilterChange(event,"brand");
                                                }}
                                            >
                                                <option value=''>Select Brand</option>
                                                {
                                                    this.state.brandState.map((brand) => {
                                                        return (
                                                        <option key={this.getKey()} value={brand}>{brand}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                            <Form.Label
                                                style={{
                                                    textAlign: "center",
                                                    width: "100%",
                                                    marginTop: "15px",
                                                }}
                                                >
                                                OR
                                                </Form.Label>
                                                <Form.Control
                                                type="text"
                                                placeholder="Enter Brand Name"
                                                value={this.state.brand}
                                                onChange={(event) => {
                                                    this.productsFilterChange(event,"brand");
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={4}>
                                            <Form.Label>Category</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={this.state.category}
                                                onChange={(event) => {
                                                this.productsFilterChange(event,"category");
                                                }}
                                            >
                                                <option value=''>Select Category</option>
                                                {
                                                    this.state.categoryState.map((category) => {
                                                        return (
                                                        <option key={this.getKey()} value={category}>{category}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                            <Form.Label
                                                style={{
                                                    textAlign: "center",
                                                    width: "100%",
                                                    marginTop: "15px",
                                                }}
                                            >
                                            OR
                                            </Form.Label>
                                            <Form.Control 
                                                type="text"
                                                placeholder="Enter Category"
                                                value={this.state.category}
                                                onChange={(event) => {
                                                    this.productsFilterChange(event,"category");
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={4}>
                                        <Form.Label>Model</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={this.state.product_no}
                                            onChange={(event) => {
                                            this.productsFilterChange(event,"product_no");
                                            }}
                                        >
                                            <option value=''>Select Model</option>
                                            {
                                                this.state.productState.map((model) => {
                                                    return (
                                                    <option key={this.getKey()} value={model}>{model}</option>
                                                    )
                                                })
                                            }
                                        </Form.Control>
                                        <Form.Label
                                            style={{
                                            textAlign: "center",
                                            width: "100%",
                                            marginTop: "15px",
                                            }}
                                        >
                                            OR
                                        </Form.Label>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Enter Model Name"
                                            value={this.state.product_no}
                                            onChange={(event) => {
                                            this.productsFilterChange(event,"product_no");
                                            }}
                                        />
                                        </Form.Group>
                                    </Form.Row>       
                                    <Button type="submit">Submit</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Card.Body className="main-container-body">
                            <MUIDataTable 
                                data={data}
                                columns={this.state.columns}
                                options={options}
                            />
                        </Card.Body>
                    </Card>
                    {(this.state.mountModalComponent) 
                    ?  
                    <EditProductModal
                        show={this.state.modalShow}     
                        editProductData={this.state.modalData}
                        productsData={productData}
                        user={this.state.modalUser}
                        closeModalPopup={this.closeModal}
                        rowCount={{page: page, rowsPerPage: rowsPerPage}}
                        onHide={() => {
                            this.setState({
                                modalShow: false,
                                mountModalComponent: false
                            })
                        }}
                    />
                    : null
                    }
                </>
            )
        }
    }
}
 
export default ProductsPagination;