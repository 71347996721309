import React from 'react';
import { Col, Card, Form, Jumbotron, Row } from "react-bootstrap";
import { UserContext } from "../contexts/userContext";
import axios from "axios";
import application from "../constants/apiList";
import swal from "@sweetalert/with-react";
import Button from "react-bootstrap/Button";
import MUIDataTable from "mui-datatables";
import Loader from "./loader";
import moment from 'moment';
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

let keyCount = 0;

class ChallanPagination extends React.Component {
    static contextType = UserContext;
    state = {
        userType: "regular",
        loadInitData: true,
        userInitData: true,
        loadState: true,
        challan_user: "",
        challan_no: "",
        to_company_name: "",
        start_date: "",
        end_date: "",
        end_date_error_msg: "",
        page: 0,
        count: 1,
        challanDataCount: 0,
        filter: true,
        rowsPerPage: 10,
        pageData: null,
        usersState: [],
        data: [],
        userData: this.context[0],
        regularUserColumn: [{
            name: "id",
            label: "Challan No",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const link = "edit-challan/"+value;
                    return (
                        <Link to={link} target="_blank">
                            {value}
                        </Link>
                    );
                }
            }
        },
        {
            name: "to_company_name",
            label: "Company Name",
        },
        {
            name: "to_company_contact",
            label: "Company Contact",
        },
        {
            name: "to_company_gst",
            label: "Company GST",
        },
        {
            name: "to_company_pan",
            label: "Company PAN",
        },
        {
            name: "challan_date",
            label: "Date",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span>{moment(value).format("MMMM Do YYYY, h:mm:ss a")}</span>
                    )
                }
            }
        }],
        columns: [
            {
                name: "id",
                label: "Challan No",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const link = "edit-challan/"+value;
                        return (
                            <Link to={link} target="_blank">
                                {value}
                            </Link>
                        );
                    }
                }
            },
            {
                name: "challan_user",
                label: "User",
            },
            {
                name: "to_company_name",
                label: "Company Name",
            },
            {
                name: "to_company_contact",
                label: "Company Contact",
            },
            {
                name: "to_company_gst",
                label: "Company GST",
            },
            {
                name: "to_company_pan",
                label: "Company PAN",
            },
            {
                name: "challan_date",
                label: "Date",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <span>{moment(value).format("MMMM Do YYYY, h:mm:ss a")}</span>
                        )
                    }
                }
            }
        ]
    }

    componentDidMount() {
        const userData = this.state.userData;
        if((userData['loggedInStatus']) && (this.state.loadInitData)){
            this.getUsers();
            this.getData(0, 10);
            this.setState({
                loadInitData: false,
                userInitData: false
            })
        }
    }
    
    componentDidUpdate() {
        const userData = this.state.userData;
        const user = userData['user'];
        if(userData['loggedInStatus'] && ((user['privilege'] === "super admin") || user['privilege'] === "admin") && (this.state.userInitData)){
            this.getUsers();
            this.setState({
                userInitData: false
            })
        }
    }

    challanFilterDataChange = (event, key) => {
        let filterData = {};
        filterData[key] = event.target.value;
        if(key === "end_date"){
            if(this.state.start_date === ""){
                this.setState({
                  validated: true,
                  end_date_error_msg: "Start date Cannot be empty when end date is selected."
                })
            }
        }
        if(this.state.start_date !== ""){
            const startDate = new Date(this.state.start_date);
            const endDate = new Date(filterData[key]);
            if(startDate > endDate){
                this.setState({
                  validated: true,
                  end_date_error_msg: "Start date must come after end date"
                })
            }else{
                this.setState({
                  validated: false,
                  end_date_error_msg: ""
                })
            }
        }
        this.setState(filterData);
    }

    getUsers = async () => {
        try{
            const config = {
                method: "get",
                url: application.API.REACT_APP_USERS_LIST+"?values=userid",
                withCredentials: true,
            };
            const response = await axios(config);
            if(response.status === 200 && response.data.status === "success"){
                const result = response.data.data;
                let userList = [];
                result.map((user) => {
                    if(user['userid'] !== null || user['userid'] !== ''){
                      userList.push(user['userid'])
                    }
                })
                // console.log("userList ==> ",userList);
                this.setState({
                    usersState: userList
                })
            }
        }catch(err){
            console.log("error in getUsers :- ",err);
            swal({ title: "Error", text: "Internal Server Error in user api", icon: "error" });
        }
    }

    getData = async (pageNo, rowCount) => {
        try {
            const response = await this.getChallanData(pageNo, rowCount, {});
            if(response !== "error"){
                // console.log("data ==> ",response.data);
                this.setState({loadState: false});
                this.setState({
                    data: response.data,
                    count: response.count,
                    pageData: response.data,
                    challanDataCount: response.count
                });
            }
        } catch (error) {
            console.log("error in getData :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
    }

    getChallanData = async (pageNo, rowCount, filteredReqData) => {
        try {
            const userData = this.context[0];
            const user = userData['user'];
            var challanColumns = [];
            if((userData['loggedInStatus'])){
                if(((user['privilege'] !== "super admin") && user['privilege'] !== "admin")){
                    //console.log("GETTING DATA ACC TO USER")
                    filteredReqData['challan_user'] = user['userName'];
                    challanColumns = ["id", "to_company_name", "to_company_contact", "to_company_gst", "to_company_pan", "challan_date"];
                    this.setState({
                        userType: "regular"
                    });
                }else{
                    challanColumns = ["id", "challan_user", "to_company_name", "to_company_contact", "to_company_gst", "to_company_pan", "challan_date"];
                    this.setState({
                        userType: "admin"
                    });
                }
                const config = {
                    withCredentials: true,
                    method: "POST",
                    url: application.API.CHALLAN_PAGINATION,
                    header: "Access-Control-Allow-Headers: X-Requested-With, privatekey",
                    data: {
                        attributes: {
                          limit: rowCount,
                          offset: pageNo * rowCount,
                        },
                        filteredReq:filteredReqData,
                        columns: challanColumns,
                      },
                };
                const response = await axios(config);
                if (response.data.status === "success") {
                    // this.setState({name: "", city: "", result: this.state.name+" successfully inserted !!!"});
                    //console.log("data ",response.data.result);
                    return response.data.result;
                }else{
                    swal({ title: "Error", text: "Error getting response from the server", icon: "error" });
                }
            }
        } catch (error) {
            console.log(error);
            swal({ title: "Error", text: "Error getting response from the server", icon: "error" });
        }
    }

    getKey = () =>{
        return keyCount++;
    }

    render() {
        if (this.state.loadState) {
            // console.log("loader...")
            return <Loader />;
        }
         else if(!this.state.userData.user.pchanged) {
          return <Redirect to={"/change-password"} />;
         } 
        else {
            const { data, count, page, rowsPerPage } = this.state;
            // console.log("data ===> ",data);
            const options = {
                page: page,
                rowsPerPage: rowsPerPage,
                count: count,
                serverSide: true,
                filter: true,
                fixedHeader: true,
                filterType: "dropdown",
                print: false,
                search: false,
                responsive: "vertical",
                tableBodyHeight: "550px"
              };
            return <>
                <Jumbotron className="jumbotron-title shadow-sm">
                    <h4 style={{ textAlign: "center" }}>Challan Details</h4>
                </Jumbotron>
                <Card className="main-container">
                    <Card className="main-container filter-container">
                        <Card.Body className="main-container-body">
                            <Form 
                                onSubmit={this.handleFilter}
                                noValidate
                            >
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="users">
                                        <Form.Label>Users</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={this.state.challan_user}
                                            onChange={(event) => {
                                                this.challanFilterDataChange(event,"challan_user");
                                            }}
                                        >
                                            <option value="">Select User</option>
                                            {
                                                this.state.usersState.map((user) =>{
                                                return (
                                                    <option value={user} key={this.getKey()}>{user}</option>
                                                )
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="challanNo">
                                        <Form.Label>Challan No</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Enter Challan No"
                                            value={this.state.challan_no}
                                            onChange={(event) => {
                                                this.challanFilterDataChange(event,"challan_no");
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="challanCompanyName">
                                        <Form.Label>Challan Compamy Name:</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Company Name"
                                            value={this.state.to_company_name}
                                            onChange={(event) => {
                                                this.challanFilterDataChange(event,"to_company_name");
                                            }}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                <Form.Group as={Col} sm={3} controlId="startDate">
                                <Form.Label>Start Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={this.state.start_date}
                                        onChange={(event) => {
                                            this.challanFilterDataChange(event,"start_date");
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={3} controlId="endDate">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control 
                                        type="date"
                                        value={this.state.end_date}
                                        isInvalid={this.state.validated}
                                        onChange={(event) => {
                                            this.challanFilterDataChange(event,"end_date");
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{this.state.end_date_error_msg}</Form.Control.Feedback>
                                </Form.Group>
                                </Form.Row>
                                <Button type="submit">Submit</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                    <Card.Body className="main-container-body">
                        <MUIDataTable
                            title={"Challan Details"}
                            data={data}
                            columns={(this.state.userType === "regular") ? this.state.regularUserColumn : this.state.columns}
                            options={options}
                        />
                    </Card.Body>
                </Card>
            </>
        }
    }
}
 
export default ChallanPagination;