import React from "react";
import MUIDataTable from "mui-datatables";
import { Col, Form} from "react-bootstrap";
import axios from "axios";
import Loader from "./loader";
import swal from "@sweetalert/with-react";
import application from "../constants/apiList";
import moment from 'moment';
import Button from "react-bootstrap/Button";

let keyCount = 0;

class InventorySummaryPagintaion extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: this.props.userDetails,
            productData: this.props.productData,
            brandState: null,
            categoryState: null,
            productState: null,
            selectedBrand: "",
            showLoader: true,
            loadInitData: true,
            data: [],
            rowsPerPage: 10,
            page: 0,
            count: 1,
            inventoryDataCount: 0,
            pageData: null,
            brand: '',
            part_name: '',
            part_code: '',
            validated: false,
            end_date_error_msg: "",
            superAdmincolumns: [
                {
                  name: "serial_no",
                  label: "Serial No.",
                },
                {
                  name: "last_modified_by",
                  label: "User",
                },
                {
                    name: "brand",
                    label: "Brand",
                },
                {
                    name: "category",
                    label: "Category",
                },
                {
                    name: "product_no",
                    label: "Product No",
                },
                {
                  name: "part_name",
                  label: "Part Name",
                },
                {
                  name: "part_code",
                  label: "Part Code",
                },
                {
                  name: "quantity",
                  label: "Quantity",
                },
                {
                    name: "last_modified_time",
                    label: "Create On",
                },
            ],
            columns: [
                {
                  name: "serial_no",
                  label: "Serial No.",
                },
                {
                    name: "brand",
                    label: "Brand",
                },
                {
                    name: "category",
                    label: "Category",
                },
                {
                    name: "product_no",
                    label: "Product No",
                },
                {
                  name: "part_name",
                  label: "Part Name",
                },
                {
                  name: "part_code",
                  label: "Part Code",
                },
                {
                  name: "quantity",
                  label: "Quantity",
                },
                {
                    name: "last_modified_time",
                    label: "Create On",
                },
            ]
        }
    }

    componentDidMount() {
        this.loadInitialData();
    }

    componentDidUpdate() {
        this.loadInitialData();
    }

    loadInitialData = async () => {
        if((this.state.user['loggedInStatus'] && (this.state.loadInitData))){
            this.setState({
                loadInitData: false,
                brandState: Object.keys(this.state.productData)
            });
            await this.getData(0, 10, {});
            this.setState({
                showLoader: false
            })
        }
    }

    getPaginationData = (responseData, pageNo, rowCount) => {
        // console.log("responseData ===> ",responseData);
        var inventorySummaryPaginationData = [];
        // console.log("startFrom => ",(pageNo*rowCount))
        var i = (pageNo*rowCount) + 1;
        responseData.forEach(element => {
            var data = {} 
            data['serial_no'] = i;
            data['last_modified_by'] = element['last_modified_by'];
            data['brand'] = element['brand'];
            data['category'] = element['category'];
            data['product_no'] = element['product_no'];
            data['part_name'] = element['part_name'];
            data['part_code'] = element['part_code'];
            data['quantity'] = element['quantity'];
            data['last_modified_time'] = moment(element['last_modified_time']).format("MMMM Do YYYY, h:mm:ss a");
            inventorySummaryPaginationData.push(data);
            data = {};
            i++;
        });
        // console.log("inventorySummaryPaginationData ==> ",inventorySummaryPaginationData);
        return inventorySummaryPaginationData;
    }

    getData = async (pageNo, rowCount, filteredReqData) => {
        try{
            if(this.state.user['loggedInStatus']){
                var config;
                if(this.state.user['user']['privilege'] === "super admin"){
                    config = {
                        withCredentials: true,
                        method: "POST",
                        url: application.API.INVENTORY_PAGINATION,
                        header: "Access-Control-Allow-Headers: X-Requested-With, privatekey",
                        data:{
                            type: "SUMMARY",
                            attributes: {
                                limit: rowCount,
                                offset: pageNo * rowCount
                            },
                            filteredReq: filteredReqData,
                            inventoryColumns: ["last_modified_by","brand","category","product_no","part_name","part_code","quantity","last_modified_time"]
                        }
                    }
                }else{
                    config = {
                        withCredentials: true,
                        method: "POST",
                        url: application.API.INVENTORY_PAGINATION,
                        header: "Access-Control-Allow-Headers: X-Requested-With, privatekey",
                        data:{
                            type: "SUMMARY",
                            attributes: {
                                limit: rowCount,
                                offset: pageNo * rowCount
                            },
                            filteredReq: filteredReqData,
                            inventoryColumns: ["brand","category","product_no","part_name","part_code","quantity","last_modified_time"]
                        }
                    }
                }
                const response = await axios(config);
                if (response.data.status === "success") {
                    const paginationData = this.getPaginationData(response.data.result.data, pageNo, rowCount);
                    // console.log("response ====> ",paginationData);
                    this.setState({
                        page: pageNo,
                        rowsPerPage: rowCount,
                        data: paginationData,
                        count: response.data.result.count,
                        pageData: paginationData,
                        inventoryDataCount: response.count
                    });
                }else{
                    console.log("error in getDataResponse :- ", response.data);
                    swal({ title: "Error", text: "Internal Server Error", icon: "error" });
                }
            }
        }catch(error) {
            console.log("error in getData :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
    }

    getKey = () =>{
        return keyCount++;
    }

    inventoryFilterChange = (event, key) => {
        let filterData = {};
        filterData[key] = event.target.value;
        this.setState(filterData);
    }

    getFilterInventoryData = () => {
        let inventoryFilterdData = {};
        let inventorySummaryData = {};
        const data = this.state;
        if(data.brand !== ""){
            inventorySummaryData['brand'] = data.brand;
        }
    
        if(data.part_name !== ""){
          inventorySummaryData['part_name'] = data.part_name;
        }
    
        if(data.part_code !== ""){
          inventorySummaryData['part_code'] = data.part_code;
        }
        // console.log("inventoryFilterdData => ",inventoryFilterdData);
        inventoryFilterdData['inventoryFilterReq'] = inventorySummaryData;
        return inventoryFilterdData;
    }

    handleFilter = async (event) => {
        try{
            event.preventDefault();
            this.setState({showLoader: true});
            const filteredData = this.getFilterInventoryData();
            // console.log("filteredData ===> ",filteredData);
            await this.getData(this.state.page, this.state.rowsPerPage, filteredData);
            this.setState({showLoader: false});
        }catch(err){
            console.log("Error in handleFilter :- ",err);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
    }

    changePage = async (page, rowsPerPage) => {
        try{
            this.setState({showLoader: true});
            const filteredData = this.getFilterInventoryData();
            await this.getData(page, rowsPerPage, filteredData);
            this.setState({showLoader: false});
        } catch (error) {
            console.log("Error in changePage :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
    }

    onFilterChange = (columns, searchedData) => {
        try{
            var searchedKey = [];
            var currentData = this.state.pageData;
            var dataMatchedFlag = true;
            var filteredData = [];
            for (var i = 0; i < searchedData.length; i++) {
                if (searchedData[i].length > 0) {
                  searchedKey.push(i);
                }
            }
            for (var i = 0; i < currentData.length; i++) {
                for (var j = 0; j < searchedKey.length; j++) {
                  //console.log(
                  //   "comparing searchedData[j] :- ",
                  //   searchedData[searchedKey[j]][0],
                  //   " with currentData :- ",
                  //   currentData[i][columns[searchedKey[j]]["name"]]
                  // );
                  if (
                    searchedData[searchedKey[j]][0] !==
                    currentData[i][columns[searchedKey[j]]["name"]]
                  ) {
                    dataMatchedFlag = false;
                  }
                }
                if (dataMatchedFlag) {
                  filteredData.push(currentData[i]);
                } else {
                  dataMatchedFlag = true;
                }
            }

            if (filteredData.length > 0) {
                this.setState({ data: filteredData });
            } else {
                this.setState({ data: currentData });
            }
        } catch (error) {
            console.log("Error in onFilterChange :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
    }

    onSearchClose = (pageLength) => {
        try {
          if (pageLength !== this.state.data) {
            this.setState({
              data: this.state.pageData,
            });
          }
        } catch (error) {
          console.log("Error in onSearchClose :- ", error);
          this.handleError(true, "Something went wrong !!!");
          swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
    };

    changeCategory = (e) => {
        const selectedBrand = e.target.value;
        const productList = [];
        let categoryList;

        if (selectedBrand === "") {
            categoryList = [];
        } else {
            categoryList = Object.keys(this.state.productData[selectedBrand]);
        }

        this.setState({
            categoryState: categoryList,
            productState: productList
        });
    }

    changeProduct = (e) => {
        const selectedCategory = e.target.value;
        const brand = this.state.brand;
        const productList = this.state.productData[brand][selectedCategory];
        this.setState({
            productState: productList
        })
    }

    render() {
        if (this.state.showLoader) {
            return <Loader />;
        }
        else{
            const { data, count, page, rowsPerPage } = this.state;
            const options = {
                page: page,
                rowsPerPage: rowsPerPage,
                count: count,
                serverSide: true,
                filter: true,
                fixedHeader: true,
                filterType: "dropdown",
                print: false,
                search: false,
                responsive: "vertical",
                tableBodyHeight: "550px",
                selectableRows: "none",
                onTableChange: (action, tableState) => {
                    switch (action) {
                        case "changePage":
                        case "changeRowsPerPage":
                            this.changePage(tableState.page, tableState.rowsPerPage);
                        break;
                        case "onSearchClose":
                        case "resetFilters":
                            this.onSearchClose(tableState.rowsPerPage);
                        break;
                        case "filterChange":
                            this.onFilterChange(tableState.columns, tableState.filterList);
                        break;
                        default:
                            //console.log("action not handled.");
                        break;
                    }
                }
            }
            return (
                <>
                    <Form 
                        onSubmit={this.handleFilter}
                        noValidate
                    >
                        <Form.Row>
                            <Form.Group as={Col} sm={3} controlId="brand">
                                <Form.Label>Brand</Form.Label>
                                <Form.Control 
                                    as="select"
                                    value={this.state.brand}
                                    onChange={(event) => {
                                        this.changeCategory(event);
                                        this.inventoryFilterChange(event, 'brand');
                                    }}
                                >
                                    <option value=''>Select Brand</option>
                                    {this.state.brandState.map((brand) => {
                                        return <option key={this.getKey()} value={brand}>{brand}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} sm={4} controlId="category">
                                <Form.Label>Category <span className="important">*</span></Form.Label>
                                <Form.Control
                                    as='select'
                                    value={this.state.category}
                                    onChange={(event) => {
                                        this.changeProduct(event);
                                        this.inventoryFilterChange(event, 'category');
                                    }}
                                >
                                    <option value=''>Select Category</option>
                                    {(this.state.categoryState != null) 
                                    ? this.state.categoryState.map((category) => {
                                        return (
                                        <option key={category + "-" + this.getKey()} value={category}>{category}</option>
                                        )
                                    })
                                    : null}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} sm={4} controlId="model">
                                <Form.Label>Model <span className="important">*</span></Form.Label>
                                <Form.Control
                                    as='select'
                                    value={this.state.product_no}
                                    onChange={(event) => {
                                        this.inventoryFilterChange(event, 'product_no');
                                    }}
                                >
                                    <option value=''>Select Model</option>
                                    {(this.state.productState != null) 
                                    ? this.state.productState.map((model) => {
                                        return (
                                        <option key={model + "-" + this.getKey()} value={model}>{model}</option>
                                        )
                                    })
                                    : null}
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={3} controlId="partName">
                                <Form.Label>Part Name</Form.Label>
                                <Form.Control 
                                    type="text"
                                    placeholder="Enter part Name"
                                    value={this.state.part_name}
                                    autoComplete="off"
                                    onChange={(event) => {
                                        this.inventoryFilterChange(event, "part_name")
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={3} controlId="partCode">
                                <Form.Label>Part Code</Form.Label>
                                <Form.Control 
                                    type="text"
                                    placeholder="Enter part Code"
                                    value={this.state.part_code}
                                    autoComplete="off"
                                    onChange={(event) => {
                                        this.inventoryFilterChange(event, "part_code")
                                    }}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Button type="submit">Submit</Button>
                    </Form>
                    <div className="kei-inventory-paginaton">
                        <MUIDataTable
                            title={"Inventory Management"}
                            data={data}
                            columns={(this.state.user['user']['privilege'] === "super admin") ? this.state.superAdmincolumns : this.state.columns}
                            options={options}
                        />
                    </div>
                </>
            );
        }
    }
}

export default InventorySummaryPagintaion;