import Modal from 'react-bootstrap/Modal';
import application from "../constants/apiList";
import axios from "axios";
import swal from "@sweetalert/with-react";
import React,{useEffect, useState} from 'react';
import Loader from "./loader";
import {Form, Col, Row, Button} from "react-bootstrap";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const ApproveWarrantyPopup = (props) => {
    let warrantyNo = props.data.warrantyNo;
    const [isLoaded, setIsLoaded] = useState(true);
    const [warrantyDetails, setWarrantyDetails] = useState({});
    const [validated, setValidated] = useState(false);
    const [warrantyActionState, setWarrantyActionState] = useState(0);

    useEffect(() => {
        setIsLoaded(false);
        getWarrantyDetails(warrantyNo);
    }, [warrantyNo])

    const getWarrantyDetails = async(warrantyNo) => {
        // console.log("get warranty details for ",warrantyNo);
        const config = {
            url: application.API.WARRANTY +"/"+warrantyNo,
            method: "GET",
            withCredentials: true,
        }
        const response = await axios(config);
        if(response.status === 200 && response.data.status === "success"){
            // console.log("response data ==> ",response.data.data);
            setWarrantyDetails(response.data.data);
            // console.log("warrantyDetails => ",warrantyDetails);
            setIsLoaded(true);
        }else{
            swal("Failed", response.data.message, "error");
        }
    }
    const handleSubmitModal = async (event) => {
        try{
            event.preventDefault();
            setIsLoaded(false);
            const warrantyStatus = parseInt(warrantyActionState);
            const config = {
                url: application.API.WARRANTY +"/"+warrantyNo,
                method: "PATCH",
                data:{
                    warranty_approval: warrantyStatus
                },
                withCredentials: true,
            }
            const response = await axios(config);
            if(response.status === 200 && response.data.status === "success"){
                getWarrantyDetails(warrantyNo);
            }else{
                setIsLoaded(true);
                swal("Failed", response.data.message, "error");
                // props.closeModalPopup();
            }
        }catch(error){
            console.log("error in handleSubmitModal :- ", error)
        }
    }
    if(!isLoaded){
        return <Loader />;
    }
    // console.log("warrantyDetails => ",warrantyDetails);
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="product-modal-style"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Warranty Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmitModal}
                >
                    <Row style={{ justifyContent: "flex-end" }}>
                        <Col md={3}>
                            <Row style={{ justifyContent: "flex-end", marginRight: "5px" }}>
                                <Form.Label>Download Invoice</Form.Label>
                            </Row>
                            <Row style={{ justifyContent: "center"}}>
                                <a href={"public/uploads/"+warrantyDetails['warranty_upload_invoice']} download={warrantyDetails['warranty_upload_invoice']}><FontAwesomeIcon icon={faDownload} /></a>
                            </Row>
                        </Col>
                    </Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={4} controlId="warranty_fname">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control 
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={warrantyDetails['warranty_fname']}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="warranty_mname">
                            <Form.Label>Middle Name</Form.Label>
                            <Form.Control 
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={warrantyDetails['warranty_mname']}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="warranty_sname">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control 
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={warrantyDetails['warranty_sname']}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={4} controlId="warranty_email_id">
                            <Form.Label>Email ID</Form.Label>
                            <Form.Control 
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={warrantyDetails['warranty_email_id']}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="warranty_mobile_no">
                            <Form.Label>Mobile No.</Form.Label>
                            <Form.Control 
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={warrantyDetails['warranty_mobile_no']}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="warranty_state">
                            <Form.Label>State</Form.Label>
                            <Form.Control 
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={warrantyDetails['warranty_state']}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={4} controlId="warranty_brand">
                            <Form.Label>Brand</Form.Label>
                            <Form.Control 
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={warrantyDetails['warranty_brand']}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="warranty_product_category">
                            <Form.Label>Category</Form.Label>
                            <Form.Control 
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={warrantyDetails['warranty_product_category']}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="warranty_model">
                            <Form.Label>Model</Form.Label>
                            <Form.Control 
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={warrantyDetails['warranty_model']}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={4} controlId="warranty_product_serial_no">
                            <Form.Label>Product Serial No.</Form.Label>
                            <Form.Control 
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={warrantyDetails['warranty_product_serial_no']}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="warranty_invoice_no">
                            <Form.Label>Invoice No.</Form.Label>
                            <Form.Control 
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={warrantyDetails['warranty_invoice_no']}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="warranty_purchase_date">
                            <Form.Label>Purchase Date</Form.Label>
                            <Form.Control 
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={moment(warrantyDetails['warranty_purchase_date']).format("DD/MM/YYYY")}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        {(warrantyDetails['warranty_approval'] === 0) 
                        ? <Form.Group as={Col} sm={4} controlId="approveRejectWarranty">
                            <Form.Check 
                                type="radio"
                                label="Approve"
                                id="approvalId"
                                name="warranyAction"
                                value={1}
                                style={{ marginRight: "10px" }}
                                onChange={(e)=>{
                                    setWarrantyActionState(e.target.value)
                                }}
                                checked={(warrantyActionState === "1")}
                            />
                            <Form.Check 
                                type="radio"
                                label="Reject"
                                id="rejectionId"
                                name="warranyAction"
                                value={-1}
                                style={{ marginRight: "10px" }}
                                onChange={(e)=>{
                                    setWarrantyActionState(e.target.value)
                                }}
                                checked={(warrantyActionState === "-1")}
                            />
                        </Form.Group>
                        : <Form.Group as={Col} sm={4} controlId="warranty_approval">
                            <Form.Label>Warranty Status</Form.Label>
                            <Form.Control 
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={(warrantyDetails['warranty_approval'] === 1) ? "Approved" : "Reject"}
                            />
                        </Form.Group>
                        }
                    </Form.Row>
                    {(warrantyDetails['warranty_approval'] === 0) 
                    ? <Form.Row>
                        <Button 
                            type="submit"
                            style={{ marginLeft: "-5px" }}
                            disabled={warrantyActionState === 0 ? true : false}
                        >
                            Submit form
                        </Button>
                    </Form.Row>
                    : null
                    }
                </Form>
                </Modal.Body>
        </Modal>
    )
}

export default ApproveWarrantyPopup;