import React, { Component } from 'react';
import { Col, Card, Form, Jumbotron, Row, Button } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import { UserContext } from "../contexts/userContext";
import swal from "@sweetalert/with-react";
import axios from "axios";
import application from "../constants/apiList";
import Loader from "./loader";
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ViewUserPopup from "../components/viewUserPopup";

class UserPagination extends Component {
    static contextType = UserContext;

    state = { 
        loadInitData: true,
        modalShow: false,
        mountModalComponent: false,
        popupUserName: null,
        data: [],
        count: 1,
        pageData: null,
        userDataCount: 0,
        page: 0,
        rowsPerPage: 10,
        loadState: false,
        selectedStatus: "",
        selectedPrivilege: "All",
        userData: this.context[0],
        columns: [
            {
                name: "userid",
                label: "User Id"
            },
            {
                name: "email",
                label: "Email Id",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <span>{(value === null) ? "NA" : value}</span>
                        )
                    }
                }
            },
            {
                name: "mobile_no",
                label: "Mobile Number",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <span>{(value === null) ? "NA" : value}</span>
                        )
                    }
                }
            },
            {
                name: "privilege",
                label: "Privilege"
            },
            {
                name: "active",
                label: "Status",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <span style={{color: (value === 1 ? "green" : "red")}}>{(value === 1) ? "Active" : "In-Active"}</span>
                        )
                    }
                }
            },
            {
              name: "acion_tab",
              label: "Action",
              options: {
                 customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <FontAwesomeIcon 
                      onClick={() => {
                        console.log("tableMeta ==> ",tableMeta)
                        console.log("rowUserName ==> ",tableMeta['rowData'][0])
                        this.setState({
                          modalShow: true,
                          mountModalComponent: true,
                          popupUserName: tableMeta['rowData'][0]
                        })
                      }}
                      icon={faEdit} 
                      style={{cursor: "pointer"}}
                    />
                  )
                }
              }
            }
        ]
    }

    componentDidMount() {
        const userData = this.state.userData;
        if((userData['loggedInStatus']) && (this.state.loadInitData)){
            this.getData(0, 10, {});
            this.setState({
                loadInitData: false
            })
        }
    }

    componentDidUpdate() {
        const userData = this.state.userData;
        if((userData['loggedInStatus']) && (this.state.loadInitData)){
            this.getData(0, 10, {});
            this.setState({
                loadInitData: false
            })
        }
    }

    getData = async (pageNo, rowCount, filteredData) => {
        try{
            this.setState({loadState: true});
            const response = await this.getUserPaginationData(pageNo, rowCount, filteredData);
            if(response !== "error") {
                this.setState({
                    data: response.data,
                    count: response.count,
                    pageData: response.data,
                    productsDataCount: response.count,
                    page: pageNo,
                    rowsPerPage: rowCount
                })
            }
            this.setState({loadState: false});
        }catch(error){
            console.log("error in getData :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
    }

    getUserPaginationData = async (pageNo, rowCount, filteredReqData) => {
        try{
            const config = {
                withCredentials: true,
                method: "POST",
                url: application.API.APP_USER_PAGINATION,
                header: "Access-Control-Allow-Headers: X-Requested-With, privatekey",
                data: {
                    attributes:{
                        limit: rowCount,
                        offset: pageNo * rowCount
                    },
                    filteredReq: filteredReqData,
                    columns: [
                        "userid",
                        "email",
                        "mobile_no",
                        "privilege",
                        "active"
                    ]
                }
            }

            const response = await axios(config);
            if (response.data.status === "success") {
                return response.data.result;
            }else{
                swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            }
        }catch(error){
            console.log("Error in getUserPaginationData :- ",error)
            swal({ title: "Error", text: "Error getting response from the server", icon: "error" });
        }
    }

    getFilteredUserData = () => {
        let userFilterData = {};
        const data = this.state;
        if(data.selectedStatus !== ""){
            userFilterData['active'] = data.selectedStatus;
        }
        if(data.selectedPrivilege !== "All" && data.selectedPrivilege !== "" ){
            userFilterData['privilege'] = data.selectedPrivilege;
        }
        return userFilterData;
    }

    closeModal = () =>{
        this.setState({
            modalShow: false,
            mountModalComponent: false
        })
    }

    userFilterChange = (event, key) => {
        let filterData = {};
        let value = event.target.value;
        filterData[key] = value;

        this.setState(filterData);
    }

    handleFilter = async(event) => {
        try{
            event.preventDefault();
            const filterData = this.getFilteredUserData();
            this.setState({loadState: true});
            const response = await this.getUserPaginationData(this.state.page, this.state.rowsPerPage, filterData);
            if(response !== "error"){
                this.setState({
                    loadState: false,
                    page: this.state.page,
                    rowsPerPage: this.state.rowsPerPage,
                    data: response.data,
                    count: response.count,
                })
            }else{
                this.setState({loadState: true});
                swal({ title: "Error", text: "Internal Server Error handle filter", icon: "error" });
              }
        }catch (error) {
            console.log("Error in handleFilter :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
    }

    render() {
        if(this.state.loadState){
            return <Loader />;
        }
        else if(!this.state.userData.user.pchanged)
         return <Redirect to={"/change-password"} />;
        else{
            const { data, count, page, rowsPerPage } = this.state;
            const options = {
                page: page,
                rowsPerPage: rowsPerPage,
                count: count,
                serverSide: true,
                filter: true,
                fixedHeader: true,
                filterType: "dropdown",
                print: false,
                search: false,
                responsive: "vertical",
                tableBodyHeight: "550px",
                onTableChange: (action, tableState) => {
                    switch (action) {
                        case "changePage":
                        case "changeRowsPerPage":
                            const filteredData = this.getFilteredUserData()
                            this.getData(tableState.page, tableState.rowsPerPage, filteredData);
                        break;
                        default:
                        break;
                    }
                }
            }
            return (
                <>
                    <Jumbotron className="jumbotron-title shadow-sm">
                        <h4 style={{ textAlign: "center" }}>User Management</h4>
                    </Jumbotron>
                    <Card className="main-container">
                        <Card.Body className="main-container-body">
                            <Form
                                noValidate
                                onSubmit={this.handleFilter}
                            >
                                <Form.Row>
                                    <Form.Group as={Col} sm={2} controlId="user-status">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control 
                                            as="select"
                                            value={this.state.selectedStatus}
                                            onChange={(event) => {
                                                this.userFilterChange(event, "selectedStatus")
                                            }}
                                        >
                                            <option value="">Select Status</option>
                                            <option value="1">Active</option>
                                            <option value="0">In-Active</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} controlId="user-privilege">
                                        <Form.Label>Privilege</Form.Label>
                                        <Form.Control 
                                            as="select"
                                            value={this.state.selectedPrivilege}
                                            onChange={(event) => {
                                                this.userFilterChange(event, "selectedPrivilege")
                                            }}
                                        >
                                            <option value="">Select Privilege</option>
                                            <option value="admin">Admin</option>
                                            <option value="regular user">Regular User</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} xs="auto" controlId="Submit" style={{"marginTop": "inherit", "marginBottom": "-10px"}}>
                                        <Button type="submit" className="mb-2">Submit</Button>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Card.Body>
                        <hr/>
                        <Card.Body className="main-container-body">
                            <MUIDataTable 
                                data={data}
                                columns={this.state.columns}
                                options={options}
                            />
                        </Card.Body>
                    </Card>
                    {(this.state.mountModalComponent)
                      ? <ViewUserPopup 
                          show={this.state.modalShow}
                          data={this.state.popupUserName}
                          onHide={() => {
                            this.setState({
                                modalShow: false,
                                mountModalComponent: false
                            })
                            const filteredData = this.getFilteredUserData()
                            this.getData(this.state.page, this.state.rowsPerPage, filteredData);
                          }}
                        />
                      : null
                    }
                </>
            );
        }
    }
}
 
export default UserPagination;