import { Jumbotron, Card, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import React,{useState, useContext, useEffect} from 'react';
import axios from "axios";
import { UserContext } from "../contexts/userContext";
import "../styles/componentsStyles/addProduct.scss";
import AddExistingProduct from "../components/addExistingProduct";
import AddNewProduct from "../components/addNewProduct";
import application from "../constants/apiList";
import swal from "@sweetalert/with-react";
import Loader from "./loader";
import AddBulkProducts from './addBulkProducts';
import Button from "react-bootstrap/Button";
import MyVerticallyCenteredModal from "./addSerialNumber";
import { Redirect } from "react-router-dom";

const AddProduct = () => {
    
    const [actionType, setActionType] = useState("existing");
    const [productData, setProductData] = useState({});
    const [isInitDataLoadStarted, setIsInitDataLoadStarted] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [userState, setUserState] = useContext(UserContext);
    let products;

    useEffect(() => {
        if(userState.loggedInStatus && !isInitDataLoadStarted){
            getProductData();
            setIsInitDataLoadStarted(true);
            setIsLoaded(false);
        }
    })

    const handleAction = (type) => {
        if(type !== actionType){
            setActionType(type);
        }
    }

    const getProductData = async () => {
        try{
            const config = {
                method: "get",
                url: application.API.APP_PRODUCTS,
                withCredentials: true,
            };
            const response = await axios(config);
            if (response.status === 200 && response.data.status === "success") {
                products = response.data.result;
                setProductData(products);
                // let brand = Object.keys(response.data.result);
                // let category = Object.keys(productData[brand[0]]);
                // setBrandState(brand);
                // setCategoryState(category);
                setIsLoaded(true);
            }else {
                swal({ title: "Error", text: "Internal Server Error", icon: "error" }).then(() => {
                    setIsLoaded(true);
                });
            }
        } catch (error) {
            console.log("error in getProductData :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" }).then(() => {
                setIsLoaded(true);
            });
          }
    }

    const submitProduct = async (productData) => {
        try{
            setIsLoaded(false);
            const config = {
                url: application.API.APP_PRODUCT,
                method: 'post',
                data:{
                    brand: productData.brand,
                    category: productData.category,
                    product_no: productData.product_no,
                    product_version: productData.product_version,
                    product_warranty: productData.product_warranty,
                    created_date: productData.createdDate,
                    last_modified_by: productData.user,
                    status: productData.status
                },
                withCredentials: true
            }
            const response = await axios(config);
            setIsLoaded(true);
            if(response.status === 200 && response.data.status === "success"){
                return true;
            }else{
                return false;
            }
        }catch(error){
            console.log("error in submitProduct :- ", error);
            setIsLoaded(true);
            return 'error';
        }
    }
    return (
        <>
        {
            (!isLoaded) 
             ? <Loader /> 
             : (!userState.user.pchanged) 
             ? <Redirect to={"/change-password"} /> :
            <>
                <Jumbotron className="jumbotron-title shadow-sm">
                    <h4 style={{textAlign: "center"}}>Add Products</h4>
                </Jumbotron>
                
                    <Card className="main-container filter-container">
                    <Form.Row  style={{ justifyContent: "flex-end",marginBottom: "0px", fontSize: "12px" }}>
                        <div>
                            <Row style={{justifyContent: "flex-end", marginBottom: "0px",  fontSize: "12px"}}>
                                <Button variant="primary" onClick={() => { setModalShow(true)}}>Add Serial Number</Button>
                                <MyVerticallyCenteredModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    user={userState}
                                /> 
                            </Row>
                        </div>
                    </Form.Row>
                    <Card.Body className="main-container-body">
                        <Form.Row>
                            <Form.Group as={Col} sm={12} controlId="existingProductType">
                                <Form.Check
                                    label="Add Existing Product"
                                    type="radio"
                                    name="addProductType"
                                    checked={(actionType === "existing") ? true : false}
                                    onChange={() => {handleAction("existing")}}
                                />
                                <Form.Check 
                                    id="newProductType"
                                    label="Add New Product"
                                    type="radio"
                                    name="addProductType"
                                    checked={(actionType === "new") ? true : false}
                                    onChange={() => {handleAction("new")}}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Card.Body>
                    <hr/>
                    
                    <AddBulkProducts user={userState}/>
                    <hr/>
                    
                    <Card.Body className="main-container-body">
                        {
                            (actionType === "existing") 
                            ? <AddExistingProduct products={productData} insertProduct={submitProduct}/>
                            : <AddNewProduct insertProduct={submitProduct} user={userState}/>
                        }
                    </Card.Body>
            </Card>
        </>
    }
            
</>)
}

export default AddProduct;