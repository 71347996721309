import { Jumbotron, Card, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import React,{useState, useContext, useEffect} from 'react';
import "../styles/componentsStyles/addProduct.scss";
import * as XLSX from 'xlsx';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import helpers from "../helpers";
import application from "../constants/apiList";
import Loader from "./loader";
import axios from "axios";
import swal from "@sweetalert/with-react";

const MyVerticallyCenteredModal = ({show, onHide, user})=>{
    const [modalShow, setModalShow] = useState(false);
    const [fileUploadingError, setFileUploadingError] = useState('');
    const [loader, setLoader] = useState('');
    let bulkSerialData;
   
    const CheckExtension = async (e) => {
        const file = e.target.files[0];
        let fileName = file.name;
        if(helpers.checkFileExtension(fileName) == 'xlsx'){
            const reader = new FileReader();
            reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type:'binary'});
        }
        setFileUploadingError("");
        readExcel(file);
        }else{
            setFileUploadingError("Please upload .xlsx");
        }
    }

    const readExcel = (file) =>{
        const promise = new Promise((resolve, reject) =>{
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
    
            fileReader.onload = (e) =>{
                const bufferArray = e.target.result;
    
                const wb = XLSX.read(bufferArray, {type:"buffer"});
    
                const wsname = wb.SheetNames[0];
    
                const ws = wb.Sheets[wsname];
    
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };
            fileReader.onerror= (error)=>{
                reject(error);
            };
        });
    
        promise.then((d)=>{
            if(d.length <=3000&& d.length > 0){
                bulkSerialData = d;
                setFileUploadingError("");
            }else{
                setFileUploadingError("Maximum of 3000 products can be added at once");
            }

        })    
    }

    const UploadFileFunction = async ()=>{
        const reqData = {};
        reqData['data'] = bulkSerialData;
        reqData['user'] = user.user.userName;
        setLoader(true);
        const config = {
            url: application.API.APP_PRODUCT_SERIALS,
            method: "post",
            data: reqData,
            withCredentials: true,
        }
        const response = await axios(config);
        setLoader(false);
        onHide(false);
        
        if(response.status === 200 && response.data.status === "success"){
            swal("Success", " Product Added", "success");
        }else{
            swal("Failed", response.data.message, "error");
        }
    }

    if(loader){
        return <Loader />;
    }
        
    return (
            <Modal
                size="xl"
                show={show}
                onHide = {onHide}
            //   marginTop = "12px"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    ADD SERIAL NUMBER       
                </Modal.Title>
                </Modal.Header>
                <Modal.Body style = {{height:"250px"}}>   
                    <Form.Row  style={{ justifyContent: "flex-end",marginBottom: "0px", fontSize: "12px" }}>
                        <div>
                            <span style={{ color: "red"}}> Note: For bulk-uploading please use the below sample file.</span><br/>
                            <a href="/serial_sample.xlsx" download="Sample_file.xlsx"> Download Sample File</a>
    
                        {/* <span style={{ color: "red"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Maximum of 3000 products can be uploaded at a time.</span>        */}
                        </div>
                    </Form.Row>
                    <Form.Row>
                    <Form.Group as={Col} md={6} style={{marginLeft: "25px"}} controlId="bulkProductUpload">
                    <Form.Label>Upload Serial Number </Form.Label>
                    <Row>
                        <Col md={6}>
                        <Form.File
                            name="serialFile"
                            onChange={(event) => {CheckExtension(event)}}
                        />        
                        </Col>  
                            <Col
                                md={6}
                                style={{ 'marginTop': '-5px' }}
                            >
                                <Button
                                    id= "submit"
                                    type="button"
                                        disabled={(fileUploadingError === "") ? false : true}
                                    onClick = {UploadFileFunction}
                                >
                                    Upload
                                </Button>
                            </Col> 
        
                    </Row>
                        <p id="error" style={{fontSize:"small", marginTop: "-30px"}} >{fileUploadingError}</p>  
                    </Form.Group>
                </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
                );

            }

export default MyVerticallyCenteredModal;