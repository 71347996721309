import React,{useState, useContext, useReducer} from 'react';
import {Form, Col, Row} from "react-bootstrap";
import swal from "@sweetalert/with-react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import moment from 'moment';
import { UserContext } from "../contexts/userContext";
import application from "../constants/apiList";

const initialState = {
    brand:{
        isValid: true,
        value: "",
        errorMsg: ""
    },
    category:{
        isValid: true,
        value: "",
        errorMsg: ""
    },
    product_no:{
        isValid: true,
        value: "",
        errorMsg: ""
    },
    product_version:{
        isValid: true,
        value: "",
        errorMsg: ""
    },
    product_warranty:{
        isValid: true,
        value: "",
        errorMsg: ""
    },
    created_date:{
        value: "",
        isValid: true,
        errorMsg: ""
    },
    last_modified_by:{
        value: "",
        isValid: true,
        errorMsg: ""
    },
    status:{
        value: "active",
        isValid: true,
        errorMsg: ""
    }
}

function init(initialData){
    return {
        data: initialData,
    };
}

function reducer(state, action){
    switch (action.type) {
        case "valueChanged":
            let stateData = {};
            let changedValue = {};
            
            changedValue["value"] = action.payload;
            changedValue["isValid"] = true;
            changedValue["errorMsg"] = "";

            state.data[action.key] = changedValue;
            stateData["data"] = state.data;
            
            return stateData;
        case "initialEditState":
            let initialEditState = {};
            initialEditState['data'] = action.payload;
            
            return initialEditState;
        default:
            throw new Error();
    }
}

const AddExistingProduct = ({products, insertProduct}) => {
    const [brandState, setBrandState] = useState(() => {
        return Object.keys(products);
    })
    const [categoryState, setCategoryState] = useState(() => {
        return Object.keys(products[brandState[0]]);
    });
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const [validated, setValidated] = useState(false);
    const [isCategoryTextSelected, setIsCategoryTextSelected] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);
    const [userState, setUserState] = useContext(UserContext);

    let key = 0;

    const getKey = () => {
        return key+1;
    }

    const changeCategory = (event) => {
        const selectedBrand = event.target.value;
        // console.log("Logs:- selectedBrand => ",selectedBrand);
        // console.log("Logs:- selectedBrandCategory => ",Object.keys(products[selectedBrand]));
        setCategoryState(Object.keys(products[selectedBrand]));
    }

    const resetForm = () =>{
        for (let key in initialState) {
            let defaultValue = {};
            defaultValue['isValid'] = true;
            defaultValue['value'] = "";
            defaultValue['errorMsg'] = "";
            initialState[key] = defaultValue;
        }

        initialState['status']['value'] = 'active';
        dispatch({
            type: "initialEditState",
            key: '',
            payload: initialState,
        })
        setValidated(false);
    }


    const handleSubmit = async (event) =>{
        try{
            const form = event.currentTarget;
            setValidated(true);
            const stateData = state.data;
            event.preventDefault();
            if (form.checkValidity()) {
                setIsLoaded(false);
                const createdDate = moment().format('YYYY-MM-DD HH:MM:SS');
                // console.log("Logs:- createdDate => ",createdDate);
                const user = userState.user.userName;
                const config = {
                    url: application.API.APP_PRODUCT,
                    method: 'post',
                    data:{
                        brand: stateData.brand.value,
                        category: stateData.category.value,
                        product_no: stateData.product_no.value,
                        product_version: stateData.product_version.value,
                        product_warranty: stateData.product_warranty.value,
                        created_date: createdDate,
                        last_modified_by: user,
                        status: (stateData.status.value === "active") ? 1 : 0
                    },
                    withCredentials: true
                }
                const response = await axios(config);
                // console.log("Logs:- response ==> ",response);
                if(response){
                    swal({ title: "Success", text: "Product Added", icon: "success" }).then(() => {
                        resetForm();
                    })        
                }else{
                    swal({ title: "Error", text: "Internal Server Error", icon: "error" })
                }
            }
        }catch(error){
            // console.log("error in submitProduct :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" })
            .then(() => {
                setIsLoaded(true);
            });
        }
    } 
    return (
        <>
            <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                  <Form.Row>
                    <Form.Group as={Col} sm={4} controlId="brand">
                        <Form.Label>Brand <span className="important">*</span></Form.Label>
                        <Form.Control
                          required={(state.data.brand !== "") ? false : true}
                          as="select"
                          value={state.data.brand.value}
                          onChange={(event) => {
                                changeCategory(event);
                                dispatch({
                                    type: "valueChanged",
                                    key: "brand",
                                    payload: event.target.value,
                                })
                            }}
                        >
                            <option value=''>Select Brand</option>
                            {
                                brandState.map((brand) => {
                                    return (
                                        <option key={brand + "-" + getKey()} value={brand}>{brand}</option>
                                    )
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} sm={4} controlId="category">
                        <Form.Label>Category <span className="important">*</span></Form.Label>
                        <Form.Control
                           required={(state.data.brand !== "") ? false : true}
                           as="select"
                           value={(isCategoryTextSelected) ? "selectCategory" : state.data.category.value}
                           onChange={(event) => {
                               const selectedCategory = event.target.value;
                               if(selectedCategory !== "selectCategory"){
                                   dispatch({
                                       type: "valueChanged",
                                       key: "category",
                                       payload: event.target.value,
                                   })
                                   setIsCategoryTextSelected(false);
                               }else{
                                    dispatch({
                                        type: "valueChanged",
                                        key: "category",
                                        payload: "",
                                    })
                                   setIsCategoryTextSelected(true);
                               }
                            }}
                        >
                            <option value=''>Select Category</option>
                            <option value='selectCategory'>Add New Category</option>
                            {
                                categoryState.map((category) => {
                                    return (
                                        <option key={category + "-" + getKey()} value={category}>{category}</option>
                                    )
                                })
                            }
                        </Form.Control>
                        {
                            (isCategoryTextSelected) 
                            ? <Form.Control 
                                type="text"
                                placeholder="Enter Category"
                                value={state.data.category.value}
                                onChange={(event) => {
                                    dispatch({
                                        type: "valueChanged",
                                        key: "category",
                                        payload: event.target.value,
                                    })
                                }}
                            />
                            : null
                        }
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                      <Form.Group as={Col} sm={4} controlId="productName">
                          <Form.Label>Product Name <span className="important">*</span></Form.Label>
                          <Form.Control 
                            type="text"
                            placeholder="Enter Product Name"
                            value={state.data.product_no.value}
                            onChange={(event) => {
                                dispatch({
                                    type: "valueChanged",
                                    key: "product_no",
                                    payload: event.target.value,
                                })
                            }}
                            required={(state.data.product_no.value !== "") ? false : true}
                          />
                      </Form.Group>
                      <Form.Group as={Col} sm={4} controlId="productVersion">
                          <Form.Label>Product Version <span className="important">*</span></Form.Label>
                          <Form.Control 
                            type="text"
                            placeholder="Enter Product Version"
                            value={state.data.product_version.value}
                            onChange={(event) => {
                                dispatch({
                                    type: "valueChanged",
                                    key: "product_version",
                                    payload: event.target.value,
                                })
                            }}
                            required={(state.data.product_version.value !== "") ? false : true}
                          />
                      </Form.Group>
                      <Form.Group as={Col} sm={4} controlId="productWarranty">
                          <Form.Label>Product Warranty <span className="important">*</span></Form.Label>
                          <Form.Control 
                            type="text"
                            placeholder="Enter Product Warranty"
                            value={state.data.product_warranty.value}
                            onChange={(event) => {
                                dispatch({
                                    type: "valueChanged",
                                    key: "product_warranty",
                                    payload: event.target.value,
                                })
                            }}
                            required={(state.data.product_warranty.value !== "") ? false : true}
                          />
                      </Form.Group>
                  </Form.Row>
                  <Form.Row style={{ justifyContent: "center" }}>
                    <Form.Group as={Row} controlId="productStatus">
                        <Form.Check 
                            type="radio"
                            label="Active"
                            id="activeProduct"
                            name="productStatusType"
                            value="active"
                            style={{ marginRight: "10px" }}
                            onChange={(event) => {
                                dispatch({
                                    type: "valueChanged",
                                    key: "status",
                                    payload: "active",
                                })
                            }}
                            checked={(state.data.status.value === "active")}
                        />
                        <Form.Check 
                            type="radio"
                            label="InActive"
                            id="inActiveProduct"
                            name="productStatusType"
                            value="inactive"
                            style={{ marginRight: "10px" }}
                            onChange={(event) => {
                                dispatch({
                                    type: "valueChanged",
                                    key: "status",
                                    payload: "inactive",
                                })
                            }}
                            checked={(state.data.status.value === "inactive")}
                        />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Button type="submit">Submit form</Button>
                    <Button style={{ 'marginLeft': '45px' }} onClick={resetForm}>Reset form</Button>
                  </Form.Row>
              </Form>
        </>
    )
}

export default AddExistingProduct;