import React from "react";
import { UserContext } from "../contexts/userContext";
import { Col, Card, Form, Jumbotron, Row, Button } from "react-bootstrap";
import Loader from "./loader";
import MUIDataTable from "mui-datatables";
import application from "../constants/apiList";
import swal from "@sweetalert/with-react";
import axios from "axios";
import { render } from 'react-dom';
import Moment from 'moment';
import { Redirect } from "react-router-dom";

const format1 = "YYYY-MM-DD HH:mm:ss";
let i =1;

class FailedSerialPagination extends React.Component {
    static contextType = UserContext;
    state = {
        loadInitData: true,
        validated: false,
        page: 0,
        count: 1,
        jobsheetDataCount: 0,
        filter: true,
        rowsPerPage: 15,
        data: [],
        userData: this.context[0],
        columns: [
              {
                  
                name: "id",
                label: "SrNo"
            },
            {

                name: "model",
                label: "Model"
            },
            {
                name: "serial",
                label: "Serial"
            },
            {
                name: "reason",
                label: "Reason"
            },
            {
                name: "user",
                label: "User"
            },
            {
                name: "created_date",
                label: "Created Date"

            }
           
        ]
    }

    componentDidMount() {
        const userData = this.state.userData;
        // console.log("componentDidMount ===> ",userData);
        if((userData['loggedInStatus']) && (this.state.loadInitData)){
            this.getFailedSerialData(0, 50, {});
            this.setState({
                loadInitData: false
            })
        }
    }

    componentDidUpdate() {
      const userData = this.state.userData;
      if((userData['loggedInStatus']) && (this.state.loadInitData)){
        this.getFailedSerialData(0, 50, {});
          this.setState({
              loadInitData: false
          })
        }
  }

    getFailedSerialData = async (pageNo, rowCount, filteredReqData) => {
        try{
            const userData = this.state.userData;
            const user = userData['user'];
            if(((user['privilege'] === "super admin") || user['privilege'] === "admin")){
                const config = {
                    withCredentials: true,
                    url: application.API.APP_FAILED_SERIAL_PAGINATION,
                    method: "POST",
                    header: "Access-Control-Allow-Headers: X-Requested-With, privatekey",
                    data: {
                        attributes:{
                            limit: rowCount,
                            offset: pageNo * rowCount
                        },
                        filteredReq: filteredReqData,
                        columns: [
                            "model",
                            "serial",
                            "reason",
                            "user",
                            "created_date"
                        ]
                    }
                }
                const response = await axios(config);
                // console.log("response ==========> ",response.data);
                var failedSerialData = response.data.result.data;
                const arrayLength = response.data.result.data.length;

                for(let i=0; i<arrayLength;i++){
                  failedSerialData[i].id = i+1;
                  let date = failedSerialData[i].created_date;
                  failedSerialData[i].created_date = Moment(date).format('DD-MM-YYYY HH:mm:ss')
                }
                // console.log(failedSerialData);
                this.setState({
                  data: failedSerialData
                })
                // this.setState({
                //     loadInitData: true
                // })
                if (response.data.status === "success") {
                // return response.data.result;

                }else{
                    swal({ title: "Error", text: "Error getting response from the server", icon: "error" });
                }
            }
        }catch(error){
            console.log("Error in getFailedSerialData ==> ",error);
            swal({ title: "Error", text: "Error getting response from the server", icon: "error" });
        }
    }
  
    handleError = (status, message, error) => {
        this.setState({ errorStatus: status, errorMessage: message });
      };
    
    handleFilter = async (event) => {
        try{
          event.preventDefault();
          this.setState({loadState: true});
          //console.log("check filter");
          const filterData = this.getFilterFailedSeriesData();
          const response = await this.getFailedData(this.state.page, this.state.rowsPerPage, filterData);
          this.setState({loadState: false});
          if(response !== "error"){
            this.setState({
              page: this.state.page,
              rowsPerPage: this.state.rowsPerPage,
              data: response.data,
              count: response.count,
              jobsheetDataCount: response.count
            });
          }else{
            swal({ title: "Error", text: "Internal Server Error handle filter", icon: "error" });
          }
        }catch(err){
          console.log("Error in handleFilter :- ",err);
          this.handleError(true, "Something went wrong !!!");
          swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
      }

    getFilterFailedSeriesData =()=>{
        let failedSeriesFilterData = {};
        const data = this.state;
        if(data.start_date !== ""){
            failedSeriesFilterData['start_date'] = data.start_date;
            // console.log(data.start_date+"   data.start_date")
          }
      
          if(data.end_date !== ""){
            failedSeriesFilterData['end_date'] = data.end_date;
          }
          return failedSeriesFilterData;
    }

    failedSerialFilterDataChange = (event, key) => {
        let filterData = {};
        filterData[key] = event.target.value;
        if(key === "end_date"){
          if(this.state.start_date === ""){
            this.setState({
              validated: true,
              end_date_error_msg: "Start date Cannot be empty when end date is selected."
            })
          }
          if(this.state.start_date !== ""){
            const startDate = new Date(this.state.start_date);
            const endDate = new Date(filterData[key]);
            if(startDate > endDate){
              this.setState({
                validated: true,
                end_date_error_msg: "Start date must come after end date"
              })
            }else{
              this.setState({
                validated: false,
                end_date_error_msg: ""
              })
            }
          }
        }
        this.setState(filterData);
      }

    getFailedData = async (pageNo, rowCount, filteredReqData) => {
        try {
          // //console.log("userContext --> ",this.context);
          const userData = this.context[0];
          const user = userData['user'];
          //console.log("user --> ",userData);
          if((userData['loggedInStatus'])){
            if(((user['privilege'] !== "super admin") && user['privilege'] !== "admin")){
              //console.log("GETTING DATA ACC TO USER")
              filteredReqData['jobsheet_user'] = user['userName'];
            }
            const config = {
              withCredentials: true,
              method: "POST",
              url: application.API.APP_FAILED_SERIAL_PAGINATION,
              header: "Access-Control-Allow-Headers: X-Requested-With, privatekey",
              data: {
                attributes: {
                  limit: rowCount,
                  offset: pageNo * rowCount,
                },
                filteredReq:filteredReqData,
                columns: [
                  "id",
                  "model",
                  "serial",
                  "reason",
                  "user",
                  "created_date"
                ],
              },
            };
            //console.log("Calling getData jobsheet");
            const response = await axios(config);
            // //console.log("response ==========> ",response.data);
            this.state.data = response.data.result.data;
            const arrayLength = response.data.result.data.length;

            for(let i=0; i<arrayLength;i++){
              this.state.data[i].id = i+1;
              let date = this.state.data[i].created_date;
              this.state.data[i].created_date = Moment(date).format('DD-MM-YYYY HH:mm:ss')
            }
            if (response.data.status === "success") {
              // this.setState({name: "", city: "", result: this.state.name+" successfully inserted !!!"});
              //console.log("data ",response.data.result);
              return response.data.result;
            }else{
              this.handleError(true, "Error getting response from the server");
              return "error";
            }
          }else{
            return "error";
          }
        }catch (error) {
          console.log(error);
          if (error.response) {
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
          }
          this.handleError(true, "Error getting response from the server");
          swal({ title: "Error", text: "Error getting response from the server", icon: "error" });
        }
      };
      
    render() {
        // console.log("data===>"+ this.state.userData.user.pchanged);
        if(!this.state.userData.user.pchanged) {
          return <Redirect to={"/change-password"} />;
        }
        return (
            <>
              <Jumbotron className="jumbotron-title shadow-sm">
                <h4 style={{ textAlign: "center" }}>Failed serial pagination</h4>
              </Jumbotron>
              <Card>
                <Form>
                  <Form.Row>
                    <Form.Group as={Col} sm={2} controlId="startDate">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control 
                        type="date"
                        value={this.state.start_date}
                        onChange={(event) => {
                          this.failedSerialFilterDataChange(event,"start_date");
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={2} controlId="endDate">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control 
                        type="date"
                        value={this.state.end_date}
                        isInvalid={this.state.validated}
                        onChange={(event) => {
                           this.failedSerialFilterDataChange(event,"end_date");
                        }}
                      />
                      <Form.Control.Feedback type="invalid">{this.state.end_date_error_msg}</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  
                  <center><Button type="submit" id="submitButton"  onClick={this.handleFilter}>Submit</Button></center> 
                    
                </Form>
                <MUIDataTable
                  title="Failed Serials Data"
                  data={this.state.data}
                  columns={this.state.columns}
                    // options={options}
                />
            </Card>
          </>
        )
    }

}

export default FailedSerialPagination;