const modules = [
    {
        id: "1",
        title: "Jobsheet"
    },
    {
        id: "2",
        title: "User"
    },
    {
        id: "3",
        title: "Inventory",
        link: "/view-inventory"
    },
    {
        id: "4",
        title: "Challan"
    }
];

const moduleAction = [
    {
        parentId: "1",
        id: "1-1",
        title: "Add",
        link: "/insert"
    },
    {
        parentId: "1",
        id: "1-2",
        title: "View",
        link: "/viewJobsheet"
    },
    {
        parentId: "2",
        id: "2-1",
        title: "My Profile",
        link: "/my-profile"
    },
    {
        parentId: "4",
        id: "4-1",
        title: "Add Challan",
        link: "/add-challan"
    },
    {
        parentId: "4",
        id: "4-2",
        title: "View Challan",
        link: "/view-challan"
    },
]

export {modules, moduleAction}