import React, { useEffect, useContext, useState } from "react";
import logo from "../resources/images/KEI-logo.png";
import lightningBoltIcon from "../resources/images/lightning-bolt.svg"
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../contexts/userContext";
import { Redirect } from "react-router-dom";
import swal from "@sweetalert/with-react";
import application from "../constants/apiList";
import { modules as adminModules, moduleAction as adminModuleAction } from "../constants/adminHeader";
import { modules as regularUserModules, moduleAction as regularUserModuleAction } from "../constants/regularHeader";
import { modules as superAdminModules, moduleAction as superAdminModuleAction } from "../constants/superAdminHeader";
import helpers from "../helpers"

export const Header = () => {
  const [userState, setUserState] = useContext(UserContext);
  const [logoutState, setLogoutState] = useState(false);
  const [redirectState, setredirectState] = useState({ redirectStatus: false });

  let modulesHeader = [], modulesActionHeader = [];

  const logout = async () => {
    try {
      // setLogoutState(true);
      // setUserState({ loggedInStatus: false });
      const config = {
        method: "delete",
        url: application.API.REACT_APP_USERSLOGOUT,
        withCredentials: true,
        // headers: { authorization: process.env.REACT_APP_AUTH },
      };
      let result = await axios(config);
      if (result.status === 200) {
        //   this.setState({ redirect: "/login" });
        //   return <Redirect to={'/login'} />;
        setLogoutState(true);
        setUserState({ loggedInStatus: false });
      } else {
        swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        //console.log("error");
      }
    } catch (e) {
      swal({ title: "Error", text: "Internal Server Error", icon: "error" });
      console.log("Error from the server", e);
    }
  };

  const redirectAuthenticated = async (path) => {
    path = path === "" || typeof path === "undefined" ? "/" : path;
    try {
      const config = {
        method: "post",
        url: application.API.REACT_APP_USERSCHECKAUTH,
        withCredentials: true,
        // headers: { authorization: process.env.REACT_APP_AUTH },
      };
      // result = await axios(config).then((res) => //console.log("result", res));
      let result = await axios(config);
      if (result.status === 200) {
        console.log("result", result.data);
        if (result.data.data === "success") {
          //console.log("already present", result.data.data);
          setUserContext(result.data.user);
        } else {
          //console.log("making redirectStatus true");
          setredirectState({ redirectStatus: true, redirectValue: "/login" });
        }
      } else {
        //console.log("committing");
        swal({ title: "Error", text: "Internal Server Error", icon: "error" });
      }
    } catch (e) {
      console.log("Error", e);
      swal({ title: "Error", text: "Internal Server Error", icon: "error" });
    }
  };

  const setUserContext = (userData) => {
    let user = {};
    user["loggedInStatus"] = true;
    user["user"] = userData;
    console.log("loggedInUser ===> ", user);
    setUserState(user);
  };

  useEffect(() => {
    //console.log("userState.loggedInStatus ==> ", userState.loggedInStatus);
    if (!userState.loggedInStatus ||typeof userState.loggedInStatus === "undefined") {
      const redirectUrl = window.location.href;
      // //console.log(redirectUrl.split('/')[redirectUrl.split('/').length-1]);
      redirectAuthenticated(
        redirectUrl.split("/")[redirectUrl.split("/").length - 1]
      );
    }
  }, []);

  const getDropDownList = (id) => {
    const requiredModuleAction = modulesActionHeader.filter(moduleActionHeader => moduleActionHeader.parentId === id);
    return (
      requiredModuleAction.map((moduleAction) => {
        return <NavDropdown.Item as={Link} to={moduleAction.link} key={moduleAction.id}>{moduleAction.title}</NavDropdown.Item>  
      })
    )
  }

  if(userState.loggedInStatus){
      if(userState.user['privilege'] === "admin"){
        modulesHeader = adminModules;
        modulesActionHeader = adminModuleAction;
      }
      else if(userState.user['privilege'] === "normal" || userState.user['privilege'] === "regular user"){
        modulesHeader = regularUserModules;
        modulesActionHeader = regularUserModuleAction;
      }
      else if(userState.user['privilege'] === "super admin"){
        modulesHeader = superAdminModules;
        modulesActionHeader = superAdminModuleAction;
      }
  }
  if (redirectState.redirectStatus && !userState.loggedInStatus && window.location.href.split("/")[window.location.href.split("/").length - 1] !== "register-warranty") {
    return <Redirect to={redirectState.redirectValue} />;
  }
  return userState.loggedInStatus ? (
    <Navbar className="header shadow" expand="lg">
      <Navbar.Brand>
        <Link to="/">
          <img src={logo} alt="logo" style={{ width: 70 }} />
          {
              // check if lightning env set then show lightning icon
              helpers.checkLightingEnv()
              ?
              (
                <img src={lightningBoltIcon} id="" alt="lightning icon" width="30" />
              )
              :
              null
          }
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle></Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">

        <Nav className="mr-5">
          <Link className="p-3" to="/" key="0">Home</Link>
          {
            modulesHeader.map((module) => {
              return getDropDownList(module.id).length === 0
              ? <Link className="p-3" to={module.link} key={module.id}>{module.title}</Link> 
              : <NavDropdown className="moduleHeaderDropdown" title={module.title} key={module.id}>
                  {getDropDownList(module.id)}
                </NavDropdown>
            })
          }
          <span className="p-3 logoutText" key={3} onClick={logout} style={{cursor: "pointer"}}>Log Out</span>
        </Nav>

      </Navbar.Collapse>
    </Navbar>
  ) : !logoutState ? null : (
    <Redirect to={"/login"} />
  );
};
