import React from "react";
import MUIDataTable from "mui-datatables";
import { Col, Card, Form, Jumbotron, Row } from "react-bootstrap";
import axios from "axios";
import Loader from "./loader";
import swal from "@sweetalert/with-react";
import { UserContext } from "../contexts/userContext";
import application from "../constants/apiList";
import InventoryHistoryPagintaion from "./inventoryHistoryPagination";
import InventorySummaryPagintaion from "./inventorySummaryPagination";
import { Redirect } from 'react-router-dom';

var userData;
var user;

class InventoryPagintaion extends React.Component {
    static contextType = UserContext;
    
    state = {
        loadInitData: true,
        userInitData: true,
        showLoader: true,
        paginationType: "HISTORY",
        productData: null,
        paginationCount: 0,
        data: [],
        pageData: null,
        count: 1,
        userData: this.context[0],
    }

    getProductData = async () => {
        try{
            const config = {
                method: "get",
                url: application.API.APP_PRODUCTS,
                withCredentials: true,
            };
            const response = await axios(config);
            if (response.status === 200 && response.data.status === "success") {
                // console.log("Brand => ",Object.keys(response.data.result));
                this.setState({
                    productData: response.data.result
                })
                // setBrandState(Object.keys(response.data.result));
                // setIsLoaded(true);
            }
        }catch (error) {
            console.log("error in getProductData :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" }).then(() => {
                // setIsLoaded(true);
            });
          }
    }

    componentDidMount() {
        this.loadInitialData();
    }

    componentDidUpdate() {
        this.loadInitialData();
    }

    loadInitialData = async () => {
        userData = this.context[0];
        user = userData['user'];
        // console.log("User ===> ",user);
        if((userData['loggedInStatus']) && (this.state.loadInitData)){
            this.setState({
                loadInitData: false
            });
            await this.getProductData();
            // await this.getData(0, 10, {});
            this.setState({
                showLoader: false
            })
        }
    }

    handleAction = (type) => {
        if(type !== this.state.paginationType){
            this.setState({paginationType: type});
        }
    }
    
    render() {
        // console.log("brandData ==> ",this.state.brandData);
        if (this.state.showLoader) {
            // console.log("loader...")
            return <Loader />;
        }
        else if(!this.state.userData.user.pchanged)
         return <Redirect to={"/change-password"} />;
        return (
            <>
                <Jumbotron className="jumbotron-title shadow-sm">
                    <h4 style={{ textAlign: "center" }}>Inventory Details</h4>
                </Jumbotron>
                <Card className="main-container">
                    {
                        (user['privilege'] === "super admin") 
                        ?
                            <>
                                <Card.Body className="main-container-body">
                                    <Form.Row>
                                        <Form.Group as={Col} sm={12} controlId="paginationType">
                                                <Form.Check
                                                    label="View History"
                                                    type="radio"
                                                    name="inventoryPagination"
                                                    checked={(this.state.paginationType === "HISTORY") ? true : false}
                                                    onChange={() => {this.handleAction("HISTORY")}}
                                                />
                                                <Form.Check 
                                                    id="summaryId"
                                                    label="View Summary"
                                                    type="radio"
                                                    name="inventoryPagination"
                                                    checked={(this.state.paginationType === "SUMMARY") ? true : false}
                                                    onChange={() => {this.handleAction("SUMMARY")}}
                                                />
                                        </Form.Group>
                                    </Form.Row>
                                </Card.Body>
                                <hr/>
                                <Card.Body className="main-container-body">
                                    {
                                        (this.state.paginationType === "HISTORY") 
                                        ? <InventoryHistoryPagintaion productData={this.state.productData} userDetails={this.context[0]}/>
                                        : <InventorySummaryPagintaion productData={this.state.productData} userDetails={this.context[0]}/>
                                    }
                                </Card.Body>
                            </>
                        :
                        <Card.Body className="main-container-body">
                            <InventorySummaryPagintaion productData={this.state.productData} userDetails={this.context[0]}/>
                        </Card.Body>
                    }
                </Card>
            </>
        );
    }

}

export default InventoryPagintaion;