import {Form, Modal, Button, Col, Row} from 'react-bootstrap';
import Loader from "./loader";
import React,{useState} from "react";
import application from "../constants/apiList";
import axios from "axios";
import swal from "@sweetalert/with-react";

const FindSerialModal = ({show, hide}) => {
    const [modalShow, setModalShow] = useState(show);
    const [serialNoVal, setSerialNoVal] = useState("");
    const [searchResult, setSearchResult] = useState({});
    const [loader, setLoader] = useState('');
    const [validated, setValidated] = useState(false);

    
    const handleSearch = async (e) => {
        const form = e.currentTarget;
        setValidated(true);
        e.preventDefault();
        if (form.checkValidity()) {
            setLoader(true);
            const config = {
                url: application.API.APP_PRODUCT_SERIALS+"/"+serialNoVal,
                method: "get",
                withCredentials: true
            }
            const response = await axios(config)
            setLoader(false);
            if(response.status === 200 && response.data.status === "success"){
                if(response.data.data !== null){
                    setSearchResult({isAvailable: true, msg:"Entered serial number is present"});
                    setValidated(true)
                }else{
                    setSearchResult({isAvailable: false, msg:"Entered serial number is not present"});
                    setValidated(false)
                }
            }else{
                    swal("Failed", response.data.message, "error");
                    hide();
                }
        }
    }
    
    if(loader){
        return <Loader />;
    }

    return (
        <Modal
            size="xl"
            show={show}
            backdrop={true}
            onHide = {hide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Find Serial Number
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form 
                    noValidate
                    validated={validated}
                    onSubmit={handleSearch}
                >
                    <Form.Row style={{justifyContent:"center"}}>
                        <Form.Group as={Col} md={4}  controlId="findSerial">
                            <Form.Control 
                                type="text"
                                placeholder="Enter Serial Number"
                                value={serialNoVal}
                                autoComplete="off"
                                onChange={(e) => {
                                    setSerialNoVal(e.target.value);
                                    setSearchResult("");
                                    // setValidated(false)
                                }}
                                required
                            />
                        </Form.Group>
                    </Form.Row>
                    <Row style={{justifyContent:"center", color: searchResult['isAvailable']?"green" : "red"}}>
                        <span>{searchResult["msg"]}</span>
                    </Row>
                    <Form.Row style={{justifyContent:"center"}}>
                        <Button type="submit">Submit</Button>
                    </Form.Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default FindSerialModal;