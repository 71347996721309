import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import forkodesLogo from "../resources/images/forkodes-logo.svg";
class Footer extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Card className="footer text-center mt-5">
          <Container>
            <Row>
              <Col>
                <div className="footer-content">
                  <a target="_" href="https://forkodes.com">
                    <img
                      src={forkodesLogo}
                      alt="forkodesLogo"
                      style={{ width: 30 }}
                    />
                  </a>
                  <h4 className="text-secondary">
                    Forkodes{" "}
                    <span dangerouslySetInnerHTML={{ __html: "&copy;" }} />{" "}
                    {new Date().getFullYear()}
                  </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </React.Fragment>
    );
  }
}

export default Footer;
