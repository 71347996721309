import React, { Component } from "react";
import { Spinner } from "react-bootstrap";

class Loader extends Component {
  state = {};
  render() {
    return (
      <div className="loaderContainer">
        <div className="loaderInner">
          <Spinner className="loader" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }
}

export default Loader;
