import React from 'react';
import PdfRenderer from "./pdfRenderer";

export default function ImageRenderer(props) {
    return (
        <div className='invoiceUploadContainer'>
            {
                (props.imageSource === null) ? 
                <h4>{(props.uploadProgress === null ) ? null : props.uploadProgress+"%" }</h4> : 
                (props.imageSource.indexOf(".pdf") > 0) ?
                <PdfRenderer fileName={props.imageSource}/>
                :
                <img src={props.imageSource} height="200" width="250" />
            }
        </div>
    )
}
