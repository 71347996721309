import React,{useState, useContext, useEffect, useReducer} from 'react';
import {Form, Col, Row, Jumbotron, Card, Collapse} from "react-bootstrap";
import swal from "@sweetalert/with-react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { UserContext } from "../contexts/userContext";
import application from "../constants/apiList";
import Loader from "./loader";
import ImageRenderer from "./imageRenderer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from "react-router-dom";
import { faChevronDown, faChevronUp, faDownload, faPrint } from '@fortawesome/free-solid-svg-icons'

const initialState = {
    brand:{
        isValid: true,
        value: "",
        errorMsg: ""
    },
    category:{
        isValid: true,
        value: "",
        errorMsg: ""
    },
    product_no:{
        isValid: true,
        value: "",
        errorMsg: ""
    },
    part_name:{
        isValid: true,
        value: "",
        errorMsg: ""
    },
    part_code:{
        isValid: true,
        value: "",
        errorMsg: ""
    },
    quantity:{
        isValid: true,
        value: "",
        errorMsg: ""
    },
    invoice_number:{
        value: "",
        isValid: true,
        errorMsg: ""
    }
}

function init(initialData){
    return {
        data: initialData,
    };
}

function reducer(state, action){
    switch (action.type) {
        case "valueChanged":
            let stateData = {};
            let changedValue = {};
            
            changedValue["value"] = action.payload;
            changedValue["isValid"] = true;
            changedValue["errorMsg"] = "";

            state.data[action.key] = changedValue;
            stateData["data"] = state.data;
            
            return stateData;
        case "initialEditState":
            let initialEditState = {};
            initialEditState['data'] = action.payload;
            
            return initialEditState;
        default:
            throw new Error();
    }
}

let productData;

const AddInventory = () => {

    const [state, dispatch] = useReducer(reducer, initialState, init);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isInitDataLoadStarted, setIsInitDataLoadStarted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [userState, setUserState] = useContext(UserContext);
    const [brandState, setBrandState] = useState(null);
    const [categoryState, setCategoryState] = useState(null);
    const [productState, setProductState] = useState(null);
    const [uploadInvoiceFile, setUploadInvoiceFile] = useState(null);
    const [uploadInvoiceFileProgress, setUploadInvoiceFileProgress] = useState(null);
    const [uploadInvoiceSource, setUploadInvoiceSource] = useState(null);
    const [invoiceCollapse, setInvoiceCollapse] = useState(false);

    let keyCount = 0;

    useEffect(() => {
        if(userState.loggedInStatus && !isInitDataLoadStarted){
            getProductData();
            setIsInitDataLoadStarted(true);
            setIsLoaded(false);
        }
    });

    const getFileSize = (sizeInBytes) => {
        return sizeInBytes/1024/1024;
    }

    const getProductData = async () => {
        try{
            const config = {
                method: "get",
                url: application.API.APP_PRODUCTS,
                withCredentials: true,
            };
            const response = await axios(config);
            if (response.status === 200 && response.data.status === "success") {
                
                productData = response.data.result;
                // console.log("api productData ==> ",productData);
                let brand = Object.keys(response.data.result);
                // let category = Object.keys(productData[brand[0]]);
                // let productList;
                
                setBrandState(Object.keys(response.data.result));
                // setCategoryState(category);
                // setProductState(productList);
                setIsLoaded(true);
            }else {
                swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            }
        }catch (error) {
            console.log("error in getProductData :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" }).then(() => {
                setIsLoaded(true);
            });
          }
    }

    const checkFileExtension = (fileName) => {
        return (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName)[0] : fileName;
    }

    const validateInvoice = (e) => {
        // console.log("imageName :- ", e.target.files[0].name);
        const file = e.target.files[0];
        if(getFileSize(file.size) > 2){
          swal({
            title: "Error",
            text: "The size of the upload should be less than 2mb",
            icon: "error",
          });
          e.target.value = "";
        }
        else if(checkFileExtension(file.name).toLowerCase() !== "pdf"){
            swal({
                title: "Error",
                text: "Only pdf files are allowed",
                icon: "error",
            });
            e.target.value = "";
        }
        setUploadInvoiceFile(e.target.files[0]);
    };

    const uploadFile = async (e) => {
        let form = new FormData();
        form.append("file", uploadInvoiceFile);
        const response = await axios.post(application.API.JOBSHEET_IMAGE_UPLOAD, form, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
                const progressData = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadInvoiceFileProgress(progressData);
            }
        });
        if (response.status === 200) {
            if (response.data["status"] === "success") {
                const imgSrc =
                response.data.data["filePath"] +
                "/" +
                response.data.data["fileName"];
                setUploadInvoiceSource(imgSrc);
            }
        }
    }

    const getKey = () => {
        return keyCount++;
    }

    const resetForm = () => {
        for (let key in initialState) {
            let defaultValue = {};
            defaultValue['isValid'] = true;
            defaultValue['value'] = "";
            defaultValue['errorMsg'] = "";
            initialState[key] = defaultValue;
        }
        dispatch({
          type: "initialEditState",
          key: '',
          payload: initialState,
        })
        setValidated(false);
    }

    const getFileName = (file) => {
        if (file !== null) {
          const fileName = file.split("/")[file.split("/").length - 1];
          return fileName
        }
        return null;
    };

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity()) {
            event.preventDefault();
            const user = userState.user.userName;
            const inventoryData = state.data;
            const uploadInvoiceFileName = getFileName(uploadInvoiceSource);

            var data = {
                last_modified_by: user,
                brand: inventoryData.brand.value,
                category: inventoryData.category.value,
                product_no: inventoryData.product_no.value,
                part_name: inventoryData.part_name.value,
                part_code: inventoryData.part_code.value,
                quantity: inventoryData.quantity.value,
                invoice_number: inventoryData.invoice_number.value,
                inventory_upload_invoice: uploadInvoiceFileName
            };

            var config = {
                url: application.API.INVENTORY_URL,
                method: 'POST',
                data: {
                    insertionType: "HISTORY",
                    data},
                withCredentials: true
            }

            const response = await axios(config);
            if (response.status === 200 && response.data.status === "success") {
                for (let key in initialState) {
                    let defaultValue = {};
                    defaultValue['isValid'] = true;
                    defaultValue['value'] = "";
                    defaultValue['errorMsg'] = "";
                    initialState[key] = defaultValue;
                }
                setValidated(false);
                setInvoiceCollapse(false);
                swal({ title: "Success", text: "Inventory Added", icon: "success" });
            }else {
                swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            }
        }else {
            if(getFileName(uploadInvoiceSource) === null){ 
                swal({ title: "Error", text: "Please upload invoice", icon: "error" });
            }
            event.preventDefault();
            event.stopPropagation();
        }
    }

    const changeCategory = (e) => {
        const selectedBrand = e.target.value;
        const productList = [];
        let categoryList;
        // console.log("productData ==> ",productData);

        if (selectedBrand === "") {
            categoryList = [];
        } else {
            categoryList = Object.keys(productData[selectedBrand]);
            // console.log("categoryList ===> ",categoryList);
        }
        setCategoryState(categoryList);
        setProductState(productList);
    }

    const changeProduct = (e) => {
        const selectedCategory = e.target.value;
        const brand = state.data.brand.value;
        const productList = productData[brand][selectedCategory];
        setProductState(productList);
    }

    if(!isLoaded || brandState === null){
        // console.log("Loading init data");
        return <Loader />;
    }

    return (
      (!userState.user.pchanged) 
      ? <Redirect to={"/change-password"} />
      :
        <>
            <Jumbotron className="jumbotron-title shadow-sm">
                <h4 style={{textAlign: "center"}}>Add Inventory</h4>
            </Jumbotron>
            <Card className="main-container filter-container">
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                >
                    <Form.Row>
                        <Form.Group as={Col} sm={4} controlId="brand">
                            <Form.Label>Brand <span className="important">*</span></Form.Label>
                            <Form.Control
                                required={(state.data.brand.value !== "" ? false : true)}
                                as='select'
                                value={state.data.brand.value}
                                onChange={(event) => {
                                    changeCategory(event);
                                    dispatch({
                                        type: "valueChanged",
                                        key: "brand",
                                        payload: event.target.value,
                                    })
                                }}
                            >
                                <option value=''>Select Brand</option>
                                {
                                    brandState.map((brand) => {
                                        return (
                                            <option key={brand + "-" + getKey()} value={brand}>{brand}</option>
                                        )
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="category">
                            <Form.Label>
                                Category <span className="important">*</span>
                            </Form.Label>
                            <Form.Control
                                required={(state.data.category.value !== "" ? false : true)}
                                as='select'
                                value={state.data.category.value}
                                onChange={(event) => {
                                    changeProduct(event);
                                    dispatch({
                                        type: "valueChanged",
                                        key: "category",
                                        payload: event.target.value,
                                    })
                                }}
                            >
                                <option value=''>Select Category</option>
                                {(categoryState != null) 
                                ? categoryState.map((category) => {
                                    return (
                                      <option key={category + "-" + getKey()} value={category}>{category}</option>
                                    )
                                  })
                                : null}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="model">
                            <Form.Label>Model <span className="important">*</span></Form.Label>
                            <Form.Control
                                required={(state.data.product_no.value !== "" ? false : true)}
                                as='select'
                                value={state.data.product_no.value}
                                onChange={(event) => {
                                    dispatch({
                                        type: "valueChanged",
                                        key: "product_no",
                                        payload: event.target.value,
                                    })
                                }}
                            >
                                <option value=''>Select Model</option>
                                {(productState != null) 
                                ? productState.map((model) => {
                                    return (
                                      <option key={model + "-" + getKey()} value={model}>{model}</option>
                                    )
                                  })
                                : null}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} sm={4} controlId="partName">
                            <Form.Label>Part Name <span className="important">*</span></Form.Label>
                            <Form.Control 
                                type="text"
                                placeholder="Enter Part Name"
                                value={state.data.part_name.value}
                                autoComplete="off"
                                onChange={(event) => {
                                    dispatch({
                                        type: "valueChanged",
                                        key: "part_name",
                                        payload: event.target.value,
                                    })
                                }}
                                required={(state.data.part_name.value !== "") ? false : true}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="partCode">
                            <Form.Label>Part Code <span className="important">*</span></Form.Label>
                            <Form.Control 
                                type="text"
                                placeholder="Enter Part Code"
                                value={state.data.part_code.value}
                                autoComplete="off"
                                onChange={(event) => {
                                    dispatch({
                                        type: "valueChanged",
                                        key: "part_code",
                                        payload: event.target.value,
                                    })
                                }}
                                required={(state.data.part_code.value !== "") ? false : true}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="partQuantity">
                            <Form.Label>Part Quantity <span className="important">*</span></Form.Label>
                            <Form.Control 
                                type="number"
                                placeholder="Enter Part Quantity"
                                value={state.data.quantity.value}
                                autoComplete="off"
                                onChange={(event) => {
                                    dispatch({
                                        type: "valueChanged",
                                        key: "quantity",
                                        payload: event.target.value,
                                    })
                                }}
                                required={(state.data.quantity.value !== "") ? false : true}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>  
                        <Form.Group as={Col} sm={4} controlId="invoiceNumber">
                            <Form.Label>Invoice Number <span className="important">*</span></Form.Label>
                            <Form.Control 
                                type="text"
                                placeholder="Enter Invoice Number"
                                value={state.data.invoice_number.value}
                                autoComplete="off"
                                onChange={(event) => {
                                    dispatch({
                                        type: "valueChanged",
                                        key: "invoice_number",
                                        payload: event.target.value,
                                    })
                                }}
                                required={(state.data.invoice_number.value !== "") ? false : true}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="uploadInvoice">
                            <Form.Label>Upload Invoice (2mb) <span className="important">*</span></Form.Label>
                            <Row>
                        <Col>
                            <Form.Control
                                type="file"
                                onChange={validateInvoice}
                                required={true}
                            />
                        </Col>
                        <Col
                            md={6}
                            style={{ 'marginTop': '-5px' }}
                        >
                            <Button
                            type="button"
                            onClick={() => uploadFile(this)}
                            disabled={uploadInvoiceFile === null}
                            >
                            Upload
                            </Button>
                        </Col>
                        </Row>
                        {uploadInvoiceFileProgress === 100 ? <Row><Col style={{textAlign: "center"}}>
                            <FontAwesomeIcon icon={invoiceCollapse ? faChevronUp : faChevronDown} onClick={() => setInvoiceCollapse(!invoiceCollapse)} />
                            <Collapse in={invoiceCollapse}>
                            <div>
                                <ImageRenderer
                                imageSource={uploadInvoiceSource}
                                uploadProgress={uploadInvoiceFileProgress}
                                />
                            </div>
                            </Collapse></Col></Row> : ''}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Button type="submit">Submit form</Button>
                        <Button style={{ 'marginLeft': '45px' }} onClick={resetForm}>Reset form</Button>
                    </Form.Row>
                </Form>
            </Card>
        </>
    )
}

export default AddInventory;