import Modal from 'react-bootstrap/Modal';
import application from "../constants/apiList";
import axios from "axios";
import swal from "@sweetalert/with-react";
import React,{useEffect, useState} from 'react';
import Loader from "./loader";
import { Col, Button, Form } from "react-bootstrap";

const ViewUserPopup = (props) => {
  let userName = props.data;
  const [isLoaded, setIsLoaded] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [pass, setPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [isActiveUser, setIsActiveUser] = useState("active");
  const [privilege, setPrivilege] = useState(null);

  useEffect(() => {
   setIsLoaded(false);
   getUserDetails(userName);
  }, [userName]);

  const getUserDetails = async (userName) => {
    const config = {
      url: application.API.APP_USERURL + "/" + userName,
      method: "GET",
      withCredentials: true,
    }
    const response = await axios(config);
    if(response.status === 200 && response.data.status === "success"){
      console.log("response data ==> ",response.data.data);
      setIsLoaded(true);
      setIsActiveUser((response.data.data.active) ? "active" : "inactive");
      setPrivilege(response.data.data.privilege);
      setUserDetails(response.data.data);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    //collect the editable data
    let updatedUserData = {};
     let response;
    if(confPass !== "" && confPass === pass) {
      updatedUserData['pass'] = pass;
      updatedUserData['pchanged'] = 1;
    }
    updatedUserData['active'] = (isActiveUser === "active");
    updatedUserData['privilege'] = privilege;
    const config = {
      method: "PATCH",
      url: application.API.APP_USERURL +"/"+userName,
      data: updatedUserData,
      withCredentials: true,
    }
    setIsLoaded(false);
    response = await axios(config);
    if (response.data.status === "success") {
      swal("Success", "Details updated successfully", "success");
      getUserDetails(userName);
      setIsLoaded(true);
    }else{
      setIsLoaded(true);
      swal("Failed", response.data.message, "error");
    }
  }

    if(!isLoaded){
        return <Loader />;
    }
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="product-modal-style"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          User Details
        </Modal.Title>
      </Modal.Header>
       <Modal.Body>
         <Form
          noValidate
          onSubmit={handleSubmit}
         >
           <Form.Row>
             <Form.Group as={Col} sm={4} controlId="userId">
               <Form.Label>User ID</Form.Label>
               <Form.Control 
                type='text'
                disabled={true}
                value={userDetails.userid}
               />
             </Form.Group>
             <Form.Group as={Col} sm={4} controlId="newPass">
               <Form.Label>Password</Form.Label>
               <Form.Control 
                type="password"
                onChange={(event) => {
                  setPass(event.target.value)
                }}
                value={pass}
               />
             </Form.Group>
             <Form.Group as={Col} sm={4} controlId="confirmPass">
               <Form.Label>Confirm Password</Form.Label>
               <Form.Control 
                type="password"
                isInvalid={pass !== confPass}
                onChange={(event) => {
                  setConfPass(event.target.value)
                }}
                value={confPass}
               />
             </Form.Group>
           </Form.Row>
           <Form.Row>
             <Form.Group as={Col} sm={4} controlId="first_name">
               <Form.Label>First Name</Form.Label>
               <Form.Control 
                type="text"
                disabled={true}
                value={userDetails.first_name}
               />
             </Form.Group>
             <Form.Group as={Col} sm={4} controlId="middle_name">
               <Form.Label>Middle Name</Form.Label>
               <Form.Control 
                type="text"
                disabled={true}
                value={userDetails.middle_name}
               />
             </Form.Group>
             <Form.Group as={Col} sm={4} controlId="last_name">
               <Form.Label>Last Name</Form.Label>
               <Form.Control 
                type="text"
                disabled={true}
                value={userDetails.last_name}
               />
             </Form.Group>
           </Form.Row>
           <Form.Row>
             <Form.Group as={Col} sm={6} controlId="adhar_pan">
               <Form.Label>Adhar/Pan No.</Form.Label>
               <Form.Control 
                type="text"
                disabled={true}
                value={userDetails.adhar_pan}
               />
             </Form.Group>
             <Form.Group as={Col} sm={6} controlId="email">
               <Form.Label>Email</Form.Label>
               <Form.Control 
                type="text"
                disabled={true}
                value={userDetails.email}
               />
             </Form.Group>
           </Form.Row>
           <Form.Row>
             <Form.Group as={Col} sm={4} controlId="mobile_no">
               <Form.Label>Mobile No.</Form.Label>
               <Form.Control 
                type="text"
                disabled={true}
                value={userDetails.mobile_no}
               />
             </Form.Group>
             <Form.Group as={Col} sm={4} controlId="franchise">
               <Form.Label>Branch</Form.Label>
               <Form.Control 
                type="text"
                disabled={true}
                value={userDetails.franchise}
               />
             </Form.Group>
             <Form.Group as={Col} sm={4} controlId="gst">
               <Form.Label>GST No.</Form.Label>
               <Form.Control 
                type="text"
                disabled={true}
                style={{fontSize: "14px"}}
                value={userDetails.gst}
               />
             </Form.Group>
           </Form.Row>
           <Form.Row>
             <Form.Group as={Col} sm={4} controlId="company_title">
               <Form.Label>Company Name</Form.Label>
               <Form.Control 
                type="text"
                disabled={true}
                value={userDetails.company_title}
               />
             </Form.Group>
             <Form.Group as={Col} sm={4} controlId="company_add">
               <Form.Label>Company Address</Form.Label>
               <Form.Control 
                as="textarea"
                rows={3}
                disabled={true}
                value={userDetails.company_add}
               />
             </Form.Group>
             <Form.Group as={Col} sm={4} controlId="company_info">
               <Form.Label>Company Contact Info</Form.Label>
               <Form.Control 
                type="text"
                disabled={true}
                value={userDetails.company_info}
               />
             </Form.Group>
           </Form.Row>
           <Form.Row>
             <Form.Group as={Col} sm={4} controlId="authorise">
               <Form.Check
                label="Authorized"
                id="authorizedId"
                checked={userDetails.authorise}
                disabled={true}
              />
             </Form.Group>
             <Form.Group as={Col} sm={6} controlId="userStatus">
               <Form.Check 
                id="activeUserStatus"
                label="Active"
                type="radio"
                name="userStatusValue"
                checked={(isActiveUser === "active")}
                onChange={() => {setIsActiveUser("active")}}
                style={{cursor: "pointer"}}
               />
               <Form.Check 
                label="In Active"
                type="radio"
                id="inActiveUserStatus"
                name="userStatusValue"
                checked={(isActiveUser === "inactive")}
                style={{cursor: "pointer"}}
                onChange={() => {setIsActiveUser("inactive")}}
               />
             </Form.Group>
           </Form.Row>
           <Form.Row>
              <Form.Group as={Col} sm={4} controlId="privilege">
                <Form.Label>Privilege</Form.Label>
                <Form.Control 
                  as="select"
                  value={(privilege)}
                  onChange={(event) => {setPrivilege(event.target.value)}}
                >
                  <option value="admin">Admin</option>
                  <option value="super admin" disabled>Super Admin</option>
                  <option value="regular user">Regular User</option>
                </Form.Control>
              </Form.Group>
           </Form.Row>
           <Form.Row>
             <Button type="submit">Submit</Button>
           </Form.Row>
         </Form>
       </Modal.Body>
    </Modal>
  )
}

export default ViewUserPopup;