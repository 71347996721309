import React from "react";
import MUIDataTable from "mui-datatables";
import { Col, Card, Form, Jumbotron, Row } from "react-bootstrap";
import axios from "axios";
import "../styles/pages.scss";
import "../styles/componentsStyles/jobsheetPagination.scss";
import application from "../constants/apiList";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import Loader from "./loader";
import swal from "@sweetalert/with-react";
import Button from "react-bootstrap/Button";
import FindSerialModal from "./findSerialModal";
import { Redirect } from "react-router-dom";

let productData = {};
let keyCount = 0;

class JobsheetPagintaion extends React.Component {
  static contextType = UserContext;
  state = {
    loadInitData: true,
    userInitData: true,
    loadState: true,
    isAnywhereSearchAvailable: false,
    searchAnywhereKeyword: '',
    page: 0,
    count: 1,
    jobsheetDataCount: 0,
    filter: true,
    rowsPerPage: 10,
    pageData: null,
    filterType: "dropdown",
    jobsheet_no: "",
    jobsheet_type_of_service: "",
    jobsheet_status: "",
    jobsheet_brand: "",
    jobsheet_product_category: "",
    jobsheet_model: "",
    jobsheet_user: "",
    start_date: "",
    end_date: "",
    end_date_error_msg: "",
    validated: false,
    data: [],
    modalShow: false,
    userData: this.context[0],
    columns: [
      {
        name: "jobsheet_no",
        label: "Jobsheet No",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const link = "edit/"+value;
            // //console.log("link --> ",link);
            return (
              <Link to={link} target="_blank">
                {value}
              </Link>
            );
          },
        },
      },
      {
        name: "jobsheet_user",
        label: "Jobsheet User",
      },
      {
        name: "jobsheet_customer_name",
        label: "Customer Name",
      },
      {
        name: "jobsheet_mobile_no",
        label: "Mobile No",
      },
      {
        name: "jobsheet_email_id",
        label: "Email Id",
      },
      {
        name: "jobsheet_model",
        label: "Model",
      },
      {
        name: "jobsheet_serial_no",
        label: "Serial No",
      },
      {
        name: "jobsheet_dealer_name",
        label: "Dealer Name",
      },
    ],
    brandState: [],
    categoryState: [],
    productState: [],
    usersState: [],
    errorStatus: false,
    errorMessage: "",
  };

  componentDidMount() {
    if((this.state.userData['loggedInStatus']) && (this.state.loadInitData)){
      this.getData(0, 10);
      this.getProductData();
      this.setState({
        loadInitData: false
      })
    }
  }

  componentDidUpdate() {
    const userData = this.state.userData;
    const user = userData['user'];
    //console.log("user --> ",userData);
    //console.log("usersState ===> ",this.state.usersState.length);
    //console.log("loadInitData ==> ",this.state.loadInitData);
    if(userData['loggedInStatus'] && ((user['privilege'] === "super admin") || user['privilege'] === "admin") && (this.state.userInitData)){
      this.getUsers();
      this.setState({
        userInitData: false
      })
    }
    if((userData['loggedInStatus']) && (this.state.loadInitData)){
      this.getData(0, 10);
      this.getProductData();
      this.setState({
        loadInitData: false
      })
    }
  }

  getProductData = async () => {
    try{
      const config = {
        method: "get",
        url: application.API.APP_PRODUCTS,
        withCredentials: true,
      };
      const response = await axios(config);
      if ((response.status === 200 || response.status === 304) && response.data.status === "success") {
        productData = response.data.result;
        let brand = Object.keys(response.data.result);
        let category = Object.keys(productData[brand[0]]);
        let productList = productData[brand[0]][category[0]];

        this.setState({
          brandState: brand,
          categoryState: category,
          productState: productList
        });
      }else{
        swal({ title: "Error", text: "Internal Server Error product api", icon: "error" });
      }
    }catch(err){
      console.log("error in getProductData :- ",err);
      swal({ title: "Error", text: "Internal Server Error product api", icon: "error" });
    }
  }

  getUsers = async () => {
    try{
      const config = {
        method: "get",
        url: application.API.REACT_APP_USERS_LIST+"?values=userid",
        withCredentials: true,
      };
      const response = await axios(config);
      if(response.status === 200 && response.data.status === "success"){
        const result = response.data.data;
        let userList = [];
        result.map((user) => {
          if(user['userid'] !== null || user['userid'] !== ''){
            userList.push(user['userid'])
          }
        })
        //console.log("userList ==> ",userList);
        this.setState({
          usersState: userList
        })
      }else{
        swal({ title: "Error", text: "Internal Server Error in user api", icon: "error" });
      }
    }catch(err){
      console.log("error in getUsers :- ",err);
      swal({ title: "Error", text: "Internal Server Error in user api", icon: "error" });
    }
  }

  handleError = (status, message, error) => {
    this.setState({ errorStatus: status, errorMessage: message });
  };

  getData = async (pageNo, rowCount) => {
    try {
      const response = await this.getJobsheetData(pageNo, rowCount, {});
      if(response !== "error"){
        this.setState({loadState: false});
        //console.log("final response.data :- ", response.data);
        //console.log("final response.count :- ", response.count);
        this.setState({
          data: response.data,
          count: response.count,
          pageData: response.data,
          jobsheetDataCount: response.count
        });
      }
    } catch (error) {
      console.log("error in getData :- ", error);
      this.handleError(true, "Error Getting data");
      swal({ title: "Error", text: "Internal Server Error", icon: "error" });
    }
  };

  getJobsheetData = async (pageNo, rowCount, filteredReqData) => {
    try {
      // //console.log("userContext --> ",this.context);
      const userData = this.context[0];
      const user = userData['user'];
      //console.log("user --> ",userData);
      if((userData['loggedInStatus'])){
        if(((user['privilege'] !== "super admin") && user['privilege'] !== "admin")){
          //console.log("GETTING DATA ACC TO USER")
          filteredReqData['jobsheet_user'] = user['userName'];
        }
        const config = {
          withCredentials: true,
          method: "POST",
          url: application.API.JOBSHEET_PAGINATON,
          header: "Access-Control-Allow-Headers: X-Requested-With, privatekey",
          data: {
            attributes: {
              limit: rowCount,
              offset: pageNo * rowCount,
            },
            filteredReq:filteredReqData,
            columns: [
              "jobsheet_no",
              "jobsheet_user",
              "jobsheet_customer_name",
              "jobsheet_mobile_no",
              "jobsheet_email_id",
              "jobsheet_model",
              "jobsheet_serial_no",
              "jobsheet_dealer_name",
            ],
          },
        };
        //console.log("Calling getData jobsheet");
        const response = await axios(config);
        // //console.log("response ==========> ",response.data);
  
        if (response.data.status === "success") {
          // this.setState({name: "", city: "", result: this.state.name+" successfully inserted !!!"});
          //console.log("data ",response.data.result);
          return response.data.result;
        }else{
          this.handleError(true, "Error getting response from the server");
          return "error";
        }
      }else{
        return "error";
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      }
      this.handleError(true, "Error getting response from the server");
      swal({ title: "Error", text: "Error getting response from the server", icon: "error" });
    }
  };

  changePage = async (page, rowsPerPage) => {
    try {
      if(this.state.searchAnywhereKeyword === "" || (!this.state.isAnywhereSearchAvailable)){
        this.setState({loadState: true});
        const filteredData = this.getFilterJobsheetData();
        const response = (this.state.searchAnywhereKeyword !== '') ? this.handleAnywhereSearch(page, rowsPerPage, this.state.searchAnywhereKeyword) : await this.getJobsheetData(page, rowsPerPage, filteredData);
        this.setState({loadState: false});
        //console.log("changePage page => ", page);
        //console.log("DATA => ", response.data);
        this.setState({
          page: page,
          rowsPerPage: rowsPerPage,
          data: response.data,
          count: response.count,
          pageData: response.data,
          jobsheetDataCount: response.count
        });
      }else{
        //console.log("searching anywhere started");
        this.handleAnywhereSearch(page, rowsPerPage, this.state.searchAnywhereKeyword);
      }
    } catch (error) {
      console.log("Error in changePage :- ", error);
      this.handleError(true, "Error while getting jobsheet");
      swal({ title: "Error", text: "Internal Server Error", icon: "error" });
    }
  };

  searchFilter = async (pageData, searchedKeyword) => {
    try {
      const filteredData = pageData.filter((data) => {
        // //console.log(data);
        var checkData = false;
        for (var key in data) {
          // //console.log("data[key] >>> ",data[key]);
          if (data[key] != null) {
            if (data[key].toLowerCase().indexOf(searchedKeyword.toLowerCase()) !==-1) {
              // //console.log("found in :- ",data[key]);
              checkData = true;
              break;
            }
          }
        }
        if (checkData) {
          return data;
        }
        return null;
      });
      // //console.log("filteredData >>> ",filteredData.length);
      if (filteredData.length > 0) {
        this.setState({
          data: filteredData,
        });
      } else {
        this.setState({
          data: pageData,
        });
      }
    } catch (error) {
      console.log("error in searchFilter :- ", error);
      this.handleError(true, "Something went wrong while searching");
      swal({ title: "Error", text: "Internal Server Error", icon: "error" });
    }
  };

  onSearchClose = (pageLength) => {
    try {
      if (pageLength !== this.state.data) {
        this.setState({
          data: this.state.pageData,
        });
      }
    } catch (error) {
      console.log("Error in onSearchClose :- ", error);
      this.handleError(true, "Something went wrong !!!");
      swal({ title: "Error", text: "Internal Server Error", icon: "error" });
    }
  };

  onFilterChange = (columns, searchedData) => {
    try {
      var searchedKey = [];
      var currentData = this.state.pageData;
      var dataMatchedFlag = true;
      var filteredData = [];

      //extracting the filter key from array
      for (var i = 0; i < searchedData.length; i++) {
        if (searchedData[i].length > 0) {
          searchedKey.push(i);
        }
      }
      //console.log("searchedKey ==> ", searchedKey);
      //comparing the searchedData with pageData
      for (var i = 0; i < currentData.length; i++) {
        for (var j = 0; j < searchedKey.length; j++) {
          //console.log(
          //   "comparing searchedData[j] :- ",
          //   searchedData[searchedKey[j]][0],
          //   " with currentData :- ",
          //   currentData[i][columns[searchedKey[j]]["name"]]
          // );
          if (
            searchedData[searchedKey[j]][0] !==
            currentData[i][columns[searchedKey[j]]["name"]]
          ) {
            dataMatchedFlag = false;
          }
        }
        if (dataMatchedFlag) {
          filteredData.push(currentData[i]);
        } else {
          dataMatchedFlag = true;
        }
      }

      //console.log("filteredData :- ", filteredData.length);
      if (filteredData.length > 0) {
        this.setState({ data: filteredData });
      } else {
        this.setState({ data: currentData });
      }
    } catch (error) {
      console.log("Error in onFilterChange :- ", error);
      this.handleError(true, "Something went wrong while applying filter");
      swal({ title: "Error", text: "Internal Server Error", icon: "error" });
    }
  };


  handleFilter = async (event) => {
    try{
      event.preventDefault();
      this.setState({loadState: true});
      //console.log("check filter");
      const filterData = this.getFilterJobsheetData();
      // const response = await this.getJobsheetData(this.state.page, this.state.rowsPerPage, filterData);
      const response = (this.state.searchAnywhereKeyword !== '') ? this.handleAnywhereSearch(this.state.page, this.state.rowsPerPage, this.state.searchAnywhereKeyword) : await this.getJobsheetData(this.state.page, this.state.rowsPerPage, filterData);
      this.setState({loadState: false});
      if(response !== "error"){
        this.setState({
          page: this.state.page,
          rowsPerPage: this.state.rowsPerPage,
          data: response.data,
          count: response.count,
          jobsheetDataCount: response.count
        });
      }else{
        swal({ title: "Error", text: "Internal Server Error handle filter", icon: "error" });
      }
    }catch(err){
      console.log("Error in handleFilter :- ",err);
      this.handleError(true, "Something went wrong !!!");
      swal({ title: "Error", text: "Internal Server Error", icon: "error" });
    }
  }

  getFilterJobsheetData = () => {
    let jobsheetFilterData = {};
    const data = this.state;
    if(data.jobsheet_no !== ""){
      jobsheetFilterData['jobsheet_no'] = data.jobsheet_no;
    }

    if(data.jobsheet_status !== ""){
      jobsheetFilterData['jobsheet_status'] = data.jobsheet_status;
    }

    if(data.jobsheet_type_of_service !== ""){
      jobsheetFilterData['jobsheet_type_of_service'] = data.jobsheet_type_of_service;
    }

    if(data.start_date !== ""){
      jobsheetFilterData['start_date'] = data.start_date;
    }

    if(data.end_date !== ""){
      jobsheetFilterData['end_date'] = data.end_date;
    }

    if(data.jobsheet_brand !== ""){
      jobsheetFilterData['jobsheet_brand'] = data.jobsheet_brand;
    }

    if(data.jobsheet_product_category !== ""){
      jobsheetFilterData['jobsheet_product_category'] = data.jobsheet_product_category;
    }

    if(data.jobsheet_model !== ""){
      jobsheetFilterData['jobsheet_model'] = data.jobsheet_model;
    }

    if(data.jobsheet_user !== ""){
      jobsheetFilterData['jobsheet_user'] = data.jobsheet_user;
    }

    //console.log("jobsheetFilterData => ",jobsheetFilterData);
    return jobsheetFilterData;
  }

  jobsheetFilterDataChange = (event, key) => {
    let filterData = {};
    filterData[key] = event.target.value;
    if(key === "end_date"){
      if(this.state.start_date === ""){
        this.setState({
          validated: true,
          end_date_error_msg: "Start date Cannot be empty when end date is selected."
        })
      }
      if(this.state.start_date !== ""){
        const startDate = new Date(this.state.start_date);
        const endDate = new Date(filterData[key]);
        if(startDate > endDate){
          this.setState({
            validated: true,
            end_date_error_msg: "Start date must come after end date"
          })
        }else{
          this.setState({
            validated: false,
            end_date_error_msg: ""
          })
        }
      }
    }
    this.setState(filterData);
  }

  getKey = () =>{
    return keyCount++;
  }

  searchDiv = () => {
    return (<div>
      <input className="search-input"
        type="text"
        placeholder="Jobsheet Details"
        value={this.state.searchAnywhereKeyword}
        onChange={(e) => {
          if (e.target.value === "") {
            this.setState({
              data: this.state.pageData,
              count: this.state.jobsheetDataCount,
              page: this.state.pageNo,
              rowsPerPage: this.state.rowsPerPage
            })
          }
          this.setState({
            searchAnywhereKeyword: e.target.value
          })
        }}
      />
      <Button className="search-btn" onClick={(e) => {
        this.handleAnywhereSearch(this.state.page, this.state.rowsPerPage, this.state.searchAnywhereKeyword);
      }}>Search</Button>
    </div>)
  }

  handleAnywhereSearch = async (pageNo, rowCount, searchedKeyword) => {
    try{
      this.setState({loadState: true});
      let filteredReqData = this.getFilterJobsheetData();
      const userData = this.context[0];
      const user = userData['user'];
      if(((user['privilege'] !== "super admin") && user['privilege'] !== "admin")){
        //console.log("GETTING DATA ACC TO USER")
        filteredReqData['jobsheet_user'] = user['userName'];
      }
      const config = {
        withCredentials: true,
        method: "POST",
        url: application.API.JOBSHEET_PAGINATON+"/"+searchedKeyword,
        header: "Access-Control-Allow-Headers: X-Requested-With, privatekey",
        data: {
          attributes: {
            limit: rowCount,
            offset: pageNo * rowCount,
          },
          filteredReq:filteredReqData,
          columns: [
            "jobsheet_no",
            "jobsheet_user",
            "jobsheet_customer_type",
            "jobsheet_customer_name",
            "jobsheet_mobile_no",
            "jobsheet_email_id",
            "jobsheet_model",
            "jobsheet_serial_no",
            "jobsheet_dealer_name",
          ],
        },
      };
      const response = await axios(config);
      //console.log("response === ",response.data.result.data)
      if(response.status === 200 && response.data.status === "success"){
        this.setState({
          data: response.data.result.data,
          count: response.data.result.count,
          page: pageNo,
          rowsPerPage: this.state.rowsPerPage,
          pageData: this.state.pageData,
          loadState: false
        })
      }
    }catch(err){
      console.log("Error in handleAnywhereSearch :- ",err);
      this.handleError(true, "Something went wrong !!!");
      swal({ title: "Error", text: "Internal Server Error", icon: "error" });
    }
  }

  hideModal = () => {
    this.setState({
      modalShow: false
    })
  }

  render() {
    
    
    if (this.state.loadState) {
      // console.log("loader...")
      return <Loader />;
    } 
    else if(!this.state.userData.user.pchanged) {
      return <Redirect to={"/change-password"} />;
    }
    else {
      const { data, count, page, rowsPerPage } = this.state;
      const options = {
        page: page,
        rowsPerPage: rowsPerPage,
        count: count,
        serverSide: true,
        filter: true,
        fixedHeader: true,
        filterType: "dropdown",
        print: false,
        search: false,
        responsive: "vertical",
        tableBodyHeight: "550px",
        onTableChange: (action, tableState) => {
          //console.log(tableState);
          //console.log("action ==> ", action);
          switch (action) {
            case "changePage":
            case "changeRowsPerPage":
              this.changePage(tableState.page, tableState.rowsPerPage);
              break;
            case "search":
              // case "propsUpdate":
              //console.log("Searched data :- ", tableState.searchText);
              if (tableState.searchText != null) {
                this.searchFilter(this.state.pageData, tableState.searchText);
              }
              break;
            case "onSearchClose":
              this.onSearchClose(tableState.rowsPerPage);
              break;
            case "filterChange":
              this.onFilterChange(tableState.columns, tableState.filterList);
              break;
            default:
              //console.log("action not handled.");
              break;
          }
        },
        customToolbar:() => {
          return (
            <>
            </>
          )
        }
      };
      //console.log("data-length -> ", this.state.data);
      if(this.state.pageData !== null){
        //console.log("Render pageData ===> ",this.state.pageData);
        //console.log("Render pageDataLength ===> ",this.state.pageData.length);
      }
      return (
        <React.Fragment>
          <Jumbotron className="jumbotron-title shadow-sm">
            <h4 style={{ textAlign: "center" }}>Jobsheet Details</h4>
          </Jumbotron>
          <Card className="main-container">
            <Card className="main-container filter-container">
              <Row style={{justifyContent:"flex-end", margin: "15px"}}>
                <Button variant="primary" onClick={() => { 
                  this.setState({
                    modalShow: true
                  }) 
                }}>
                  Find Serial Number
                </Button>
                <FindSerialModal 
                  show={this.state.modalShow}
                  hide={this.hideModal}
                />
              </Row>
              <Card.Body className="main-container-body">
                <Form 
                  onSubmit={this.handleFilter}
                  noValidate
                >
                  <Form.Row>
                    {
                      (this.state.usersState.length > 0)
                      ? 
                      <Form.Group as={Col} sm={2} controlId="users">
                        <Form.Label>Users</Form.Label>
                        <Form.Control
                          as="select"
                          value={this.state.jobsheet_user}
                          onChange={(event) => {
                            this.jobsheetFilterDataChange(event,"jobsheet_user");
                          }}
                        >
                          <option value="">Select User</option>
                          {
                            this.state.usersState.map((user) =>{
                              return (
                                <option value={user} key={this.getKey()}>{user}</option>
                              )
                            })
                          }
                        </Form.Control>
                    </Form.Group>
                      : null
                    }
                    <Form.Group as={Col} sm={2} controlId="jobsheetNo">
                      <Form.Label>Jobsheet No</Form.Label>
                      <Form.Control 
                        type="text"
                        placeholder="Enter Jobsheet No"
                        value={this.state.jobsheet_no}
                        onChange={(event) => {
                          this.jobsheetFilterDataChange(event,"jobsheet_no");
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="jobsheetStatus" sm={2}>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        value={this.state.jobsheet_status}
                        onChange={(event) => {
                          this.jobsheetFilterDataChange(event,"jobsheet_status");
                        }}
                      >
                        <option value="">Select Status</option>
                        <option value="pending">Pending</option>
                        <option value="sparePartPending">Pending for spare parts</option>
                        <option value="callCancel">Call Cancel</option>
                        <option value="callClose">Call Close</option>
                        <option value="others">Others</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} sm={2} controlId="typeOfService">
                        <Form.Label>Type Of Service</Form.Label>
                        <Form.Control
                          as="select"
                          value={this.state.jobsheet_type_of_service}
                          onChange={(event) => {
                            this.jobsheetFilterDataChange(event,"jobsheet_type_of_service");
                          }}
                        >
                          <option value="">Select Type Of Service</option>
                          <option value="returnable">Returnable</option>
                          <option value="nonReturnable">Non Returnable</option>
                          <option value="replacement">Replacement</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} sm={2} controlId="startDate">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control 
                        type="date"
                        value={this.state.start_date}
                        onChange={(event) => {
                          this.jobsheetFilterDataChange(event,"start_date");
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={2} controlId="endDate">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control 
                        type="date"
                        value={this.state.end_date}
                        isInvalid={this.state.validated}
                        onChange={(event) => {
                          this.jobsheetFilterDataChange(event,"end_date");
                        }}
                      />
                      <Form.Control.Feedback type="invalid">{this.state.end_date_error_msg}</Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={4}>
                      <Form.Label>Brand</Form.Label>
                      <Form.Control 
                        as="select"
                        value={this.state.jobsheet_brand}
                        onChange={(event) => {
                          this.jobsheetFilterDataChange(event,"jobsheet_brand");
                        }}
                      >
                        <option value=''>Select Brand</option>
                        {
                          this.state.brandState.map((brand) => {
                            return (
                              <option key={this.getKey()} value={brand}>{brand}</option>
                            )
                          })
                        }
                      </Form.Control>
                      <Form.Label
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "15px",
                      }}
                    >
                      OR
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Brand Name"
                      value={this.state.jobsheet_brand}
                      onChange={(event) => {
                        this.jobsheetFilterDataChange(event,"jobsheet_brand");
                      }}
                    />
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <Form.Label>Category</Form.Label>
                      <Form.Control
                        as="select"
                        value={this.state.jobsheet_product_category}
                        onChange={(event) => {
                          this.jobsheetFilterDataChange(event,"jobsheet_product_category");
                        }}
                      >
                         <option value=''>Select Category</option>
                         {
                          this.state.categoryState.map((category) => {
                            return (
                              <option key={this.getKey()} value={category}>{category}</option>
                            )
                          })
                        }
                      </Form.Control>
                      <Form.Label
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "15px",
                      }}
                    >
                      OR
                    </Form.Label>
                    <Form.Control 
                      type="text"
                      placeholder="Enter Category"
                      value={this.state.jobsheet_product_category}
                      onChange={(event) => {
                        this.jobsheetFilterDataChange(event,"jobsheet_product_category");
                      }}
                    />
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <Form.Label>Model</Form.Label>
                      <Form.Control
                        as="select"
                        value={this.state.jobsheet_model}
                        onChange={(event) => {
                          this.jobsheetFilterDataChange(event,"jobsheet_model");
                        }}
                      >
                        <option value=''>Select Model</option>
                        {
                          this.state.productState.map((model) => {
                            return (
                              <option key={this.getKey()} value={model}>{model}</option>
                            )
                          })
                        }
                      </Form.Control>
                      <Form.Label
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginTop: "15px",
                        }}
                      >
                        OR
                      </Form.Label>
                      <Form.Control 
                        type="text"
                        placeholder="Enter Model Name"
                        value={this.state.jobsheet_model}
                        onChange={(event) => {
                          this.jobsheetFilterDataChange(event,"jobsheet_model");
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Button type="submit">Submit</Button>
                </Form>
              </Card.Body>
            </Card>
            <Card.Body className="main-container-body">
              <MUIDataTable
                title={this.searchDiv()}
                data={data}
                columns={this.state.columns}
                options={options}
              />
            </Card.Body>
          </Card>
        </React.Fragment>
      );
    }
  }
}

export default JobsheetPagintaion;
