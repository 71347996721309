import Modal from 'react-bootstrap/Modal';
import {useState, useEffect, useReducer} from 'react';
import {Form, Col, Row} from "react-bootstrap";
import axios from "axios";
import Button from "react-bootstrap/Button";
import moment from 'moment';
import application from "../constants/apiList";
import '../styles/componentsStyles/editProductModal.scss';


const EditProductModal = (props) => {
    let productData = props.productsData;
    let key = 0;
    
    const currentProductData = props.editProductData;
    const productId = currentProductData.id;
    const userData = props.user;
    
    const [validated, setValidated] = useState(false);
    const [brandState, setBrandState] = useState(() => {
        return Object.keys(productData);
    })
    const [categoryState, setCategoryState] = useState(() => {
        return Object.keys(productData[currentProductData.brand]);
    });
    const [productState, setProductState] = useState(() => {
        return productData[currentProductData.brand][currentProductData.category];
    })

    const initialState = {
        brand:{
            isValid: true,
            value: currentProductData.brand,
            errorMsg: ""
        },
        category:{
            isValid: true,
            value: currentProductData.category,
            errorMsg: ""
        },
        product_no:{
            isValid: true,
            value: currentProductData.product_no,
            errorMsg: ""
        },
        product_version:{
            isValid: true,
            value: currentProductData.product_version,
            errorMsg: ""
        },
        product_warranty:{
            isValid: true,
            value: currentProductData.product_warranty,
            errorMsg: ""
        },
        created_date:{
            value: currentProductData.created_date,
            isValid: true,
            errorMsg: ""
        },
        last_modified_by:{
            value: currentProductData.last_modified_by,
            isValid: true,
            errorMsg: ""
        },
        status:{
            value: (currentProductData.status === 1) ? "active" : "inactive",
            isValid: true,
            errorMsg: ""
        }
    }

    function init(initialData){
        return {
            data: initialData,
        };
    }

    function reducer(state, action){
        switch (action.type) {
            case "valueChanged":
                let stateData = {};
                let changedValue = {};
                
                changedValue["value"] = action.payload;
                changedValue["isValid"] = true;
                changedValue["errorMsg"] = "";
    
                state.data[action.key] = changedValue;
                stateData["data"] = state.data;

                return stateData;
            case "initialEditState":
                let initialEditState = {};
                initialEditState['data'] = action.payload;
                
                return initialEditState;
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState, init);
    const changeCategory = (event) => {
        const selectedBrand = event.target.value;
        setCategoryState(Object.keys(productData[selectedBrand]));
    }

    const changeProduct = (e) => {
        const selectedCategory = e.target.value;
        let brand = state.data.brand.value;
        const productList = productData[brand][selectedCategory];
        setProductState(productList);
      };

    const getKey = () => {
        key += 1;
        return key;
    }

    const handleSubmitModal = async (event) => {
        try{
            const form = event.currentTarget;
            setValidated(true);
            const stateData = state.data;
            event.preventDefault();
            if(form.checkValidity()){
                const modifiedDate = moment().format('YYYY-MM-DD HH:mm:ss');
                const config = {
                    url: application.API.APP_PRODUCT,
                    method: 'patch',
                    data:{
                        update:{
                            brand: stateData.brand.value,
                            category: stateData.category.value,
                            product_no: stateData.product_no.value,
                            product_version: stateData.product_version.value,
                            product_warranty: stateData.product_warranty.value,
                            modified_date: modifiedDate,
                            last_modified_by: userData.userName,
                            status: (stateData.status.value === "active") ? 1 : 0
                        },
                        where:{
                            id: productId
                        }
                    },
                    withCredentials: true
                }
                const response = await axios(config);
                setValidated(false);
                props.closeModalPopup(props.rowCount);
                // if (response.status === 200 && response.data.status === "success") {
                //     console.log("Logs:- success");
                // }else{
                //     console.log("Logs:- failure");
                // }
            }
        }catch(error){
            console.log("error in handleSubmitModal :- ", error)
        }
    }
    return (
        <Modal 
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="product-modal-style"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Product
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmitModal}
                >
                    <Form.Row>
                        <Form.Group as={Col} sm={4} controlId="brand">
                            <Form.Label>Brand <span className="important">*</span></Form.Label>
                            <Form.Control 
                              required={(state.data.brand.value !== "") ? false : true}
                              as="select"
                              value={state.data.brand.value}
                              onChange={(event) => {
                                    changeCategory(event);
                                    dispatch({
                                        type: "valueChanged",
                                        key: "brand",
                                        payload: event.target.value,
                                    })
                                }}  
                            >
                                <option value=''>Select Brand</option>
                                {
                                    brandState.map((brand) => {
                                        return (
                                            <option key={brand + "-" + getKey()} value={brand}>{brand}</option>
                                        )
                                    })
                                }
                            </Form.Control> 
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="category">
                            <Form.Label>Category <span className="important">*</span></Form.Label>
                            <Form.Control
                                required={(state.data.category.value !== "") ? false : true}
                                as="select"
                                value={state.data.category.value}
                                onChange={(event) => {
                                    changeProduct(event);
                                    dispatch({
                                        type: "valueChanged",
                                        key: "category",
                                        payload: event.target.value,
                                    })
                                }}
                            >
                                <option value=''>Select Category</option>
                                {
                                    categoryState.map((category) => {
                                        return (
                                            <option key={category + "-" + getKey()} value={category}>{category}</option>
                                        )
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="model">
                            <Form.Label>Model <span className="important">*</span></Form.Label>
                            <Form.Control
                                required={(state.data.product_no.value !== "") ? false : true}
                                as='select'
                                value={state.data.product_no.value}
                                onChange={(event) => {
                                    dispatch({
                                        type: "valueChanged",
                                        key: "product_no",
                                        payload: event.target.value,
                                    })
                                }}
                            >
                                <option value=''>Select Model</option>
                                {
                                    productState.map((model) => {
                                        return (
                                            <option key={model + "-" + getKey()} value={model}>{model}</option>
                                        )
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} sm={4} controlId="productVersion">
                            <Form.Label>Product Version <span className="important">*</span></Form.Label>
                            <Form.Control 
                                type="text"
                                placeholder="Enter Product Version"
                                value={state.data.product_version.value}
                                onChange={(event) => {
                                    dispatch({
                                        type: "valueChanged",
                                        key: "product_version",
                                        payload: event.target.value,
                                    })
                                }}
                                required={(state.data.product_version.value !== "") ? false : true}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={4} controlId="productWarranty">
                            <Form.Label>Product Warranty <span className="important">*</span></Form.Label>
                            <Form.Control 
                                type="text"
                                placeholder="Enter Product Warranty"
                                value={state.data.product_warranty.value}
                                onChange={(event) => {
                                    dispatch({
                                        type: "valueChanged",
                                        key: "product_warranty",
                                        payload: event.target.value,
                                    })
                                }}
                                required={(state.data.product_warranty.value !== "") ? false : true}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row style={{ justifyContent: "center" }}>
                        <Form.Group as={Row} controlId="productStatus">
                            <Form.Check 
                                type="radio"
                                label="Active"
                                id="activeProductModal"
                                name="productStatusType"
                                value="active"
                                style={{ marginRight: "10px" }}
                                onChange={(event) => {
                                    dispatch({
                                        type: "valueChanged",
                                        key: "status",
                                        payload: "active",
                                    })
                                }}
                                checked={(state.data.status.value === "active")}
                            />
                            <Form.Check 
                                type="radio"
                                label="InActive"
                                id="inActiveProductModal"
                                name="productStatusType"
                                value="inactive"
                                style={{ marginRight: "10px" }}
                                onChange={(event) => {
                                    dispatch({
                                        type: "valueChanged",
                                        key: "status",
                                        payload: "inactive",
                                    })
                                }}
                                checked={(state.data.status.value === "inactive")}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Button type="submit">Submit form</Button>
                  </Form.Row>
                </Form>
            </Modal.Body>
        </Modal>    
    )
}

export default EditProductModal;