import React, { useState, useEffect, useContext } from "react";
import logo from "../resources/images/KEI-logo.png";
import lightningBoltIcon from "../resources/images/lightning-bolt.svg"
import "../styles/componentsStyles/loginBox.scss";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import  application  from "../constants/apiList";
import helpers from "../helpers"


function LoginBox() {
  const [show, setShow] = useState(false);
  let [headText, setHeadText] = useState("Error");
  let [bodyText, setBodyText] = useState("Error");
  let [redirect, setRedirect] = useState(null);
  let [userState, setUserState] = useContext(UserContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    //console.log("LOGIN TEST :- ",userState.loggedInStatus);
    if(userState.loggedInStatus){
      //console.log("User is logged in already");
      <Redirect to={'/'} />
    }
  })

  // render() {
  const { register, errors, handleSubmit, watch } = useForm({
    mode: "onBlur",
  });
  const onSubmit = async (data) => {
    let result;
    try {
      require("dotenv").config();
      const config = {
        method: "post",
        url: application.API.REACT_APP_USERS,
        // headers: { authorization: process.env.REACT_APP_AUTH },
        data: {
          username: data.login,
          password: data.password,
        },
        withCredentials: true,
      };
      // result = await axios(config).then((res) => //console.log("result", res));
      result = await axios(config);
      if (result.data.data === "success") {
        //console.log("login", result.headers);
        setUserContext(result.data.user);
        setRedirect("/");
      } else if (result.data.data === "faliure") {
        //console.log("invalid user");
        setHeadText("Error");
        setBodyText("Username/Password is incorrect");
        handleShow();
      } else if (result.data.data === "loggedIn") {
        //console.log("already logged in", result.data);
        setRedirect("/");
      }
    } catch (err) {
      console.log(err);
      setHeadText("Error");
      setBodyText("Internal Server Error");
      handleShow();
      // alert("Server Error");
    }
  };

  const setUserContext = (userData) => {
    let user = {};
    user["loggedInStatus"] = true;
    user["user"] = userData;
    //console.log("loggedInUser ===> ", user);
    setUserState(user);
  };

  if (userState.loggedInStatus) {
    return <Redirect to={"/"} />;
  }
  return (
    <React.Fragment>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{headText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{bodyText}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="wrapper fadeInDown align-middle">
        <div id="formContent">
          <div className="fadeIn first">
            <img src={logo} id="" alt="User Icon" width="100" />
            {
              // check if lightning env set then show lightning icon
              helpers.checkLightingEnv()
              ?
              (
                <img src={lightningBoltIcon} id="" alt="lightning icon" width="30" />
              )
              :
              null
            }
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="text"
              className="fadeIn second input-login"
              name="login"
              placeholder="username"
              ref={register({
                required: true,
                // validate: checkCred,
              })}
            />
            {errors.login && errors.login.type === "required" && (
              <p>This field is required</p>
            )}
            {errors.login && errors.login.type === "pattern" && (
              <p>Please enter a valid email address</p>
            )}

            <input
              type="password"
              className="fadeIn third input-login"
              name="password"
              placeholder="password"
              ref={register({
                required: true,
                // validate: checkCred,
              })}
            />
            {errors.password && errors.password.type === "validate" && (
              <p>Invalid Credentials</p>
            )}
            {errors.password && errors.password.type === "required" && (
              <p>This field is required</p>
            )}
            <div id="formFooter align-middle">
              <input type="submit" className="fadeIn fourth" value="login" />
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
  // }
}

export default LoginBox;
