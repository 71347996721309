const modules = [
    {
        id: "1",
        title: "Jobsheet"
    },
    {
        id: "2",
        title: "Product"
    },
    {
        id: "3",
        title: "User"
    },
    {
        id: "4",
        title: "Inventory"
    },
    {
        id: "5",
        title: "Challan"
    },
    {
        id: "6",
        title: "Warranty",
        link: "/view-warranty"
    }
];

const moduleAction = [
    {
        parentId: "1",
        id: "1-1",
        title: "Add",
        link: "/insert"
    },
    {
        parentId: "1",
        id: "1-2",
        title: "View",
        link: "/viewJobsheet"
    },
    {
        parentId: "2",
        id: "2-1",
        title: "Add",
        link: "/addProduct"
    },
    {
        parentId: "2",
        id: "2-2",
        title: "View",
        link: "/view-products"
    },
    {
        parentId: "2",
        id: "2-3",
        title: "Failed Serials",
        link: "/view-failed-serials"
    },
    {
        parentId: "3",
        id: "3-1",
        title: "Add User",
        link: "/add-user"
    },
    {
        parentId: "3",
        id: "3-2",
        title: "My Profile",
        link: "/my-profile"
    },
    {
        parentId: "3",
        id: "3-3",
        title: "User Management",
        link: "/user-management"
    },
    {
        parentId: "4",
        id: "4-1",
        title: "Add",
        link: "/add-inventory"
    },
    {
        parentId: "4",
        id: "4-2",
        title: "View",
        link: "/view-inventory"
    },
    {
        parentId: "5",
        id: "5-1",
        title: "Add Challan",
        link: "/add-challan"
    },
    {
        parentId: "5",
        id: "5-2",
        title: "View Challan",
        link: "/view-challan"
    },
]

export {modules, moduleAction}