import "./App.css";
import LoginBox from "./components/loginBox";
import Home from "./components/home";
import JobsheetPagintaion from "./components/jobsheetPagination";
import UserProfile from "./components/userProfile";
import { Header } from "./components/header";
import Footer from "./components/footer";
import AddJobsheet from "./components/addJobsheet";
import AddProduct from './components/addProduct';
import ProductsPagination from "./components/productsPagination";
import FailedSerialPagination from "./components/failedSerialPagination";
import WarrantyForm from "./components/warrantyForm";
import WarrantyPagination from "./components/warrantyPagination";
import UserPagination from './components/userPagination';
import ChangePassword from "./components/changePassword";

import InventoryPagination from "./components/inventoryPagination";
import ChallanPagination from "./components/challanPagination";
import Challan from "./components/challan";
import AddInventory from "./components/addInventory";
import { Row, Col, Card, Container } from "react-bootstrap";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { UserContext } from "./contexts/userContext";
import "bootstrap/dist/css/bootstrap.min.css";
import {useContext} from 'react';
function App() {

  const [userState, setUserState] = useContext(UserContext);

  const Page404 = ({ location }) => (
    <Container className="mt-5 text-center">
      <Row>
        <Col>
          <Card className="shadow-lg p-3">
            <h1>Page not found</h1>
          </Card>
        </Col>
      </Row>
    </Container>
  );
  // console.log("Logs:- userData ==> ",userState);
  return (
    <Router>
      <div className="App">
        <Header />
        <Switch>
          <Route path="/login" component={LoginBox} />
          <Route exact path="/register-warranty" component={WarrantyForm} />
        </Switch>
        {
          (userState && userState.loggedInStatus === true) ?
          (
            <Switch>
              <Route exact path="/" component={Home} />
                <Route exact path="/viewJobsheet" component={JobsheetPagintaion} />
                <Route exact path="/my-profile" render={(props) => <UserProfile pageType="my-profile"/>} />
                <Route exact path="/add-user" render={(props) => <UserProfile pageType="add-user"/>} />
                <Route exact path="/user-management" component={UserPagination} />
                {/* <Route exact path="/my-profile" component={UserProfile} /> */}
                {/* <Route exact path="/add-user" component={UserProfile} /> */}
                <Route exact path={["/insert","/edit/:insertNo"]} component={AddJobsheet}></Route>
                <Route exact path="/addProduct" component={AddProduct}></Route>
                <Route exact path="/view-products" component={ProductsPagination}></Route>
                <Route exact path="/view-failed-serials" component={FailedSerialPagination}></Route>
                <Route exact path="/add-inventory" component={AddInventory}></Route>
                <Route exact path="/view-inventory" component={InventoryPagination}></Route>
                <Route exact path={"/add-challan"} key="add-challan" component={Challan}></Route>
                <Route exact path={"/edit-challan/:challanId"} key="edit-challan" component={Challan}></Route>
                <Route exact path="/view-challan" component={ChallanPagination}></Route>
                <Route exact path="/view-warranty" component={WarrantyPagination}></Route>
                <Route exact path="/change-password" component={ChangePassword}></Route>
                <Route path="*" component={Page404} />
            </Switch>
          )
          :
          (
            null
          )
        }
        <Footer />
      </div>
    </Router>
  );
}

export default App;
