import React, { Component } from 'react';
import MUIDataTable from "mui-datatables";
import { Col, Card, Form, Jumbotron, Row, Button } from "react-bootstrap";
import application from "../constants/apiList";
import { UserContext } from "../contexts/userContext";
import axios from "axios";
import swal from "@sweetalert/with-react";
import Loader from "./loader";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ApproveWarrantyPopup from "./approveWarrantyPopup";
import { Redirect } from "react-router-dom";

let productData = {};
let keyCount = 0;

class WarrantyPagination extends React.Component {

    static contextType = UserContext;

    getKey = () =>{
        return keyCount++;
    }

    state = {
        loadInitData: true,
        loadState: false,
        mountModalComponent: false,
        modalShow: false,
        brandState: [],
        categoryState: [],
        productState: [],
        modalUser: null,
        warranty_brand: "",
        warranty_product_category: "",
        warranty_model: "",
        warranty_approval: -1,
        start_date: "",
        end_date: "",
        validated: false,
        end_date_error_msg: "",
        data: [],
        count: 1,
        pageData: null,
        productsDataCount: 0,
        rowsPerPage: 10,
        page: 0,
        approveWarrantyNo: 0,
        warrantyData: {},
        userData: this.context[0],
        columns: [
            {
                name: "warranty_no",
                label: "Warranty No"
            },
            {
                name: "warranty_fullName",
                label: "Full Name"
            },
            {
                name: "warranty_email_id",
                label: "Email Id"
            },
            {
                name: "warranty_mobile_no",
                label: "Mobile No"
            },
            {
                name: "warranty_city",
                label: "City"
            },
            {
                name: "warranty_model",
                label: "Model"
            },
            {
                name: "warranty_dname",
                label: "Deaker Name"
            },
            {
                name: "warranty_invoice_no",
                label: "Invoice Number"
            },
            {
                name: "warranty_submission_date",
                label: "Submission Date",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <span>{ moment(value).format("MMMM Do YYYY, h:mm:ss a")}</span>
                        )
                    }
                }
            },
            {
                name: "action_tab",
                label: "Action",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <FontAwesomeIcon 
                            onClick={() => {
                                // console.log("Value => ",value);
                                // console.log("tableMeta => ",tableMeta);
                                // console.log("updateValue => ",tableMeta['rowData'][0]);
                                this.setState({  
                                    modalShow: true,
                                    mountModalComponent: true,
                                    approveWarrantyNo: tableMeta['rowData'][0]
                                })
                            }} 
                            icon={faEdit} 
                            style={{cursor: "pointer"}} 
                            />
                        );
                    }
                }
            }
        ]
    }

    componentDidMount() {
        if((this.state.userData['loggedInStatus']) && (this.state.loadInitData)){
            this.getProductData();
            this.getData(0, 10, {});
            this.setState({
                modalUser: this.state.userData['user'],
                loadInitData: false
            })
        }
    }

    componentDidUpdate() {
        if((this.state.userData['loggedInStatus']) && (this.state.loadInitData)){
            this.getProductData();
            this.getData(0, 10, {});
            this.setState({
                modalUser: this.state.userData['user'],  
                loadInitData: false
            })
        }
    }

    getProductData = async () => {
        try{
            const config = {
                method: "get",
                url: application.API.APP_PRODUCTS,
                withCredentials: true,
            };
            this.setState({
                loadState: true
            })
            const response = await axios(config);
            this.setState({
              loadState: false
            })
            if ((response.status === 200 || response.status === 304) && response.data.status === "success") {
                productData = response.data.result;
                // console.log("api productData ==> ",productData);
                let brand = Object.keys(response.data.result);
                this.setState({
                    brandState: brand
                })
            }
        }catch(err){
          console.log("error in getProductData :- ",err);
          swal({ title: "Error", text: "Internal Server Error product api", icon: "error" });
        }
    }

    getData = async (pageNo, rowCount, filteredData) => {
        try{
            const response = await this.getWarrantyPaginationData(pageNo, rowCount, filteredData);
            if(response != "error") {
                this.setState({
                    data: response.data,
                    count: response.count,
                    pageData: response.data,
                    productsDataCount: response.count,
                    page: pageNo,
                    rowsPerPage: rowCount
                })
            }
        }catch (error) {
            console.log("error in getData :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
          }
    }

    getWarrantyPaginationData = async (pageNo, rowCount, filteredReqData) => {
        try{
            const config = {
                withCredentials: true,
                method: "POST",
                url: application.API.WARRANTY_PAGINATION,
                header: "Access-Control-Allow-Headers: X-Requested-With, privatekey",
                data: {
                    attributes:{
                        limit: rowCount,
                        offset: pageNo * rowCount
                    },
                    filteredReq: filteredReqData,
                    columns: [
                        "warranty_no",
                        "warranty_email_id",
                        "warranty_mobile_no",
                        "warranty_city",
                        "warranty_model",
                        "warranty_dname",
                        "warranty_invoice_no",
                        "warranty_submission_date"
                    ]
                }
            }

            const response = await axios(config);
            if (response.data.status === "success") {
                return response.data.result;
            }else{
                swal({ title: "Error", text: "Internal Server Error", icon: "error" });
            }
        }catch (error) {
            console.log("Error in getProductsData :- ",error)
            swal({ title: "Error", text: "Error getting response from the server", icon: "error" });
          }
    }

    changeCategory = (e) => {
        const selectedBrand = e.target.value;
        const productList = [];
        let categoryList;
        // console.log("productData ==> ",productData);

        if (selectedBrand === "") {
            categoryList = [];
        } else {
            categoryList = Object.keys(productData[selectedBrand]);
            // console.log("categoryList ===> ",categoryList);
        }
        this.setState({
            categoryState: categoryList,
            productState: productList,
            warranty_brand: selectedBrand
        });
    }

    changeProduct = (e) => {
        const selectedCategory = e.target.value;
        const brand = this.state.warranty_brand;
        const productList = productData[brand][selectedCategory];
        // console.log("productData ===> ",productData)
        // console.log("productList ==> ",productList);
        this.setState({
            productState: productList,
        });
    }

    warrantyFilterChange = (event, key) => {
        let filterData = {};
        filterData[key] = event.target.value;
        if(key === "end_date"){
            if(this.state.start_date === ""){
              this.setState({
                validated: true,
                end_date_error_msg: "Start date Cannot be empty when end date is selected."
              })
            }
            if(this.state.start_date !== ""){
              const startDate = new Date(this.state.start_date);
              const endDate = new Date(filterData[key]);
              if(startDate > endDate){
                this.setState({
                  validated: true,
                  end_date_error_msg: "Start date must come after end date"
                })
              }else{
                this.setState({
                  validated: false,
                  end_date_error_msg: ""
                })
              }
            }
          }
        this.setState(filterData);
    }

    getFilteredData = () => {
        let warrantyFilteredData = {};
        const data = this.state;
        if(data.warranty_brand != ""){
            warrantyFilteredData["warranty_brand"] = data.warranty_brand;
        }
        if(data.warranty_product_category != ""){
            warrantyFilteredData["warranty_product_category"] = data.warranty_product_category;
        }
        if(data.warranty_model != ""){
            warrantyFilteredData["warranty_model"] = data.warranty_model;
        }
        if(data.warranty_approval != -1){
            warrantyFilteredData["warranty_approval"] = data.warranty_approval;
        }
        if(data.start_date != ""){
            warrantyFilteredData["start_date"] = data.start_date;
        }
        if(data.end_date != ""){
            warrantyFilteredData["end_date"] = data.end_date;
        }
        return warrantyFilteredData;
    }

    closeModal = () =>{
        this.setState({
            modalShow: false,
            mountModalComponent: false
        })
    }

    changePage = async (page, rowsPerPage) => {
        try {
            this.setState({loadState: true});
            const filteredData = this.getFilteredData();
            const response = await this.getWarrantyPaginationData(page, rowsPerPage, filteredData);
            this.setState({loadState: false});
            this.setState({
                page: page,
                rowsPerPage: rowsPerPage,
                data: response.data,
                count: response.count,
                pageData: response.data
              });
        } catch (error) {
            console.log("Error in changePage :- ", error);
            this.handleError(true, "Error while getting warranty");
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }
    }

    handleFilter = async(event) => {
        try{
            event.preventDefault();
            const filterData = this.getFilteredData();
            this.setState({loadState: true});
            const response = await this.getWarrantyPaginationData(this.state.page, this.state.rowsPerPage, filterData);
            if(response !== "error"){
                this.setState({
                  loadState: false,
                  page: this.state.page,
                  rowsPerPage: this.state.rowsPerPage,
                  data: response.data,
                  count: response.count
                });
              }else{
                this.setState({loadState: true});
                swal({ title: "Error", text: "Internal Server Error handle filter", icon: "error" });
              }
        }catch (error) {
            console.log("Error in handleFilter :- ", error);
            swal({ title: "Error", text: "Internal Server Error", icon: "error" });
        }

    }

    render() { 
        if(!this.state.userData.user.pchanged) {
          return <Redirect to={"/change-password"} />;
        }
        if(this.state.loadState){
            return <Loader />;
        }else{
            const { data, count, page, rowsPerPage } = this.state;
            const options = {
                page: page,
                rowsPerPage: rowsPerPage,
                count: count,
                serverSide: true,
                filter: true,
                fixedHeader: true,
                filterType: "dropdown",
                print: false,
                search: false,
                responsive: "vertical",
                tableBodyHeight: "550px",
                onTableChange: (action, tableState) => {
                    switch (action) {
                        case "changePage":
                        case "changeRowsPerPage":
                            // const filteredData = this.changePage(tableState.page, tableState.rowsPerPage);
                            const filteredData = this.getFilteredData();
                            // console.log("filteredData ==> ",filteredData)
                            this.getData(tableState.page, tableState.rowsPerPage, filteredData);
                        break;
                        default:
                        break;
                    }
                }
            }
            this.state.warrantyData['warrantyNo'] = this.state.approveWarrantyNo;
            this.state.warrantyData['closeModalPopup'] = this.closeModal;
            return (
                <>
                    <Jumbotron className="jumbotron-title shadow-sm">
                        <h4 style={{ textAlign: "center" }}>Warranty Management</h4>
                    </Jumbotron>
                    <Card className="main-container">
                        <Card className="main-container filter-container">
                            <Card.Body className="main-container-body">
                                <Form 
                                    noValidate
                                    onSubmit={this.handleFilter}
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} sm={4} controlId="brand">
                                            <Form.Label>Brand</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={this.state.warranty_brand}
                                                onChange={(event) => {
                                                    this.changeCategory(event);
                                                    this.warrantyFilterChange(event, "warranty_brand")
                                                }}
                                            >
                                                <option value=''>Select Brand</option>
                                                {
                                                    this.state.brandState.map((brand) => {
                                                        return (
                                                        <option key={this.getKey()} value={brand}>{brand}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={4} controlId="category">
                                            <Form.Label>Category</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={this.state.warranty_product_category}
                                                onChange={(event) => {
                                                    this.changeProduct(event);
                                                    this.warrantyFilterChange(event, "warranty_product_category")
                                                }}
                                            >
                                                <option value=''>Select Category</option>
                                                {
                                                    this.state.categoryState.map((category) => {
                                                        return (
                                                        <option key={this.getKey()} value={category}>{category}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={4} controlId="model">
                                            <Form.Label>Model</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={this.state.warranty_model}
                                                onChange={(event) => {
                                                    this.warrantyFilterChange(event, "warranty_model")
                                                }}
                                            >
                                                <option value=''>Select Model</option>
                                                {
                                                    this.state.productState.map((model) => {
                                                        return (
                                                        <option key={this.getKey()} value={model}>{model}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} sm={4} controlId="status">
                                            <Form.Label>Warranty Status</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={this.state.warranty_approval}
                                                onChange={(event) => {
                                                    this.setState({
                                                        warranty_approval: event.target.value
                                                    });
                                                }}
                                            >
                                                <option value="0">Select Status</option>
                                                <option value="-1">Pending</option>
                                                <option value="1">Approved</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={4} controlId="startDate">
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control 
                                                type="date"
                                                value={this.state.start_date}
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    this.warrantyFilterChange(event,"start_date");
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={4} controlId="endDate">
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control 
                                                type="date"
                                                value={this.state.end_date}
                                                isInvalid={this.state.validated}
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    this.warrantyFilterChange(event,"end_date");
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">{this.state.end_date_error_msg}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Button type="submit">Submit</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Card.Body className="main-container-body">
                            <MUIDataTable 
                                data={data}
                                columns={this.state.columns}
                                options={options}
                            />
                        </Card.Body>
                    </Card>
                    {(this.state.mountModalComponent) 
                    ? <ApproveWarrantyPopup 
                        show={this.state.modalShow}
                        data={this.state.warrantyData}
                        // data={this.state.approveWarrantyNo}
                        // closeModalPopup={this.closeModal}
                        onHide={() => {
                            this.setState({
                                modalShow: false,
                                mountModalComponent: false
                            })
                        }}
                    />
                    : null
                    }
                </>
            )
        };
    }
}
 
export default WarrantyPagination;