import * as XLSX from 'xlsx';
// import React,{useState, useContext, useEffect} from 'react';

const helpers ={
    checkFileExtension: (fileName)=>{
        return (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName)[0] : fileName;

    },
    readExcel: (file)=>{
            let Data;
            // console.log("Yoo"+file);
            const promise = new Promise((resolve, reject) =>{
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) =>{
                const bufferArray = e.target.result;
    
                const wb = XLSX.read(bufferArray, {type:"buffer"});
    
                const wsname = wb.SheetNames[0];
    
                const ws = wb.Sheets[wsname];
    
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };
            fileReader.onerror= (error)=>{
                reject(error);
            };

        });
        promise.then((d)=>{
            // console.log(d);
            return d;
        })
    },
    checkLightingEnv: () => {
        return process.env.REACT_APP_APPLICATIONTYPE.includes("lighting")
    }

}      

 export default helpers;


    
